import React, { useContext } from 'react';
import axios from 'axios';
import FileSaver from 'file-saver';
import { AuthenticationContext } from '../../context/AuthenticationContext';

interface DownloaderProps {
  url: string;
  fileName: string;
  children: (download: () => void) => React.ReactElement;
}

function download(url: string, fileName: string, token?: string | null) {
  axios.get(url, {
    responseType: 'blob',
    headers: token ? { Authorization: `Bearer ${token}` } : {}
  }).then(response => {
    FileSaver.saveAs(response.data, fileName);
  });
}

export default function Downloader({ url, fileName, children }: DownloaderProps) {
  const context = useContext(AuthenticationContext);
  const downloadFunction = () => {
    download(url, fileName, context?.accessToken);
  };

  return children(downloadFunction);
}