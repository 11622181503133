import React from 'react';

import { ListTaxReturn } from 'components/List/ListTaxReturn/ListTaxReturn';
import Page from 'components/Page/Page';
import Paper from 'components/Paper/Paper';

export default function TaxReturnPage(): React.ReactElement {
  return (
    <Page title="Tax returns">
      <Paper>
        <ListTaxReturn/>
      </Paper>
    </Page>
  );
}
