import useApi from './api';

export interface UseSubmitFormOptions<Result> {
  onSuccess?: (response: Result) => void;
  willCallBackend?: () => void;
}

interface UseSubmitFormProps<Result> extends UseSubmitFormOptions<Result> {
  urlPath: string;
}

export function useSubmitForm<FormValues, Result>({
  urlPath,
  onSuccess,
}: UseSubmitFormProps<Result>): [
  submit: (values: FormValues) => Promise<Result>,
  loading: boolean,
  errorOccured: boolean,
] {

  const { post, postLoading,postError } = useApi<FormValues, Result>();
  const submitFunction = async (values: FormValues) => {
    return post(urlPath, values)
      .then(response => {
        (onSuccess != null) && onSuccess(response.data);
        return Promise.resolve(response.data);
      });
  };

  return [
    submitFunction,
    postLoading,
    postError,
  ];
}
