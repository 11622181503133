import axios, { AxiosRequestConfig, AxiosResponse, isAxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { usePopMessage } from '../context/PopMessageContext';
import { useNullableAuthenticationContext } from '../context/AuthenticationContext';
import { useBoolean } from './useBoolean';

interface UseApiResult<Request, Response>{
  post: (uri: string, request?: Request) => Promise<AxiosResponse<Response>>;
  put: (uri: string, request?: Request) => Promise<AxiosResponse<Response>>;
  get: (uri: string, request?: Request) => Promise<AxiosResponse<Response>>;
  sendDelete: (uri: string, request?: Request) => Promise<AxiosResponse<Response>>;
  postError: boolean;
  postLoading: boolean;
  putError: boolean;
  putLoading: boolean;
  getError: boolean;
  getLoading: boolean;
  deleteError: boolean;
  deleteLoading: boolean;
}

export default function useApi<Request, Response>(): UseApiResult<Request, Response>{

  const { send: sendPost, loading: postLoading, errorOccured: postError } = useSendRequest<Request, Response>();
  const { send: sendPut, loading: putLoading, errorOccured: putError } = useSendRequest<Request, Response>();
  const { send: sendGet, loading: getLoading, errorOccured: getError } = useSendRequest<Request, Response>();
  const { send: sendDeleteRequest, loading: deleteLoading, errorOccured: deleteError } = useSendRequest<Request, Response>();

  const post = useCallback((uri: string, request?: Request) => {
    return sendPost({
      method: 'post',
      url: uri,
      headers: {
        'Content-Type': request instanceof FormData ? 'multipart/form-data' : 'application/json',
      },
    }, request);
  }, []);

  const put = useCallback((uri: string, request?: Request) => {
    return sendPut({
      method: 'put',
      url: uri,
    }, request);
  }, [sendPut]);

  const get = useCallback((uri: string, request?: Request) => {
    return sendGet({
      method: 'get',
      url: uri,
      params: request
    }, request);
  }, [sendGet]);

  const sendDelete = useCallback((uri: string, request?: Request) => {
    return sendDeleteRequest({
      method: 'delete',
      url: uri,
      params: request
    }, request);
  }, [sendDeleteRequest]);

  return {
    post, postLoading, postError,
    put, putLoading, putError,
    get, getLoading, getError,
    sendDelete, deleteLoading, deleteError,
  };
}

interface UseFetchDtoResult<Request, Response>{
  fetch: (request?: Request) => void;
  result?: Response;
  setResult: (result?: Response) => void;
}

export function useFetchDto<Request, Response>(path: string): UseFetchDtoResult<Request, Response>{
  const { get } = useApi<Request, Response>();
  const { popError } = usePopMessage();
  const [result, setResult] = useState<Response>();
  const fetch = useCallback((request?: Request) => {
    get(path, request)
      .then(response => {
        setResult(response.data);
      })
      .catch(e => {
        popError(e);
      });
  }, [get, path, popError]);

  return { fetch, result, setResult };
}

interface UseSendRequestResult<Request, Response>{
  send:
    (config: AxiosRequestConfig<Request>, request?: Request)
      => Promise<AxiosResponse<Response>>;
  loading: boolean;
  errorOccured: boolean;
}

export function useSendRequest<Request, Response>(): UseSendRequestResult<Request, Response>{
  const { value: loading, setTrue: start, setFalse: stop } = useBoolean();
  const { value: errorOccured, setBoolean: setErrorOccured } = useBoolean();
  const authContext = useNullableAuthenticationContext();
  const { popError } = usePopMessage();

  const send = useCallback(async (config: AxiosRequestConfig<Request>, request?: Request) => {
    try {
      start();
      setErrorOccured(false);
      const response = await axios<Response>({
        ...config,
        url: `${process.env.REACT_APP_BASE_API_URI}/${config.url}`,
        data: config.method !== 'get' && {
          ...request,
        },
        params: config.method === 'get' && {
          ...request,
        },
        headers: {
          ...config.headers,
          Authorization: authContext?.accessToken ? `Bearer ${authContext.accessToken}` : undefined
        },
        withCredentials: true,
      });
      stop();
      return Promise.resolve(response);
    } catch (e) {
      setErrorOccured(true);
      stop();
      if(isAxiosError(e) && e.response?.status === 401 && authContext){
        authContext.logout();
        popError(e, 'Your session expired, please login in.', { vertical: 'top', horizontal: 'center' });
      }
      return Promise.reject(e);
    }
  }, []);

  return { send, loading, errorOccured };
}
