import React from 'react';
import { Box, Typography } from '@mui/material';

interface HttpErrorPageProps {
  code?: number;
  message?: string;
}

export default function HttpErrorPage({ code, message }: HttpErrorPageProps): React.ReactElement {
  return <Box
    sx={{
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    }}
  >
    <Typography variant="h1">{code ?? 'Unexpected error'}</Typography>
    {message && <Typography variant="h3">{message}</Typography>}
  </Box>;
}
