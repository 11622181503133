import React, { createContext, useContext } from 'react';
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { Controller, Path, useFormContext } from 'react-hook-form';

interface FormItemContextContent<FormValues extends object> {
  violation?: string;
  label: string;
  field: ControllerRenderProps<FormValues>;
}

export const FormItemContext = createContext<FormItemContextContent<any> | null>(null);

interface FormItemContextResult<FormValues extends object> {
  violation?: string;
  field: ControllerRenderProps<FormValues>;
  label: string;
}

export function useFormItemContext<FormValues extends object> (): FormItemContextResult<FormValues> {
  const context = useContext<FormItemContextContent<FormValues>>(FormItemContext as React.Context<FormItemContextContent<FormValues>>);
  if (context === null) {
    throw new Error('useFormItemContext called outside Context.');
  }

  return {
    violation: context.violation,
    field: context.field,
    label: context.label,
  };
}


interface FormItemProps<FormValues> {
  violation?: string;
  children: React.ReactNode;
  name: keyof FormValues & string & Path<FormValues>
  label: string
}

export default function FormItemProvider<FormValues extends object> ({
  violation,
  name,
  label,
  children
}: FormItemProps<FormValues>) {
  const { control } = useFormContext<FormValues>();
  return <Controller
    name={name}
    control={control}
    render={({ field }) => {
      return (
        <FormItemContext.Provider
          value={
            {
              violation,
              field: field as unknown as ControllerRenderProps<any>,
              label,
            }
          }
        >
          {children}
        </FormItemContext.Provider>
      );
    }}
  />;
}