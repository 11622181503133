import React from 'react';
import { TextField as MuiTextField, TextFieldProps } from '@mui/material';
import { useFormItemContext } from '../FormItemContext';


export function InputText<FormValues extends object> (props: TextFieldProps) {
  const { violation, field, label } = useFormItemContext<FormValues>();

  return (
    <MuiTextField
      {...field}
      {...props}
      label={label}
      helperText={violation}
      error={violation !== undefined}
      size="small"
      variant="outlined"
      margin="normal"
      fullWidth={true}
      sx={{ my: 1 }}
    />
  );
}