import * as React from 'react';
import { UserDto } from 'types';
import { Box, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import { useBoolean } from 'hooks/useBoolean';
import { ListItemLink } from 'components/ListItemLink/ListItemLink';
import UpdateEmployeeEmailDialog from 'components/Dialog/UpdateEmployeeEmailDialog/UpdateEmployeeEmailDialog';
import useApi from '../../hooks/api';
import DialogConfirmation from '../Dialog/DialogConfirmation';
import { usePopMessage } from '../../context/PopMessageContext';

interface EmployeeItemProps {
  employee: UserDto;
  refreshCompany: () => void;
}

export default function EmployeeItem({ employee, refreshCompany }: EmployeeItemProps) {
  const { value: visible, setTrue: open, setFalse: close } = useBoolean();
  const { sendDelete, deleteLoading } = useApi();
  const {
    value: deleteVisible,
    setTrue: openDelete,
    setFalse: closeDelete
  } = useBoolean();
  const { popError } = usePopMessage();

  return <ListItem sx={{ p: 0, display: 'flex' }}>
    <Box>
      <ListItemText
        primary={`${employee.firstName} ${employee.lastName}`}
        secondary={employee.email}
      />
    </Box>
    <Box sx={{ flex: 1, display: 'flex', mx: 1 }}>
      {
        employee.requests.map((requestSummary, index) =>
          <ListItemLink
            key={index}
            to={`/admin/requests/${requestSummary.id}`}
            sx={{ flex: 0 }}
          >
            <Typography sx={{ whiteSpace: 'nowrap' }}>{requestSummary.taxReturnName}</Typography>
          </ListItemLink>
        )
      }
    </Box>
    <Box>
      <ListItemButton sx={{ display: 'flex', justifyContent: 'center' }} onClick={open}>
        Update employee email
      </ListItemButton>
      <ListItemButton sx={{ display: 'flex', justifyContent: 'center' }} onClick={openDelete}>
        Delete User
      </ListItemButton>
      <DialogConfirmation
        onCancel={closeDelete}
        onConfirm={() => {
          sendDelete(`api/admin/employees/${employee.id}/delete`)
            .then(() => {
              closeDelete();
              refreshCompany();
            })
            .catch((e) => {
              popError(e);
            });
        }}
        loading={deleteLoading}
        open={deleteVisible}
      >
        <Typography>If you confirm, there is no possible going back.</Typography>
      </DialogConfirmation>
      <UpdateEmployeeEmailDialog
        visible={visible}
        close={close}
        refreshCompany={refreshCompany}
        employee={employee}
      />
    </Box>
  </ListItem>;
}
