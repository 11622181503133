import React from 'react';
import { useAdminFormContext } from 'hooks/AdminForm/AdminFormContext';
import AsyncDialog from 'components/Dialog/AsyncDialog';
import { useUpdateFormItem } from 'hooks/useUpdateFormItem';
import BaseForm from 'components/Form/BaseForm/BaseForm';
import { useFetchAdminRadioBox } from 'hooks/useFetchDto';
import { Box, Button, Divider, List, ListItem, ListItemText, Typography } from '@mui/material';
import CreateRadioBoxItemDialog from 'components/Dialog/CreateRadioBoxItemDialog/CreateRadioBoxItemDialog';
import { useBoolean } from 'hooks/useBoolean';

interface UpdateFormItemDialogProps {
  visible: boolean;
  close: () => void;
  radioBoxId: string,
}

export default function UpdateRadioBoxDialog({
  visible,
  close,
  radioBoxId,
}: UpdateFormItemDialogProps) {

  const { setForm } = useAdminFormContext();
  const {
    value: visibleAddItem,
    setTrue: openAddItem,
    setFalse: closeAddItem,
  } = useBoolean();
  const [radioBox, loading, errorOccured, fetchRadioBox, setRadioBox] = useFetchAdminRadioBox(radioBoxId);
  const { submit } = useUpdateFormItem(`api/admin/radio-boxes/${radioBoxId}/update`, {
    onSuccess: response => {
      setForm(response);
      close();
    }
  });

  return <AsyncDialog
    open={visible}
    onClose={close}
    title="Update radio box"
    loading={loading}
    errorOccured={errorOccured}
    reload={fetchRadioBox}
    result={radioBox}
  >
    {(loadedRadioBox) => {
      return <>
        <Box sx={{ mb: 3 }}>
          <Typography sx={{ fontWeight: 'bold' }}>Informations</Typography>
          <BaseForm
            onSubmit={submit}
            urlValidation={`api/admin/radio-boxes/${radioBoxId}/update/validate`}
            initialValues={{
              label: loadedRadioBox?.label ?? '',
              description: loadedRadioBox?.description ?? ''
            }}
            inputs={[
              {
                label: 'Label',
                name: 'label'
              },
              {
                label: 'Description',
                name: 'description'
              }
            ]}
          />
        </Box>
        <Box>
          <Typography sx={{ fontWeight: 'bold' }}>Items</Typography>
          <List>
            <Divider/>
            {
              radioBox?.items.map((item, index) => {
                return <>
                  <ListItem key={index}>
                    <ListItemText primary={item.label}/>
                  </ListItem>
                  <Divider/>
                </>;
              })
            }
          </List>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button onClick={openAddItem} variant="contained" color="primary" sx={{ mt: 1 }}>Add item</Button>
          </Box>
        </Box>
        <CreateRadioBoxItemDialog
          visible={visibleAddItem}
          close={closeAddItem}
          radioBoxId={radioBoxId}
          setRadioBox={setRadioBox}
        />
      </>;
    }}
  </AsyncDialog>;
}