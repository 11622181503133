import React from 'react';
import { Alert, Box, Button, Grid, List, ListItemText, Paper, Typography } from '@mui/material';
import { useFetchCompanies } from 'hooks/useFetchDto';
import AsyncComponent from 'components/AsyncComponent/AsyncComponent';
import { useBoolean } from 'hooks/useBoolean';
import CreateCompanyDialog from 'components/Dialog/CreateCompanyDialog/CreateCompanyDialog';
import { ListItemLink } from 'components/ListItemLink/ListItemLink';

export function ListCompanies(): React.ReactElement {
  const [companies, loading, errorOccured, fetch] = useFetchCompanies();
  const {
    value: visible,
    setTrue: open,
    setFalse: close,
  } = useBoolean();

  return <Grid container spacing={2} sx={{ p: 2 }}>
    <Grid xs={12} item sx={{ justifyContent: 'center', display: 'flex' }}>
      <AsyncComponent loading={loading} errorOccured={errorOccured} reload={fetch} result={companies}>
        {
          loadedCompanies => {
            return <Paper sx={{ p: 2, minWidth: 400 }}>
              {
                loadedCompanies.length === 0 && <Alert color="warning" sx={{ alignItems: 'center' }}>
                  <Typography>No company saved
                    <Button variant="text" color="warning" onClick={open}>Create one</Button>
                  </Typography>
                </Alert>
              }
              {
                loadedCompanies.length > 0 && <>
                  <Box sx={{ textAlign: 'right' }}>
                    <Button onClick={open}>New company</Button>
                  </Box>
                  <List>
                    {
                      loadedCompanies?.map((company, index) => <ListItemLink
                        key={index}
                        to={`/admin/companies/${company.id}`}
                      >
                        <ListItemText
                          primary={company.name}
                          secondary={`${company.nbEmployers} employer(s) / ${company.nbEmployees} employee(s)`}
                        />
                      </ListItemLink>
                      )
                    }
                  </List>
                </>
              }
            </Paper>;
          }
        }
      </AsyncComponent>
      <CreateCompanyDialog visible={visible} close={close} fetchCompanies={fetch}/>
    </Grid>
  </Grid>;
}

