import React from 'react';
import { Control } from 'react-hook-form';

import { FieldType, FormFieldDto } from 'types';
import FormFieldTitle from './Fields/FormFieldTitle';
import FormFieldInputText from './Fields/FormFieldInputText';
import FormFieldRadioBox from './Fields/FormFieldRadioBox';
import FormFieldDatePicker from './Fields/FormFieldDatePicker';
import FormFieldWorkday from './Fields/FormFieldWorkday';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import FormFieldCheckBox from 'components/TaxReturn/Fields/FormFieldCheckBox';
import FormFieldInputGroup from 'components/TaxReturn/Fields/FormFieldInputGroup';
import FormFieldInputFile from 'components/TaxReturn/Fields/FormFieldInputFile';

export interface FormFieldDispatcherProps<FormValues extends object> {
  formField: FormFieldDto<FormValues>;
  control: Control<FormValues, object>;
  setValue: UseFormSetValue<FormValues>;
  editable: boolean;
}

export default function FormFieldDispatcher<FormValues extends object>({
  formField,
  control,
  setValue,
  editable,
}: FormFieldDispatcherProps<FormValues>) {
  switch (formField.type) {
  case FieldType.TITLE:
    return <FormFieldTitle formField={formField} editable={editable}/>;
  case FieldType.INPUT_FILE:
    return <FormFieldInputFile formField={formField} editable={editable}/>;
  case FieldType.INPUT_TEXT:
    return <FormFieldInputText
      formField={formField}
      id={formField.id}
      control={control}
      setValue={setValue}
      editable={editable}
    />;
  case FieldType.RADIO_BOX:
    return <FormFieldRadioBox
      id={formField.id}
      formField={formField}
      control={control}
      setValue={setValue}
      editable={editable}
    />;
  case FieldType.DATE_PICKER:
    return <FormFieldDatePicker
      formField={formField}
      id={formField.id}
      control={control}
      setValue={setValue}
      editable={editable}
    />;
  case FieldType.WORKDAY:
    return <FormFieldWorkday
      formField={formField}
      id={formField.id}
      control={control}
      setValue={setValue}
      editable={editable}
    />;
  case FieldType.CHECK_BOX:
    return <FormFieldCheckBox
      formField={formField}
      id={formField.id}
      control={control}
      setValue={setValue}
      editable={editable}
    />;
  case FieldType.INPUT_GROUP:
    return <FormFieldInputGroup formField={formField} editable={editable}/>;
  }
  return (
    <p>
      Unable to display form item {formField.id} with type {formField.type}
    </p>
  );
}
