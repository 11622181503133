import { AdminFormDto } from 'types';
import { useAdminFormContext } from 'hooks/AdminForm/AdminFormContext';
import useApi from './api';

export function useCreateFormItem<FormValues>(sheetId: string, urlPath: string, closeModale: () => void): [
  (values: FormValues) => Promise<AdminFormDto>,
  boolean,
  boolean
] {
  const { setForm } = useAdminFormContext();

  const { post, postLoading,postError } = useApi<FormValues, AdminFormDto>();
  const submitFunction = async (values: FormValues) => {
    return post(`api/admin/sheets/${sheetId}/${urlPath}`, values)
      .then(response => {
        setForm(response.data);
        closeModale();
        return Promise.resolve(response.data);
      });
  };

  return [
    submitFunction,
    postLoading,
    postError,
  ];
}
