import React from 'react';
import BaseInputPassword from 'components/Form/BaseForm/Fields/BaseInputPassword';
import BaseForm from 'components/Form/BaseForm/BaseForm';
import { ActivateAccountFormValues, useActivateAccount } from 'hooks/Account/useActivateAccount';
import { useNavigate } from 'react-router-dom';
import { usePopMessage } from '../../context/PopMessageContext';
import { useAuthenticationContext } from '../../context/AuthenticationContext';

interface ActivateAccountFormProps {
  token: string;
}

export default function ActivateAccountForm({ token }: ActivateAccountFormProps) {

  const initials: ActivateAccountFormValues = {
    email: '',
    password: '',
    password_confirmation: '',
    token: token,
  };
  const navigate = useNavigate();
  const { setJwt } = useAuthenticationContext();

  const { popSuccess } = usePopMessage();
  const [submit] = useActivateAccount({
    onSuccess: (tokenResponse) => {
      navigate('/');
      setJwt(tokenResponse.jwt);
      popSuccess('Your account has been activated');
    }
  });

  return (
    <BaseForm
      onSubmit={values => {
        return submit({ ...values, timezoneOffset:(new Date()).getTimezoneOffset(), });
      }}
      urlValidation={'api/account/activate/validate'}
      initialValues={initials}
      submitBtnLabel={'Activate account'}
      inputs={[
        {
          name: 'email',
          label: 'Email'
        },
        {
          name: 'password',
          render: () => {
            return <BaseInputPassword/>;
          },
          label: 'Password'
        },
        {
          name: 'password_confirmation',
          render: () => {
            return <BaseInputPassword/>;
          },
          label: 'Password confirmation'
        }
      ]}
    />
  );
}