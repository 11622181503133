import { SummaryCompanyDto } from 'types';
import { useSubmitForm, UseSubmitFormOptions } from 'hooks/useSubmitForm';

export interface CreateCompanyFormValues {
  name?: string;
}

export function useCreateCompany(props: UseSubmitFormOptions<CreateCompanyFormValues>): [
  submit: (values: CreateCompanyFormValues) => Promise<SummaryCompanyDto>,
  loading: boolean,
  errorOccured: boolean,
] {
  return useSubmitForm<CreateCompanyFormValues, SummaryCompanyDto>({
    ...props,
    urlPath: 'api/admin/companies/create',
  });
}
