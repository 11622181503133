import React from 'react';
import BaseAsyncSelect from 'components/Form/BaseForm/Fields/BaseAsyncSelect';

interface BaseSelectProps<OptionType> {
  options: OptionType[];
  getOptionValue: (option: OptionType) => string;
  getOptionLabel: (option: OptionType) => string;
  multiple?: boolean;
}

export default function BaseSelect<OptionType, FormValues extends object>(props: BaseSelectProps<OptionType>) {
  return <BaseAsyncSelect {...props} loading={false}/>;
}
