import { AdminFormDto, UpdateSheetFormValues } from 'types';
import useApi from '../api';

interface UpdateFormSheetProps {
  onSuccess?: (response: AdminFormDto) => void;
  willCallBackend?: () => void;
}

interface UpdateFormSheetResult {
  submit: (values: UpdateSheetFormValues) => Promise<AdminFormDto>;
  loading: boolean;
  errorOccured: boolean;
}

export function useUpdateFormSheet(sheetId: string, { onSuccess }: UpdateFormSheetProps): UpdateFormSheetResult {

  const { post, postLoading,postError } = useApi<UpdateSheetFormValues, AdminFormDto>();
  const submitFunction = async (
    values: UpdateSheetFormValues
  ) => {
    return post(`api/admin/sheets/${sheetId}/update`, values)
      .then(response => {
        onSuccess && onSuccess(response.data);
        return Promise.resolve(response.data);
      });
  };

  return {
    loading: postLoading,
    errorOccured: postError,
    submit: submitFunction
  };
}
