import React, { useEffect } from 'react';
import Page from 'components/Page/Page';
import { Alert, Box, Chip, Grid, IconButton, List, ListItem, ListItemText, Paper, Typography } from '@mui/material';
import { useFetchEmployeeTaxReturnRequests } from 'hooks/useFetchDto';
import AsyncComponent from 'components/AsyncComponent/AsyncComponent';
import { taxReturnRequestStatusMapping } from 'types';
import moment from 'moment';
import { ListItemLink } from 'components/ListItemLink/ListItemLink';
import Downloader from 'components/Downloader/Downloader';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

export default function EmployeeHomePage(): React.ReactElement {

  const [requests, loading, errorOccured, fetchRequests] = useFetchEmployeeTaxReturnRequests();

  useEffect(() => {
    fetchRequests();
  }, []);
  return <Page title="Dashboard">
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid xs={12} item sx={{ justifyContent: 'center', display: 'flex' }}>
        <Paper sx={{ p: 2, minWidth: 650 }}>
          <AsyncComponent
            loading={loading}
            errorOccured={errorOccured}
            reload={fetchRequests}
            result={requests}
          >
            {
              loadedRequests => <>
                {
                  loadedRequests.length === 0 && <Alert color="warning" sx={{ alignItems: 'center' }}>
                    <Typography>No tax return declaration assigned for you</Typography>
                  </Alert>
                }
                {
                  loadedRequests.length > 0 && <List>
                    {
                      loadedRequests?.map((request, index) => <ListItem
                        key={index}
                        sx={{ p: 0 }}
                      >
                        <ListItemLink
                          to={`/employee/tax-return-requests/${request.id}`}
                          key={index}
                        >
                          <Box sx={{ display: 'flex', flex: 1 }}>
                            <ListItemText
                              primary={request.taxReturn.name}
                              secondary={`Expiration: ${moment.utc(request.expirationDate).local().format('D MMMM YYYY')}`}
                              sx={{ flex: 2 }}
                            />
                            <Box sx={{
                              flex: 3,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'end',
                            }}
                            >
                              <Chip
                                label={taxReturnRequestStatusMapping[request.status].label}
                                sx={{
                                  background: taxReturnRequestStatusMapping[request.status].backgroundColor,
                                  color: taxReturnRequestStatusMapping[request.status].contentColor,
                                }}
                              />
                            </Box>
                          </Box>
                        </ListItemLink>
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          pl: 3,
                        }}>
                          {
                            request.file ? <Downloader
                              url={`${process.env.REACT_APP_BASE_API_URI}/api/storage/${request.file.id}`}
                              fileName={request.file.fileName}
                            >
                              {
                                download => <IconButton onClick={download}>
                                  <FileDownloadIcon/>
                                </IconButton>
                              }
                            </Downloader>
                              : <Typography sx={{ color: '#CCC' }}>No file available</Typography>
                          }
                        </Box>
                      </ListItem>
                      )
                    }
                  </List>
                }
              </>
            }
          </AsyncComponent>
        </Paper>
      </Grid>
    </Grid>
  </Page>;
}
