import React from 'react';
import { useParams } from 'react-router-dom';
import { useFetchTaxReturnRequest } from 'hooks/useFetchDto';
import AsyncComponent from 'components/AsyncComponent/AsyncComponent';
import Page from 'components/Page/Page';
import ChipTaxReturnRequest from 'components/Chip/ChipTaxReturnRequest';
import { TaxReturnRequestProvider } from 'hooks/TaxReturn/TaxReturnContext';
import TaxReturnViewer from 'components/TaxReturn/TaxReturnViewer';
import { Box, Button } from '@mui/material';
import Downloader from 'components/Downloader/Downloader';

export default function TaxReturnRequestPage(): React.ReactElement {
  const { id } = useParams<'id'>();
  const [taxReturn, isLoading, errorOccured, fetch, setRequest] = useFetchTaxReturnRequest(id);

  return (
    <AsyncComponent
      loading={isLoading}
      errorOccured={errorOccured}
      reload={fetch}
      result={taxReturn}
    >
      {
        loadedRequest => <Page
          title={
            <>
              {`${loadedRequest.taxReturn.name}`}
              <ChipTaxReturnRequest status={loadedRequest.status} sx={{ ml: 2 }}/>
            </>
          }
        >
          <>
            {
              loadedRequest.file && <Box>
                <Downloader
                  url={`${process.env.REACT_APP_BASE_API_URI}/api/storage/${loadedRequest.file.id}`}
                  fileName={loadedRequest.file.fileName}
                >
                  {
                    download => <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                      <Button
                        variant="contained"
                        size="large"
                        onClick={download}
                      >
                        Download your tax return declaration
                      </Button>
                    </Box>
                  }
                </Downloader>
              </Box>
            }
            <TaxReturnRequestProvider request={loadedRequest} setRequest={setRequest}>
              <TaxReturnViewer editable={loadedRequest.editable}/>
            </TaxReturnRequestProvider>
          </>
        </Page>
      }
    </AsyncComponent>
  );
}
