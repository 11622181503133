import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { frFR } from '@mui/material/locale';
import { deepmerge } from '@mui/utils';
import { Box as MuiBox, CssBaseline as MuiCssBaseline, Typography as MuiTypography } from '@mui/material';

import { HandleBackendProvider } from 'hooks/HandleBackendContext';
import Router from './router';
import { PopMessageProvider } from './context/PopMessageContext';

import './app.css';
import { AuthenticationProvider } from './context/AuthenticationContext';

let theme = createTheme(
  {
    palette: {
      primary: {
        main: '#3949ab'
      }
    },
    typography: {
      h5: {
        fontWeight: 500,
        fontSize: 26,
        letterSpacing: 0.5
      }
    },
    shape: {
      borderRadius: 8
    },
    mixins: {
      toolbar: {
        minHeight: 48
      }
    }
  },
  frFR
);
theme = deepmerge(theme, {
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#081627'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none'
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1)
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(255,255,255,0.15)'
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#4fc3f7'
          }
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          minWidth: 'auto',
          marginRight: theme.spacing(2),
          '& svg': {
            fontSize: 20
          }
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32
        }
      }
    }
  }
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <PopMessageProvider>
        <AuthenticationProvider>
          <HandleBackendProvider>
            <MuiBox
              id="app-content"
              sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column', background: '#dddddd' }}
            >
              <MuiCssBaseline/>
              <Router/>
              <MuiBox component="footer" sx={{ p: 2, background: '#ffffff' }}>
                <MuiTypography variant="body2" color="text.secondary" align="center">
                  Current version: {process.env.REACT_APP_VERSION}
                </MuiTypography>
              </MuiBox>
            </MuiBox>
          </HandleBackendProvider>
        </AuthenticationProvider>
      </PopMessageProvider>
    </ThemeProvider>
  );
};

export default App;
