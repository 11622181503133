import React from 'react';
import { Control } from 'react-hook-form';
import { Typography as MuiTypography } from '@mui/material';

import { FormSheetDto } from 'types';
import FormFieldDispatcher from './FormFieldDispatcher';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';

export interface SheetViewerProps<FormValues extends object> {
  sheet: FormSheetDto<FormValues>;
  control: Control<FormValues, object>;
  setValue: UseFormSetValue<FormValues>;
  editable: boolean;
}

export default function SheetViewer<FormValues extends object>({
  sheet,
  control,
  setValue,
  editable,
}: SheetViewerProps<FormValues>) {
  return (
    <>
      <MuiTypography
        component="h2"
        sx={{ textAlign: 'center', textTransform: 'uppercase', fontSize: 40 }}
      >
        {sheet.name}
      </MuiTypography>
      {sheet.fields.map(field => {
        return (
          <FormFieldDispatcher
            key={field.id}
            control={control}
            formField={field}
            setValue={setValue}
            editable={editable}
          />
        );
      })}
    </>
  );
}
