import React, { createContext, useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { jwtDecode } from 'jwt-decode';

interface AuthenticationContextResult{
  accessToken?: string;
  role?: string;
  setJwt: (jwt: string) => void;
  setAccessToken: (token?: string) => void;
  logout: () => void;
}

interface DecodedJwtResult{
  accessToken: string;
  role: 'ADMIN' | 'EMPLOYEE' | 'EMPLOYER';
}

export const AuthenticationContext = createContext<AuthenticationContextResult | null>(null);

interface AuthenticationProviderProps {
  children: React.ReactElement;
}

export const useAuthenticationContext = (): AuthenticationContextResult => {
  const context = useContext(AuthenticationContext);
  if (context === null) {
    throw new Error('authenticationContext called outside Context.');
  }

  return context;
};

export const useNullableAuthenticationContext = (): AuthenticationContextResult | null => {
  return useContext(AuthenticationContext);
};

export const AuthenticationProvider = ({ children }: AuthenticationProviderProps) => {
  const [cookies, setCookie, removeCookie] = useCookies(['jwt']);
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const [role, setRole] = useState<'ADMIN' | 'EMPLOYEE' | 'EMPLOYER' | undefined>(undefined);

  const setJwt = function(jwt: string){
    setCookie('jwt', jwt, { path: '/' });
    const token = jwtDecode<DecodedJwtResult>(jwt);
    setRole(token.role);
    setAccessToken(token.accessToken);
  };

  const logout = () => {
    removeCookie('jwt', { path: '/' });
    setAccessToken(undefined);
    setRole(undefined);
  };


  useEffect(() => {
    if(cookies.jwt){
      setJwt(cookies.jwt);
    }
  }, []);

  return <AuthenticationContext.Provider
    value={{
      setJwt,
      accessToken,
      setAccessToken,
      role,
      logout
    }}
  >
    {
      children
    }
  </AuthenticationContext.Provider>;
};