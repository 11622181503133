import React from 'react';
import { Typography as MuiTypography } from '@mui/material';

import { FormFieldWithChange } from './utils';
import FormFieldContainer from './FormFieldContainer';
import DataViewer from 'components/DataViewer/DataViewer';
import ControlledInputText from '../../Form/ControlledInputs/ControlledInputText';
import { InputTextDto } from 'types';

export default function FormFieldInputText<FormValues extends object>({
  id,
  formField,
  control,
  editable,
}: FormFieldWithChange<FormValues>) {
  const inputText = formField.data as InputTextDto;
  return (
    <FormFieldContainer
      formField={inputText}
      formFieldId={id}
      skeletonProps={{ variant: 'text', height: 50 }}
      render={field => {
        return (
          <>
            {field.description && (
              <MuiTypography>{field.description}</MuiTypography>
            )}
            {editable
              ? (
                <ControlledInputText
                  label={field.label}
                  name={id}
                  control={control}
                  isValidating={false}
                  violations={[]}
                  defaultValue={field.value ?? undefined}
                />
              )
              : (
                <DataViewer
                  label={field.label}
                  value={field.value ?? undefined}
                />
              )}
          </>
        );
      }}
    />
  );
}
