import React, { ReactElement, ReactNode, useState } from 'react';
import {
  DefaultValues,
  FormProvider,
  Path,
  useForm,
} from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { AxiosError } from 'axios';
import { InputText } from './Fields/InputText';
import FormItemProvider from './FormItemContext';
import { Box, Button } from '@mui/material';
import { usePopMessage } from '../../context/PopMessageContext';
import { useBoolean } from '../../hooks/useBoolean';

export interface FormItemProps<FormValues>{
  render?: (violation?:string) => ReactNode;
  name: keyof FormValues & string & Path<FormValues>;
  label: string;
}

type ViolationsType<FormValues> = {
  [K in keyof FormValues]?: string;
}
interface FormProps<FormValues, ReturnType>{
  defaultValues?: DefaultValues<FormValues>;
  onSubmit: (values: FormValues) => Promise<ReturnType>;
  onError?: (err: AxiosError) => void;
  onSuccess?: (response: ReturnType) => void;
  inputs: FormItemProps<FormValues>[]
  submitLabel?: ReactNode
  renderSubmit?: (isSubmitting: boolean) => ReactNode
}

export default function Form<ReturnType, FormValues extends FieldValues = FieldValues>(
  { defaultValues, onSubmit, inputs, onSuccess, onError, submitLabel, renderSubmit }: FormProps<FormValues, ReturnType>
): ReactElement {

  const form = useForm<FormValues, unknown>({ defaultValues });
  const { handleSubmit } = form;
  const {
    value: isSubmitting,
    setTrue: startSubmit,
    setFalse: stopSubmit,
  } = useBoolean();
  const [violations, setViolations] = useState<ViolationsType<FormValues>>({});
  const { popError } = usePopMessage();
  const submitFunction = (values: FormValues) => {
    setViolations({});
    startSubmit();
    onSubmit(values)
      .then((response) => {
        onSuccess && onSuccess(response);
      })
      .catch((err: AxiosError<{errors: ViolationsType<FormValues>}>)=> {
        if(err.response?.status === 400){
          setViolations(err.response.data.errors);
        }else{
          if(onError){
            onError(err);
          }else{
            popError(err);
          }
        }
      })
      .finally(stopSubmit);
  };

  return <FormProvider {...form}>
    <form
      onSubmit={handleSubmit(submitFunction)}
    >
      {
        inputs.map((input, index) => {
          return <FormItemProvider<FormValues>
            violation={violations[input.name]}
            label={input.label}
            name={input.name}
            key={index}
          >
            {input.render ? input.render(violations[input.name] ?? undefined) : <InputText />}
          </FormItemProvider>;
        })
      }
      {
        renderSubmit
          ? renderSubmit(isSubmitting)
          : <Box marginTop={2} display='flex' justifyContent='end'>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={isSubmitting}
            >
              {
                isSubmitting
                  ? 'Patientez'
                  : submitLabel ? submitLabel : 'Enregistrer'
              }
            </Button>
          </Box>
      }
    </form>
  </FormProvider>;
}