import * as React from 'react';
import { AppBar as MuiAppBar, Tab as MuiTab, TabProps, Tabs as MuiTabs } from '@mui/material';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';

import { useAuthenticationContext } from '../../context/AuthenticationContext';
import { forwardRef, useMemo } from 'react';

const routes = ['/', '/admin/tax-returns', '/admin/companies', '/admin/users', '/admin/create-employee-requests'];
export default function Navbar() {
  const { pathname } = useLocation();
  const { role } = useAuthenticationContext();
  const currentTab =
    routes.filter(pattern => matchPath(pattern, pathname))[0] ?? '404';

  return (
    <MuiAppBar
      component="div"
      position="static"
      elevation={0}
      sx={{ zIndex: 0 }}
    >
      <MuiTabs value={currentTab} textColor="inherit">
        <MuiTabLink to='/' value='/' label='Dashboard' />
        {role === 'ADMIN' && <MuiTabLink
          to='/admin/tax-returns'
          value='/admin/tax-returns'
          label='Tax returns' />}
        {role === 'ADMIN' && <MuiTabLink
          to='/admin/companies'
          value='/admin/companies'
          label='Companies' />}
        {role === 'ADMIN' && <MuiTabLink
          to='/admin/create-employee-requests'
          value='/admin/create-employee-requests'
          label='Create employee requests' />}
        <MuiTab value="404"/>
      </MuiTabs>
    </MuiAppBar>
  );
}

interface MuiTabLinkProps extends Omit<TabProps, 'component'|'ref'>{
  to: string;
}

export function MuiTabLink({ to,...props }: MuiTabLinkProps): React.ReactElement {
  const renderLink = useMemo(
    () =>
      forwardRef(function Link(itemProps, ref: React.Ref<HTMLAnchorElement>) {
        return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
      }),
    [to]
  );

  return <MuiTab {...props} component={renderLink} />;
}
