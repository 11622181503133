import React from 'react';
import { ListItemButton, ListItemText } from '@mui/material';
import ItemEditor from '../ItemEditor';
import { AdminFormFieldDto } from 'types';
import UpdateInputGroupDialog from 'components/Dialog/UpdateInputGroupDialog/UpdateInputGroupDialog';
import { useBoolean } from 'hooks/useBoolean';

export interface InputGroupEditorProps {
  field: AdminFormFieldDto;
}

export default function InputGroupEditor({
  field
}: InputGroupEditorProps) {

  const {
    value: visible,
    setTrue: open,
    setFalse: close,
  } = useBoolean();

  return (
    <ItemEditor
      label={'INPUT GROUP'}
      actions={<ListItemButton sx={{ flex: 0 }} onClick={open}>Edit</ListItemButton>}
    >
      <>
        <ListItemText sx={{ px: 1, textAlign: 'center' }} primary={field.label}/>
        {
          visible && <UpdateInputGroupDialog visible={visible} close={close} inputGroupId={field.id}/>
        }
      </>
    </ItemEditor>
  );
}
