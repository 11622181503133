import React from 'react';
import {
  Checkbox as MuiCheckbox,
  CheckboxProps,
  FormControl as MuiFormControl,
  FormControlLabel as MuiFormControlLabel
} from '@mui/material';
import { Control, Controller, Path, PathValue } from 'react-hook-form';

interface ControlledCheckBoxProps<FormValues extends object> extends Omit<CheckboxProps, 'defaultValue'> {
  label?: string;
  control: Control<FormValues, object>;
  name: string & Path<FormValues>;
  defaultValue: boolean;
}

export default function ControlledCheckBox<FormValues extends object>({
  label,
  control,
  name,
  defaultValue,
  ...props
}: ControlledCheckBoxProps<FormValues>) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={
        defaultValue as
          | PathValue<FormValues, string & Path<FormValues>>
          | undefined
      }
      render={({ field }) => {
        return (
          <MuiFormControl
            {...field}
            margin="normal"
            variant="outlined"
            sx={{ my: 1 }}
            fullWidth
          >
            <MuiFormControlLabel
              control={<MuiCheckbox checked={field.value as boolean} {...{ ...field, ...props }} />}
              label={label}
              name={field.name}
            />
          </MuiFormControl>
        );
      }}
    />
  );
}
