import React from 'react';

import BasicDialog from 'components/Dialog/BasicDialog';
import { UpdateSheetFormValues } from 'types';
import { useAdminFormContext } from 'hooks/AdminForm/AdminFormContext';
import BaseForm from '../../Form/BaseForm/BaseForm';
import { useCreateFormSheet } from 'hooks/FormSheet/useCreateFormSheet';

interface CreateSheetDialogProps {
  visible: boolean;
  close: () => void;
}

export default function CreateSheetDialog({
  visible,
  close
}: CreateSheetDialogProps) {

  const { setForm, form } = useAdminFormContext();

  const { submit } = useCreateFormSheet(form.id, {
    onSuccess: response => {
      setForm(response);
      close();
    }
  });
  const initials: UpdateSheetFormValues = {
    name: ''
  };

  return (
    <BasicDialog
      open={visible}
      onClose={close}
      title="Create new sheet"
    >
      <BaseForm
        urlValidation={'api/admin/sheets/create/validate'}
        initialValues={initials}
        inputs={[{ name: 'name', label: 'Name' }]}
        onSubmit={submit}
      />
    </BasicDialog>
  );
}