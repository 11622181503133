import React, { useEffect } from 'react';

import BasicDialog from 'components/Dialog/BasicDialog';
import BaseForm from '../../Form/BaseForm/BaseForm';
import { useFetchEmployees } from 'hooks/useFetchDto';
import BaseDatePicker from 'components/Form/BaseForm/Fields/BaseDatePicker';
import BaseAsyncSelect from 'components/Form/BaseForm/Fields/BaseAsyncSelect';
import { Alert, Typography } from '@mui/material';
import {
  CreateTaxReturnRequestFormValues,
  useCreateTaxReturnRequests
} from 'hooks/TaxReturnRequest/useCreateTaxReturnRequests';

interface CreateNewTaxReturnRequestDialogProps {
  visible: boolean;
  close: () => void;
  fetchRequests: () => void;
  taxReturnId: string;
}

export default function CreateNewTaxReturnRequestDialog({
  visible,
  close,
  fetchRequests,
  taxReturnId,
}: CreateNewTaxReturnRequestDialogProps) {

  const [employees, loadingEmployees, , fetch] = useFetchEmployees();

  useEffect(() => {
    fetch();
  }, []);
  const [submit] = useCreateTaxReturnRequests(taxReturnId, {
    onSuccess: () => {
      fetchRequests();
      close();
    }
  });

  return (
    <BasicDialog
      open={visible}
      onClose={close}
      title="Manage requests"
    >
      <Typography>
        If a request already exists for the user, the app send him an email to notify expiration date is updated.
        If there is no request for the user, the request will be created and the employee will be notified.
      </Typography>
      <Alert color="info" sx={{ my: 2 }}>Only employees with activated account are in the list</Alert>
      <BaseForm
        initialValues={{ expirationDate: '', employees: [] }}
        urlValidation={`api/admin/tax-returns/${taxReturnId}/requests/create/validate`}
        inputs={[
          {
            name: 'expirationDate',
            label: 'Expiration date',
            render: () => <BaseDatePicker/>
          },
          {
            name: 'employees',
            label: 'Employees',
            render: () => <BaseAsyncSelect
              options={employees ?? []}
              loading={loadingEmployees}
              getOptionValue={option => option.id}
              getOptionLabel={option => `${option.firstName} ${option.lastName}`}
              multiple
            />,
          }
        ]}
        onSubmit={(values: CreateTaxReturnRequestFormValues) => {
          return submit(values);
        }}
      />
    </BasicDialog>
  );
}