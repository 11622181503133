import * as React from 'react';
import {
  AppBar as MuiAppBar,
  Button as MuiButton,
  Grid as MuiGrid,
  Toolbar as MuiToolbar,
  Typography as MuiTypography
} from '@mui/material';

import Navbar from '../Navbar/Navbar';
import { useAuthenticationContext } from '../../context/AuthenticationContext';

export default function HomeHeader() {
  const { logout } = useAuthenticationContext();
  return (
    <React.Fragment>
      <MuiAppBar
        component="div"
        color="primary"
        position="static"
        elevation={0}
        sx={{ zIndex: 0 }}
      >
        <MuiToolbar>
          <MuiGrid container alignItems="center" spacing={1}>
            <MuiGrid item xs>
              <MuiTypography color="inherit" variant="h5" component="h1">
                MPGLOBALTAX
              </MuiTypography>
            </MuiGrid>
            <MuiGrid item>
              <MuiButton variant="contained" color="primary" onClick={() => {
                logout();
              }}>
                Logout
              </MuiButton>
            </MuiGrid>
          </MuiGrid>
        </MuiToolbar>
      </MuiAppBar>
      <Navbar/>
    </React.Fragment>
  );
}
