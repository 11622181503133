import useApi from './api';
import { useCallback } from 'react';

interface ReorderFields {
  submit: (values?: string[]) => Promise<unknown>;
}

export function useReorderFields(sheetId: string): ReorderFields {

  const { post } = useApi();
  const submit = useCallback((newIds?: string[]) => {
    return post(`api/admin/sheets/${sheetId}/form-fields/reorder`, {
      newOrder: newIds
    });
  }, []);

  return {
    submit
  };
}
