import React from 'react';
import {
  TextField as MuiTextField, TextFieldProps
} from '@mui/material';
import { deepmerge } from '@mui/utils';

import { useBaseFormContext } from '../BaseFormContext';
import { useBaseFormItemContext } from '../BaseFormItemContext';

export default function BaseInputText<FormValues extends object> (props: TextFieldProps) {
  const { formState: { isValidating } } = useBaseFormContext<FormValues>();
  const { violations, field, label } = useBaseFormItemContext<FormValues>();
  const finalProps = deepmerge(field, deepmerge(props, {
    size: 'small',
    variant: 'outlined',
    margin: 'normal',
    label,
    fullWidth: true,
    sx: { my: 1 }
  }));

  return (
    <MuiTextField
      {...finalProps}
      helperText={
        violations.length > 0 && !isValidating && violations[0].message
      }
      error={violations.length > 0 && !isValidating}
    />
  );
}
