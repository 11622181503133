import React from 'react';
import PaperWithTitle from '../Paper/PaperWithTitle';
import VerticalTabs from '../Tabs/VerticalTabs/VerticalTabs';
import SheetEditor from './SheetEditor/SheetEditor';
import { useAdminFormContext } from 'hooks/AdminForm/AdminFormContext';
import { Button } from '@mui/material';
import SheetTab from './SheetTab';
import CreateSheetDialog from '../Dialog/CreateSheetDialog/CreateSheetDialog';
import { useBoolean } from 'hooks/useBoolean';

export default function FormEditor() {

  const { form } = useAdminFormContext();
  const {
    value: visible,
    setTrue: open,
    setFalse: close,
  } = useBoolean();

  return (
    <PaperWithTitle title="Form editor">
      <div
        style={{
          display: 'flex'
        }}
      >
        <VerticalTabs
          items={[...form.sheets.map(sheet => {
            return {
              label: <SheetTab sheet={sheet}/>,
              panel: <SheetEditor sheet={sheet}/>,
              props: { sx: { p: 0 } }
            };
          })]}
          tabAction={<Button variant="contained" color="primary" onClick={open}>Create new sheet</Button>}
        />
      </div>
      <CreateSheetDialog visible={visible} close={close}/>
    </PaperWithTitle>
  );
}
