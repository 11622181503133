import React from 'react';

import BasicDialog from 'components/Dialog/BasicDialog';
import { WorkdayItemDto } from 'types';
import BaseForm from '../../Form/BaseForm/BaseForm';

interface AddCountryWorkdayDialogProps {
  visible: boolean;
  close: () => void;
  content: Array<WorkdayItemDto>;
  setContent: (newContent: Array<WorkdayItemDto>) => void;
}

export default function AddCountryWorkdayDialog({
  visible,
  close,
  content,
  setContent
}: AddCountryWorkdayDialogProps) {
  return (
    <BasicDialog
      open={visible}
      onClose={close}
      title="Add a country"
    >
      <BaseForm
        initialValues={{ country: '' }}
        submitBtnLabel="Add"
        inputs={[
          { name: 'country', label: 'Country name' },
        ]}
        onSubmit={(values) => {
          setContent([...content, {
            country: values.country,
            january: 0,
            february: 0,
            march: 0,
            april: 0,
            may: 0,
            june: 0,
            july: 0,
            august: 0,
            september: 0,
            october: 0,
            november: 0,
            december: 0
          }]);
          close();
          return Promise.resolve();
        }}
      />
    </BasicDialog>
  );
}