import React from 'react';
import { ModelDto } from 'types';
import { useAdminFormContext } from 'hooks/AdminForm/AdminFormContext';
import AsyncDialog from 'components/Dialog/AsyncDialog';
import { useUpdateFormItem } from 'hooks/useUpdateFormItem';
import BaseForm from 'components/Form/BaseForm/BaseForm';
import { BaseInputProps } from 'components/Form/BaseForm/types';

interface UpdateFormItemDialogProps<DtoType extends ModelDto, FormValues extends object> {
  visible: boolean;
  close: () => void;
  urlUpdate: string,
  urlValidation?: string,
  title: string,
  hookFetchDto: (id: string) => [
      DtoType | undefined,
    boolean,
    boolean,
    () => void,
    (dto: DtoType) => void,
      number | undefined,
      string | undefined,
  ],
  itemId: string,
  initials: (item: DtoType) => FormValues,
  inputs: BaseInputProps<FormValues>[]
}

export default function UpdateFormItemDialog<DtoType extends ModelDto, FormValues extends object>({
  visible,
  close,
  urlUpdate,
  urlValidation,
  initials,
  title,
  hookFetchDto,
  itemId,
  inputs
}: UpdateFormItemDialogProps<DtoType, FormValues>) {

  const { setForm } = useAdminFormContext();
  const [item, loading, errorOccured, fetchItem] = hookFetchDto(itemId);
  const { submit } = useUpdateFormItem(urlUpdate, {
    onSuccess: response => {
      setForm(response);
      close();
    }
  });

  return (
    <AsyncDialog
      open={visible}
      onClose={close}
      title={title}
      loading={loading}
      errorOccured={errorOccured}
      reload={fetchItem}
      result={item}
    >
      {(loadedItem) => {
        return <BaseForm
          urlValidation={urlValidation}
          initialValues={initials(loadedItem)}
          inputs={inputs}
          onSubmit={submit}
        />;
      }}
    </AsyncDialog>
  );
}