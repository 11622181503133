import React from 'react';
import LoginPage from 'views/login';
import ForbiddenPage from 'views/errors/ForbiddenPage';
import { Outlet } from 'react-router-dom';
import { useAuthenticationContext } from '../../context/AuthenticationContext';


export default function EmployeePage(): React.ReactElement {
  const { role, accessToken } = useAuthenticationContext();

  if (!accessToken) {
    return <LoginPage/>;
  }

  if (role !== 'EMPLOYEE') {
    return <ForbiddenPage/>;
  }


  return <Outlet />;
}
