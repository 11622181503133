import React, { createContext, useCallback, useContext, useState } from 'react';
import { Snackbar, Alert, AlertColor, SnackbarOrigin } from '@mui/material';
import { AxiosError } from 'axios';
import { useNullableAuthenticationContext } from './AuthenticationContext';

interface PopMessageContextContent {
  popError: (error: AxiosError, message?: string, origin?: SnackbarOrigin) => void;
  popSuccess: (message: string) => void;
}

const PopMessageContext = createContext<PopMessageContextContent | null>(
  null
);

export const usePopMessage = (): PopMessageContextContent => {
  const context = useContext(PopMessageContext);
  if (context === null) {
    throw new Error('usePopMessage called outside Context.');
  }

  return context;
};

interface PopMessageProviderProps {
  children: React.ReactElement;
}

export const PopMessageProvider = ({ children }: PopMessageProviderProps) => {
  const [notifErrorOpened, setNotifErrorOpened] = useState<boolean>(false);
  const [origin, setOrigin] = useState<SnackbarOrigin>({
    vertical: 'bottom',
    horizontal: 'left'
  });
  const [severity, setSeverity] = useState<AlertColor>('success');
  const [message, setMessage] = useState<string>('');
  const context = useNullableAuthenticationContext();
  const popError = useCallback((err: AxiosError, customMessage?: string, origin?: SnackbarOrigin) => {
    setNotifErrorOpened(true);
    setSeverity('error');
    setOrigin(origin ?? {
      vertical: 'bottom',
      horizontal: 'left'
    });
    if (customMessage) {
      setMessage(`${customMessage}`);
    } else {
      setMessage(`Une erreur ${err.response?.status} est survenue.`);
    }

    if(err.response?.status === 401 && context){
      context.logout();
    }
  }, [context]);
  const popSuccess = useCallback((message: string) => {
    setNotifErrorOpened(true);
    setSeverity('success');
    setMessage(message);
    setOrigin({ vertical: 'top', horizontal: 'center' });
  }, []);
  const handleClose = () => {
    setNotifErrorOpened(false);
  };
  return (
    <>
      <Snackbar
        anchorOrigin={origin}
        open={notifErrorOpened}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <PopMessageContext.Provider value={{ popError, popSuccess }}>
        {children}
      </PopMessageContext.Provider>
    </>
  );
};