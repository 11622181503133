import React from 'react';
import { Box as MuiBox } from '@mui/material';

export interface DataViewerProps {
  label: string;
  value?: string;
}

export default function DataViewer({ label, value }: DataViewerProps): React.ReactElement {
  return (
    <MuiBox sx={{ display: 'flex', alignItems: 'center' }}>
      <MuiBox sx={{ pr: 1 }}>{label}</MuiBox>
      {value
        ? (
          <MuiBox sx={{ fontWeight: 'bold', pl: 1 }}>
            {value}
          </MuiBox>
        )
        : (
          <MuiBox sx={{ color: '#aaa', pl: 1 }}>
            No content
          </MuiBox>
        )}
    </MuiBox>
  );
}
