import React, { useState } from 'react';
import { FormFieldProps } from './utils';
import FormFieldContainer from './FormFieldContainer';
import AddInputGroupValueDialog from 'components/AddInputGroupValueDialog/AddInputGroupValueDialog';
import { useBoolean } from 'hooks/useBoolean';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { InputGroupDto, InputGroupItemDto, InputGroupItemValueDto } from 'types';
import { useTaxReturnRequestContext } from 'hooks/TaxReturn/TaxReturnContext';
import RemoveInputGroupValue from 'components/TaxReturn/Fields/RemoveInputGroupValue';

function buildSummary(fields: InputGroupItemDto[], values: InputGroupItemValueDto[]): string {
  let result = '';
  fields.forEach(field => {
    if (field.inSummary === '1') {
      result += extractFieldValue(field, values) + ' ';
    }
  });
  return result;
}

function extractFieldValue(field: InputGroupItemDto, values: InputGroupItemValueDto[] | null): string {
  return values?.filter(value => value.input_group_field_id === field.id)[0]?.value ?? 'No data';
}

export default function FormFieldInputGroup<FormValues>({ formField, editable }: FormFieldProps<FormValues>) {
  const { request: { id: requestId } } = useTaxReturnRequestContext();
  const [inputGroup, setInputGroup] = useState<InputGroupDto>(formField.data as InputGroupDto);
  const {
    value: visible,
    setTrue: open,
    setFalse: close,
  } = useBoolean();

  return (
    <FormFieldContainer
      formField={inputGroup}
      formFieldId={formField.id}
      skeletonProps={{ variant: 'circular', height: 40, width: 40 }}
      render={field => {
        return (
          <>
            <Typography>{field.title}</Typography>
            {editable && <Button onClick={open} variant="contained" sx={{ mb: 2 }}>Add</Button>}
            <AddInputGroupValueDialog
              visible={visible}
              close={close}
              inputGroup={field}
              setInputGroup={setInputGroup}
            />
            {
              field.values?.length === 0 &&
              <Alert severity="warning">
                No item provided
              </Alert>
            }
            {
              field?.values?.map(value =>
                <Accordion key={value.id}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id={value.id}
                  >
                    <Typography>{buildSummary(field.fields, value.fields)}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List>
                      {
                        field.fields.map((item, index) => {
                          return <ListItem key={index}>
                            <Typography sx={{ fontWeight: 'bold', mr: 1 }}>
                              {item.label}
                            </Typography>
                            <ListItemText
                              primary={extractFieldValue(item, value.fields)}
                            />
                          </ListItem>;
                        })
                      }
                    </List>
                    <RemoveInputGroupValue
                      id={formField.id}
                      editable={editable}
                      requestId={requestId}
                      value={value}
                      setInputGroup={setInputGroup}
                    />
                  </AccordionDetails>
                </Accordion>
              )
            }
          </>
        );
      }}
    />
  );
}
