import React, { useState } from 'react';
import { Box, Checkbox, Typography } from '@mui/material';

import { AdminSheetDto } from 'types';
import { useUpdateFormSheetClonable } from 'hooks/FormSheet/useUpdateFormSheetClonable';

interface SheetTabProps {
  sheet: AdminSheetDto;
}

export default function SheetTab({ sheet }: SheetTabProps) {
  const [checked, setChecked] = useState<boolean>(sheet.clonable);
  const { submit: updateClonable } = useUpdateFormSheetClonable(sheet.id);

  return <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
    <Checkbox
      sx={{ p: 0, pr: 1 }}
      onChange={async ({ target }) => {
        setChecked(target.checked);
        await updateClonable(target.checked);
      }}
      checked={checked}
    />
    <Typography sx={{
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}
    >
      {sheet.name}
    </Typography>
  </Box>;
}
