import React, { useState } from 'react';
import { Box, Button, IconButton } from '@mui/material';

import BasicDialog from 'components/Dialog/BasicDialog';
import { AdminSheetDto, FieldType } from 'types';
import { ArrowBack } from '@mui/icons-material';
import CreateFormItemForm from 'components/Dialog/CreateFormItemDialog/CreateFormItemForm';
import BaseAsyncSelect from 'components/Form/BaseForm/Fields/BaseAsyncSelect';
import { inputTypes, titleLevels } from 'referentiels';
import BaseInputNumber from 'components/Form/BaseForm/Fields/BaseInputNumber';

interface CreateFormItemDialogProps {
  visible: boolean;
  close: () => void;
  sheet: AdminSheetDto;
}

const CreateFormItemDialog = ({
  visible,
  close,
  sheet
}: CreateFormItemDialogProps) => {
  const [fieldType, setFieldType] = useState<FieldType | null>(null);
  const resetFIeldType = () => {
    setFieldType(null);
  };
  const closeModal = () => {
    close();
    resetFIeldType();
  };

  return (
    <BasicDialog
      open={visible}
      onClose={closeModal}
      title={<>
        {
          fieldType && <IconButton
            color="primary"
            onClick={resetFIeldType}>
            <ArrowBack/>
          </IconButton>
        }
        New {fieldType ?? 'form field'}
      </>}
    >
      {fieldType === null
        ? <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Button variant="outlined" sx={{ mb: 1 }} onClick={() => {
            setFieldType(FieldType.TITLE);
          }}>Title</Button>
          <Button variant="outlined" sx={{ mb: 1 }} onClick={() => {
            setFieldType(FieldType.INPUT_TEXT);
          }}>Input text</Button>
          <Button variant="outlined" sx={{ mb: 1 }} onClick={() => {
            setFieldType(FieldType.INPUT_FILE);
          }}>Input file</Button>
          <Button variant="outlined" sx={{ mb: 1 }} onClick={() => {
            setFieldType(FieldType.RADIO_BOX);
          }}>Radio box</Button>
          <Button variant="outlined" sx={{ mb: 1 }} onClick={() => {
            setFieldType(FieldType.DATE_PICKER);
          }}>Date picker</Button>
          <Button variant="outlined" sx={{ mb: 1 }} onClick={() => {
            setFieldType(FieldType.WORKDAY);
          }}>Workday</Button>
          <Button variant="outlined" sx={{ mb: 1 }} onClick={() => {
            setFieldType(FieldType.CHECK_BOX);
          }}>Check Box</Button>
          <Button variant="outlined" sx={{ mb: 1 }} onClick={() => {
            setFieldType(FieldType.INPUT_GROUP);
          }}>Input group</Button>
        </Box>
        : <>
          {fieldType === FieldType.TITLE &&
            <CreateFormItemForm
              sheetId={sheet.id}
              closeModal={closeModal}
              urlSubmit="titles/create"
              urlValidation={`api/admin/sheets/${sheet.id}/titles/create/validate`}
              inputs={[
                { name: 'content', label: 'Content' },
                {
                  name: 'level',
                  render: () => {
                    return <BaseAsyncSelect
                      options={titleLevels}
                      loading={false}
                      getOptionValue={option => {
                        return option.level;
                      }}
                      getOptionLabel={option => {
                        return option.label;
                      }}
                    />;
                  },
                  label: 'Level'
                }
              ]}
              initials={{ content: '', level: 1 }}
            />
          }
          {fieldType === FieldType.INPUT_TEXT &&
            <CreateFormItemForm
              sheetId={sheet.id}
              closeModal={closeModal}
              urlSubmit="input-texts/create"
              urlValidation={`api/admin/sheets/${sheet.id}/input-texts/create/validate`}
              inputs={[
                { name: 'label', label: 'Label' },
                { name: 'description', label: 'Description' },
                {
                  name: 'type',
                  render: () => {
                    return <BaseAsyncSelect
                      options={inputTypes}
                      loading={false}
                      getOptionValue={option => {
                        return option.value;
                      }}
                      getOptionLabel={option => {
                        return option.label;
                      }}
                    />;
                  },
                  label: 'Type'
                }
              ]}
              initials={{ type: 'text', description: '', label: '', required: false }}
            />
          }
          {fieldType === FieldType.INPUT_FILE &&
            <CreateFormItemForm
              sheetId={sheet.id}
              closeModal={closeModal}
              urlSubmit="input-files/create"
              urlValidation={`api/admin/sheets/${sheet.id}/input-files/create/validate`}
              inputs={[
                { name: 'label', label: 'Label' },
                { name: 'description', label: 'Description' }
              ]}
              initials={{ label: '', description: '' }}
            />
          }
          {fieldType === FieldType.RADIO_BOX &&
            <CreateFormItemForm
              sheetId={sheet.id}
              closeModal={closeModal}
              urlSubmit="radio-boxes/create"
              urlValidation={`api/admin/sheets/${sheet.id}/radio-boxes/create/validate`}
              inputs={[
                { name: 'label', label: 'Label' },
                { name: 'description', label: 'Description' }
              ]}
              initials={{ description: '', label: '' }}
            />
          }
          {fieldType === FieldType.DATE_PICKER &&
            <CreateFormItemForm
              sheetId={sheet.id}
              closeModal={closeModal}
              urlSubmit="date-pickers/create"
              urlValidation={`api/admin/sheets/${sheet.id}/date-pickers/create/validate`}
              inputs={[
                { name: 'label', label: 'Label' },
                { name: 'description', label: 'Description' },
              ]}
              initials={{ description: '', label: '', required: false }}
            />
          }
          {fieldType === FieldType.WORKDAY &&
            <CreateFormItemForm
              sheetId={sheet.id}
              closeModal={closeModal}
              urlSubmit="workdays/create"
              urlValidation={`api/admin/sheets/${sheet.id}/workdays/create/validate`}
              inputs={[
                { name: 'label', label: 'Label' },
                {
                  name: 'year',
                  render: () => {
                    return <BaseInputNumber/>;
                  },
                  label: 'Year'
                }
              ]}
              initials={{ label: '', year: 2022 }}
            />
          }
          {fieldType === FieldType.CHECK_BOX &&
            <CreateFormItemForm
              sheetId={sheet.id}
              closeModal={closeModal}
              urlSubmit="check-boxes/create"
              urlValidation={`api/admin/sheets/${sheet.id}/check-boxes/create/validate`}
              inputs={[
                { name: 'label', label: 'Label' },
                { name: 'description', label: 'Description' },
              ]}
              initials={{ description: '', label: '' }}
            />
          }
          {fieldType === FieldType.INPUT_GROUP &&
            <CreateFormItemForm
              sheetId={sheet.id}
              closeModal={closeModal}
              urlSubmit="input-groups/create"
              urlValidation={`api/admin/sheets/${sheet.id}/input-groups/create/validate`}
              inputs={[
                { name: 'title', label: 'Title' },
                { name: 'description', label: 'Description' },
              ]}
              initials={{ description: '', title: '' }}
            />
          }
        </>
      }
    </BasicDialog>
  );
};

export default CreateFormItemDialog;
