import React from 'react';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import TaxReturnPage from 'views/admin/taxreturns';
import AdminTaxreturnPage from 'views/admin/adminTaxreturnPage';
import AdminCompaniesPage from 'views/admin/companies';
import AdminTaxReturnRequestPage from 'views/admin/taxReturnRequest';
import TaxReturnRequestPage from 'views/taxReturnRequestPage';
import AdminCompanyPage from 'views/admin/company';
import { Box as MuiBox } from '@mui/material';
import HomeHeader from 'components/Header/HomeHeader';
import AdminPage from 'views/admin/adminPage';
import LoggedOutPage from 'views/loggedOutPage';
import ActivateAccountPage from 'views/activateAccount';
import HomePage from 'views/home';
import AdminCreateEmployeeRequestsPage from 'views/admin/adminCreateEmployeeRequestsPage';
import EmployeePage from 'views/employee/employeePage';
import { useAuthenticationContext } from './context/AuthenticationContext';
import LoginPage from './views/login';
import NotFoundPage from './views/errors/NotFoundPage';

import './app.css';

const Router = () => {

  const router = createBrowserRouter([
    {
      path: '/',
      element: <RootComponent/>,
      children: [
        { index: true, element: <HomePage /> },
        {
          path: '/admin',
          element: <AdminPage />,
          children: [
            {
              path: 'tax-returns/:id',
              element: <AdminTaxreturnPage />
            },
            {
              path: 'tax-returns',
              element: <TaxReturnPage />
            },
            {
              path: 'requests/:id',
              element: <AdminTaxReturnRequestPage />
            },
            {
              path: 'companies',
              element: <AdminCompaniesPage />
            },
            {
              path: 'companies/:id',
              element: <AdminCompanyPage />
            },
            {
              path: 'create-employee-requests',
              element: <AdminCreateEmployeeRequestsPage />
            },
          ],
        },
        {
          path: '/employee',
          element: <EmployeePage/>,
          children: [
            {
              path: 'tax-return-requests/:id',
              element: <TaxReturnRequestPage />
            },
          ],
        },
      ],
      errorElement: <NotFoundPage />,
    },
    {
      path: 'public',
      element: <LoggedOutPage/>,
      children: [
        {
          path: 'activate/:token',
          element: <ActivateAccountPage />
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};

function RootComponent(){
  const { accessToken } = useAuthenticationContext();

  return accessToken
    ? <>
      <MuiBox component="header">
        <HomeHeader/>
      </MuiBox>
      <MuiBox component="main" sx={{ flex: 1, display: 'flex' }}>
        <Outlet />
      </MuiBox>
    </>
    : <LoginPage />;
}

export default Router;
