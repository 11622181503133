import React from 'react';

import BasicDialog from 'components/Dialog/BasicDialog';
import BaseForm from '../../Form/BaseForm/BaseForm';
import { CreateEmployerFormValues, useCreateEmployer } from 'hooks/Employer/useCreateEmployer';

interface CreateEmployerDialogProps {
  companyId: string;
  visible: boolean;
  close: () => void;
  fetchCompany: () => void;
}

export default function CreateEmployerDialog({
  companyId,
  visible,
  close,
  fetchCompany
}: CreateEmployerDialogProps) {

  const [submit] = useCreateEmployer(companyId, {
    onSuccess: () => {
      fetchCompany();
      close();
    }
  });

  return (
    <BasicDialog
      open={visible}
      onClose={close}
      title="Create new employer"
    >
      <BaseForm
        urlValidation={`api/admin/companies/${companyId}/employers/create/validate`}
        initialValues={{ firstName: '', lastName: '', email: '' }}
        inputs={[
          { name: 'firstName', label: 'First name' },
          { name: 'lastName', label: 'Last name' },
          { name: 'email', label: 'Email' },
        ]}
        onSubmit={(values: CreateEmployerFormValues) => {
          return submit(values);
        }}
      />
    </BasicDialog>
  );
}