import React, { useEffect, useState } from 'react';
import { TableCell, TextField, Typography } from '@mui/material';
import { WorkdayItemDto } from 'types';

interface WorkdayCellProps {
  content: Array<WorkdayItemDto>;
  setContent: (value: Array<WorkdayItemDto>) => void;
  index: number;
  editable: boolean;
  field: keyof WorkdayItemDto;
}

export default function WorkdayCell<FormValues extends object>({
  content,
  setContent,
  editable,
  index,
  field
}: WorkdayCellProps) {

  const [value, setValue] = useState<number>(Number(content[index][field]));

  useEffect(() => {
    if (content[index][field] !== value) {
      setValue(Number(content[index][field]));
    }
  }, [content]);
  useEffect(() => {
    setContent([...content.map((item, idx) => index !== idx ? item : { ...item, [field]: value })]);
  }, [value]);

  return <TableCell align="center" sx={{ p: 0 }}>
    {
      editable ? <TextField
        fullWidth
        value={value}
        onChange={event => {
          setValue(Number(event.target.value));
        }}
        size="small"
        inputProps={{ style: { textAlign: 'center', borderRadius: 0 } }}
        sx={{
          '& fieldset': {
            borderRadius: '0px',
          }
        }}
      /> : <Typography>{value}</Typography>
    }

  </TableCell>;
}
