import React from 'react';
import NotFoundPage from 'views/errors/NotFoundPage';
import { Outlet } from 'react-router-dom';
import { useAuthenticationContext } from '../context/AuthenticationContext';

export default function LoggedOutPage(): React.ReactElement {
  const { accessToken } = useAuthenticationContext();

  if (accessToken) {
    return <NotFoundPage/>;
  }

  return <Outlet />;
}
