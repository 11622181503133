import { AdminInputGroupDto, InputGroupItemFormValues, RadioBoxItemFormValues } from 'types';
import useApi from '../../api';

interface CreateInputGroupItemProps {
  onSuccess?: (response: AdminInputGroupDto) => void;
  willCallBackend?: () => void;
}

interface CreateRadioBoxResult {
  submit: (values: RadioBoxItemFormValues) => Promise<AdminInputGroupDto>;
  loading: boolean;
  errorOccured: boolean;
}

export function useCreateInputGroupItem(inputGroupId: string, {
  onSuccess,
}: CreateInputGroupItemProps): CreateRadioBoxResult {

  const { post, postLoading,postError } = useApi<InputGroupItemFormValues, AdminInputGroupDto>();
  const submitFunction = async (
    values: InputGroupItemFormValues
  ) => {
    return post(`api/admin/input-groups/${inputGroupId}/input-group-fields/create`, values)
      .then(response => {
        onSuccess && onSuccess(response.data);
        return Promise.resolve(response.data);
      });
  };

  return {
    loading: postLoading,
    errorOccured: postError,
    submit: submitFunction
  };
}
