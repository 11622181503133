import React from 'react';
import Page from 'components/Page/Page';
import { Alert, Box, Button, Grid, List, ListItem, ListItemText, Paper, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useFetchCompany } from 'hooks/useFetchDto';
import AsyncComponent from 'components/AsyncComponent/AsyncComponent';
import { useBoolean } from 'hooks/useBoolean';
import CreateEmployerDialog from 'components/Dialog/CreateEmployerDialog/CreateEmployerDialog';
import CreateEmployeeDialog from 'components/Dialog/CreateEmployeeDialog/CreateEmployeeDialog';
import EmployeeItem from 'components/Company/EmployeeItem';

export default function AdminCompanyPage(): React.ReactElement {

  const { id } = useParams<'id'>();
  const [company, loading, eroorOccured, fetch] = useFetchCompany(id);
  const {
    value: visibleCreateEmployer,
    setTrue: openCreateEmployer,
    setFalse: closeCreateEmployer,
  } = useBoolean();
  const {
    value: visibleCreateEmployee,
    setTrue: openCreateEmployee,
    setFalse: closeCreateEmployee,
  } = useBoolean();

  return <>
    <AsyncComponent
      result={company}
      loading={loading}
      errorOccured={eroorOccured}
      reload={fetch}
    >
      {
        loadedCompany =>
          <Page title={loadedCompany.name}><Grid container spacing={2} sx={{ p: 2 }}>
            <Grid spacing={2} justifyContent="center" container>
              <Grid xs={8} item>
                <Paper sx={{ p: 2, width: '100%' }}>
                  <Typography variant="h6">Employees</Typography>
                  {
                    loadedCompany.employees.length === 0 && <Alert color="warning" sx={{ alignItems: 'center' }}>
                      <Typography>
                        No employee saved. {
                          company?.employers.length === 0
                            ? ' Create an employer before create employee'
                            : <Button variant="text" color="warning" onClick={openCreateEmployee}>
                            Create one
                            </Button>
                        }
                      </Typography>
                    </Alert>
                  }
                  {
                    loadedCompany.employees.length > 0 && <>
                      <Box sx={{ textAlign: 'right' }}>
                        <Button onClick={openCreateEmployee}>New employee</Button>
                      </Box>
                      <List>
                        {
                          loadedCompany.employees.map((employee, index) => <EmployeeItem
                            key={index}
                            employee={employee}
                            refreshCompany={fetch}/>
                          )
                        }
                      </List>
                    </>
                  }
                </Paper>
              </Grid>
              <Grid xs={3} item sx={{ justifyContent: 'center', display: 'flex' }}>
                <Paper sx={{ p: 2, width: '100%' }}>
                  <Typography variant="h6">Employers</Typography>
                  {
                    loadedCompany.employers.length === 0 && <Alert color="warning" sx={{ alignItems: 'center' }}>
                      <Typography>No employer saved<Button variant="text" color="warning" onClick={openCreateEmployer}>Create
                        one</Button></Typography>
                    </Alert>
                  }
                  {
                    loadedCompany.employers.length > 0 && <>
                      <Box sx={{ textAlign: 'right' }}>
                        <Button onClick={openCreateEmployer}>New employer</Button>
                      </Box>
                      <List>
                        {
                          loadedCompany.employers.map((employer, index) => <ListItem key={index}>
                            <ListItemText
                              primary={`${employer.firstName} ${employer.lastName}`}
                              secondary={employer.email}
                            />
                          </ListItem>
                          )
                        }
                      </List>
                    </>
                  }
                </Paper>
              </Grid>
            </Grid>
          </Grid>

          </Page>
      }
    </AsyncComponent>
    {
      id && <>
        <CreateEmployerDialog
          companyId={id}
          visible={visibleCreateEmployer}
          close={closeCreateEmployer}
          fetchCompany={fetch}
        />
        <CreateEmployeeDialog
          companyId={id}
          employers={company?.employers ?? []}
          visible={visibleCreateEmployee}
          close={closeCreateEmployee}
          fetchCompany={fetch}/>
      </>
    }
  </>;
}
