import { UserDto } from 'types';
import { useSubmitForm, UseSubmitFormOptions } from 'hooks/useSubmitForm';

export function useAcceptCreateEmployeeRequest(requestId: string, props: UseSubmitFormOptions<UserDto>): [
  submit: (values: void) => Promise<UserDto>,
  loading: boolean,
  errorOccured: boolean,
] {
  return useSubmitForm<void, UserDto>({
    ...props,
    urlPath: `api/admin/request-employees/${requestId}/accept`,
  });
}