import React from 'react';
import { Paper as MuiPaper, PaperProps as MuiPaperProps } from '@mui/material';

export default function Paper ({ children, sx, ...props }: MuiPaperProps) {
  return (
    <MuiPaper
      {...props}
      sx={{ ...sx, minWidth: 936, overflow: 'hidden', m: 3 }}
    >
      {children}
    </MuiPaper>
  );
}
