import { useSubmitForm, UseSubmitFormOptions } from 'hooks/useSubmitForm';
import { TwoFactorResult } from '../../components/LoginForm/TwoFactorForm';

export interface ActivateAccountFormValues {
  email?: string;
  password?: string;
  password_confirmation?: string;
  token: string;
  timezoneOffset?: number;
}

export function useActivateAccount(props: UseSubmitFormOptions<TwoFactorResult>): [
  submit: (values: ActivateAccountFormValues) => Promise<TwoFactorResult>,
  loading: boolean,
  errorOccured: boolean,
] {
  return useSubmitForm({
    ...props,
    urlPath: 'api/account/activate',
  });
}
