import { useState } from 'react';
import { AxiosError } from 'axios';
import { Resolver as RhfResolver } from 'react-hook-form';

import { BaseValidator, RhfResolverErrorType, ViolationDto } from './types';
import { useBoolean } from 'hooks/useBoolean';
import { useHandleBackendContext } from 'hooks/HandleBackendContext';

export function useBaseFormResolver<FormValues extends object>(validator?: BaseValidator<FormValues>):
  [RhfResolver<FormValues>, ViolationDto[], boolean] {
  const [violations, setViolations] = useState<ViolationDto[]>([]);
  const {
    value: inProgress,
    setTrue: start,
    setFalse: stop,
  } = useBoolean();
  const { handleBackendError } = useHandleBackendContext();

  const resolver = async (values: FormValues) => {
    if (validator != null) {
      setViolations([]);
      start();
      try {
        const fetchedViolations = (await validator(values));
        setViolations(fetchedViolations);
        stop();
        if (fetchedViolations.length > 0) {
          let errors: RhfResolverErrorType<FormValues> = {} as RhfResolverErrorType<FormValues>;
          fetchedViolations.forEach(violation => {
            errors = {
              ...errors,
              [violation.field]: {
                type: 'validation',
                message: violation.message
              }
            };
          });

          return {
            values: {},
            errors
          };
        }
      } catch (err) {
        handleBackendError(err as AxiosError);
        stop();
      }
    }

    return {
      values,
      errors: {}
    };
  };

  return [resolver, violations, inProgress];
}
