import React from 'react';
import {
  Checkbox as MuiCheckbox,
  FormControl as MuiFormControl,
  FormControlLabel as MuiFormControlLabel,
  FormHelperText as MuiFormHelperText
} from '@mui/material';

import { useBaseFormContext } from '../BaseFormContext';
import { useBaseFormItemContext } from '../BaseFormItemContext';

export default function BaseCheckBox<FormValues extends object> () {
  const { formState: { isValidating } } = useBaseFormContext<FormValues>();
  const { violations, field, label } = useBaseFormItemContext<FormValues>();
  const displayError = violations.length > 0 && !isValidating;
  return (
    <MuiFormControl
      error={displayError}
      margin='normal'
      variant='outlined'
      sx={{ my: 1 }}
      fullWidth
    >
      <MuiFormControlLabel
        control={<MuiCheckbox checked={field.value as boolean} {...field} />}
        label={label}
        name={field.name}
      />
      {violations.length > 0 && !isValidating && (
        <MuiFormHelperText>{violations[0].message}</MuiFormHelperText>
      )}
    </MuiFormControl>
  );
}
