import React from 'react';
import { Box, Typography as MuiTypography } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface PageProps {
  title: React.ReactNode;
  children: React.ReactElement;
  sx?: SxProps<Theme>;
}

export default function Page({ children, title, sx }: PageProps) {
  return (
    <Box id="page-content" sx={{ ...sx, flex: 1 }}>
      <MuiTypography variant="h3" sx={{ m: 3 }}>
        {title}
      </MuiTypography>
      {children}
    </Box>
  );
}
