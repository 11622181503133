import { InputGroupDto } from 'types';
import useApi from '../api';

interface UseSaveInputGroupValueOption {
  inputGroupId: string;
  requestId: string,
  onSuccess: (response: InputGroupDto) => void;
}

export default function useSaveInputGroupValue({ requestId, inputGroupId, onSuccess }: UseSaveInputGroupValueOption) {
  const { post, postLoading,postError } = useApi<unknown, InputGroupDto>();
  const submitFunction = async (
    values: unknown
  ) => {
    return post(`api/requests/${requestId}/input-groups/${inputGroupId}/save-value`, values)
      .then(response => {
        onSuccess && onSuccess(response.data);
        return Promise.resolve(response.data);
      });
  };

  return {
    loading: postLoading,
    errorOccured: postError,
    submit: submitFunction
  };
}