import React, { useEffect } from 'react';
import { CircularProgress as MuiCircularProgress } from '@mui/material';

import EmptyOnError from '../EmptyOnError/EmptyOnError';

interface AsyncComponentProps<LoadedElement> {
  children: (result: LoadedElement) => React.ReactElement;
  loading: boolean;
  errorOccured: boolean;
  reload: () => void;
  result?: LoadedElement;
  renderLoader?: (defaultElement: React.ReactElement) => React.ReactElement;
  renderOnError?: (defaultElement: React.ReactElement) => React.ReactElement;
}

export default function AsyncComponent<LoadedElement>({
  children,
  reload,
  errorOccured,
  loading,
  result,
  renderLoader,
  renderOnError
}: AsyncComponentProps<LoadedElement>): React.ReactElement {

  useEffect(() => {
    reload();
  }, []);

  const defaultLoader = <MuiCircularProgress sx={{ margin: '1em' }}/>;
  const defaultOnError = (
    <EmptyOnError description="Unexpected error" reload={reload}/>
  );

  if (loading) {
    return (renderLoader != null) ? renderLoader(defaultLoader) : defaultLoader;
  }

  if (errorOccured) {
    return (renderOnError != null) ? renderOnError(defaultOnError) : defaultOnError;
  }

  if (result !== undefined) {
    return children(result);
  }
  return <></>;
}
