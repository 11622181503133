import React from 'react';
import {
  FormControlLabel as MuiFormControlLabel,
  Radio as MuiRadio,
  RadioGroup as MuiRadioGroup,
  RadioGroupProps as MuiRadioGroupProps
} from '@mui/material';
import { Control, Controller, Path, PathValue, } from 'react-hook-form';

interface RadioBoxItem {
  value: string;
  label: string;
}

interface ControlledRadioBoxProps<FormValues extends object> extends MuiRadioGroupProps {
  items: RadioBoxItem[];
  name: string & Path<FormValues>;
  control: Control<FormValues, object>;
}

export default function ControlledRadioBox<FormValues extends object>({
  items,
  name,
  control,
  defaultValue,
  ...props
}: ControlledRadioBoxProps<FormValues>) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={
        defaultValue as
          | PathValue<FormValues, string & Path<FormValues>>
          | undefined
      }
      render={({ field }) => {
        const finalProps = {
          ...props,
          ...field
        };
        return (
          <MuiRadioGroup
            {...finalProps}
            value={finalProps.value ?? defaultValue ?? ''}
          >
            {items.map((item, index) => {
              return (
                <MuiFormControlLabel
                  key={index}
                  value={item.value}
                  control={<MuiRadio/>}
                  label={item.label}
                />
              );
            })}
          </MuiRadioGroup>
        );
      }}
    />
  );
}
