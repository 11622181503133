import React from 'react';
import { useFetchTaxReturnRequest } from 'hooks/useFetchDto';
import AsyncComponent from 'components/AsyncComponent/AsyncComponent';
import Page from 'components/Page/Page';
import TaxReturnViewer from 'components/TaxReturn/TaxReturnViewer';
import { TaxReturnRequestProvider } from 'hooks/TaxReturn/TaxReturnContext';
import { useParams } from 'react-router-dom';
import ChipTaxReturnRequest from 'components/Chip/ChipTaxReturnRequest';
import ReviewRequest from 'components/ReviewRequest/ReviewRequest';
import { TaxReturnRequestStatut } from 'types';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import UploadRequestFile from 'components/UploadRequestFile/UploadRequestFile';
import HttpErrorPage from 'views/errors/HttpErrorPage';

export default function AdminTaxReturnRequestPage(): React.ReactElement {
  const { id } = useParams<'id'>();
  const [taxReturn, isLoading, errorOccured, fetch, setTaxReturnRequest, httpStatus, errorMessage] = useFetchTaxReturnRequest(id);

  return (
    <AsyncComponent
      loading={isLoading}
      errorOccured={errorOccured}
      reload={fetch}
      result={taxReturn}
      renderOnError={() => <HttpErrorPage code={httpStatus} message={errorMessage}/>}
    >
      {
        loadedRequest => <Page
          title={
            <>
              {`${loadedRequest.taxReturn.name} - ${loadedRequest.user.firstName} ${loadedRequest.user.lastName}`}
              <ChipTaxReturnRequest status={loadedRequest.status} sx={{ ml: 2 }}/>
            </>
          }
        >
          <TaxReturnRequestProvider request={loadedRequest} setRequest={setTaxReturnRequest}>
            <>
              <Box sx={{ px: 3 }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  Expiration date: {moment.utc(loadedRequest.expirationDate).local().format('D/MM/YYYY')}
                </Typography>
                {
                  loadedRequest.status === TaxReturnRequestStatut.WAITING_ADMIN_REVIEW && <ReviewRequest/>
                }
                {
                  (loadedRequest.status === TaxReturnRequestStatut.WAITING_FOR_RESULT_FILE
                    || loadedRequest.status === TaxReturnRequestStatut.COMPLETED)
                  && <UploadRequestFile/>
                }
              </Box>
              <TaxReturnViewer editable={false}/>
            </>
          </TaxReturnRequestProvider>
        </Page>
      }
    </AsyncComponent>
  );
}
