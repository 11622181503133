import React from 'react';
import ForbiddenPage from 'views/errors/ForbiddenPage';
import AdminHomePage from 'views/admin/adminHomePage';
import EmployerHomePage from 'views/employer/employerHomePage';
import EmployeeHomePage from 'views/employee/EmployeeHomePage';
import { useAuthenticationContext } from '../context/AuthenticationContext';

export default function HomePage(): React.ReactElement {
  const { role } = useAuthenticationContext();

  if (role==='ADMIN') {
    return <AdminHomePage/>;
  }

  if (role==='EMPLOYER') {
    return <EmployerHomePage/>;
  }

  if (role==='EMPLOYEE') {
    return <EmployeeHomePage/>;
  }


  return <ForbiddenPage/>;
}
