import { TaxReturnDto } from 'types';
import { useSubmitForm, UseSubmitFormOptions } from 'hooks/useSubmitForm';

export interface CloneTaxReturnFormValues {
  name?: string;
}

export function useCloneTaxReturn(taxReturnId: string, props: UseSubmitFormOptions<CloneTaxReturnFormValues>): [
  submit: (values: CloneTaxReturnFormValues) => Promise<TaxReturnDto>,
  loading: boolean,
  errorOccured: boolean,
] {
  return useSubmitForm<CloneTaxReturnFormValues, TaxReturnDto>({
    ...props,
    urlPath: `api/admin/tax-returns/${taxReturnId}/clone`,
  });
}
