import React from 'react';
import { DefaultValues, useForm } from 'react-hook-form';
import { Button as MuiButton, Grid as MuiGrid } from '@mui/material';

import { useBaseFormResolver } from './useBaseFormResolver';
import { BaseFormProvider } from './BaseFormContext';
import { BaseInputProps } from './types';
import BaseFormItem from './BaseFormItem';
import { useValidateForm } from 'hooks/Form/useValidateForm';

interface BaseFormProps<FormValues, ResponseType> {
  onSubmit: (data: FormValues) => Promise<ResponseType>;
  initialValues?: FormValues;
  inputs: Array<BaseInputProps<FormValues>>;
  urlValidation?: string;
  submitBtnLabel?: string;
  renderSubmit?: (isSubmitting: boolean, isValidating: boolean) => React.ReactElement;
}

export default function BaseForm<FormValues extends object, ResponseType>({
  initialValues,
  inputs,
  onSubmit,
  urlValidation,
  submitBtnLabel,
  renderSubmit
}: BaseFormProps<FormValues, ResponseType>) {
  const { validate } = useValidateForm(urlValidation);
  const [resolver, violations] = useBaseFormResolver<FormValues>(validate);
  const { handleSubmit, formState, control } = useForm<FormValues>({
    mode: 'onSubmit',
    resolver,
    defaultValues: (initialValues != null) ? initialValues as DefaultValues<FormValues> : undefined
  });

  const { isSubmitting, isValidating } = formState;

  return <BaseFormProvider
    formState={formState}
    violations={violations}
    control={control}
  >
    <form
      onSubmit={handleSubmit(async (values) => {
        return await onSubmit(values);
      })}
    >
      {inputs.map((input, index) => {
        return <BaseFormItem<FormValues>
          {...input}
          key={index}
          violations={violations.filter(violation => violation.field === input.name)}
        />;
      })}
      {
        (renderSubmit != null)
          ? renderSubmit(isSubmitting, isValidating)
          : <MuiGrid sx={{ pt: 1, textAlign: 'center' }}>
            <MuiButton
              disabled={isSubmitting || isValidating}
              type="submit"
              variant="contained"
              color="primary"
              size="large"
            >
              {isValidating
                ? 'Validating'
                : isSubmitting
                  ? 'Submitting'
                  : submitBtnLabel ?? 'Save'}
            </MuiButton>
          </MuiGrid>
      }
    </form>
  </BaseFormProvider>;
}
