import { TaxReturnRequestDto } from 'types';
import { useTaxReturnRequestContext } from 'hooks/TaxReturn/TaxReturnContext';
import { DeclineTaxReturnValues } from 'components/Dialog/DeclineTaxReturnRequest/DeclineTaxReturnRequestDialog';
import useApi from '../api';


export default function useDeclineTaxReturnRequest(onSuccess: (request: TaxReturnRequestDto) => void): [
  boolean,
  boolean,
  (values: DeclineTaxReturnValues) => Promise<TaxReturnRequestDto>,
] {
  const { request: { id: requestId }, setRequest } = useTaxReturnRequestContext();

  const { post, postLoading,postError } = useApi<DeclineTaxReturnValues, TaxReturnRequestDto>();
  const submitFunction = async (
    values: DeclineTaxReturnValues
  ) => {
    return post(`api/admin/requests/${requestId}/decline`, values)
      .then(response => {
        onSuccess && onSuccess(response.data);
        setRequest(response.data);
        return Promise.resolve(response.data);
      });
  };

  return [
    postLoading,
    postError,
    submitFunction
  ];
}