import React from 'react';

import BasicDialog from 'components/Dialog/BasicDialog';
import BaseForm from '../../Form/BaseForm/BaseForm';
import { AdminInputGroupDto } from 'types';
import { useCreateInputGroupItem } from 'hooks/InputGroup/InputGroupItem/useCreateInputGroup';

interface CreateInputGroupItemDialogProps {
  visible: boolean;
  close: () => void;
  inputGroupId: string;
  setInputGroup: (inputGroup: AdminInputGroupDto) => void;
}

export default function CreateInputGroupItemDialog({
  visible,
  close,
  inputGroupId,
  setInputGroup
}: CreateInputGroupItemDialogProps) {

  const { submit } = useCreateInputGroupItem(inputGroupId, {
    onSuccess: response => {
      setInputGroup(response);
      close();
    }
  });

  return (
    <BasicDialog
      open={visible}
      onClose={close}
      title="New input group item"
    >
      <BaseForm
        onSubmit={submit}
        urlValidation={`api/admin/input-groups/${inputGroupId}/input-group-fields/create/validate`}
        initialValues={{ label: '' }}
        inputs={[
          {
            name: 'label',
            label: 'Label'
          }
        ]}
      />
    </BasicDialog>
  );
}

