import React from 'react';

import BasicDialog from 'components/Dialog/BasicDialog';
import { AdminSheetDto, UpdateSheetFormValues } from 'types';
import { useUpdateFormSheet } from 'hooks/FormSheet/useUpdateFormSheet';
import { useAdminFormContext } from 'hooks/AdminForm/AdminFormContext';
import BaseForm from '../../Form/BaseForm/BaseForm';

interface EditTaxReturnDialogProps {
  visible: boolean;
  close: () => void;
  sheet: AdminSheetDto;
}

export default function EditSheetTitleDialog({
  visible,
  close,
  sheet
}: EditTaxReturnDialogProps) {

  const { setForm } = useAdminFormContext();
  const { submit } = useUpdateFormSheet(sheet.id, {
    onSuccess: response => {
      setForm(response);
      close();
    }
  });
  const initials: UpdateSheetFormValues = {
    name: sheet.name
  };

  return (
    <BasicDialog
      open={visible}
      onClose={close}
      title="Update sheet title"
    >
      <BaseForm
        urlValidation={`api/admin/sheets/${sheet.id}/update/validate`}
        initialValues={initials}
        inputs={[{ name: 'name', label: 'Name' }]}
        onSubmit={submit}
      />
    </BasicDialog>
  );
}