import React from 'react';
import { Box, Button } from '@mui/material';
import Form from '../Form/Form';
import { useAuthenticationContext } from '../../context/AuthenticationContext';
import { usePopMessage } from '../../context/PopMessageContext';
import useApi from '../../hooks/api';

interface TwoFactorFormProps{
  token: string;
  setToken: (token?: string) => void;
}

interface TwoFactorRequest{
  twoFactorToken: string;
  twoFactorDigits: string;
  timezoneOffset?: number;
}

export interface TwoFactorResult{
  jwt: string;
}

export default function TwoFactorForm({ token, setToken }: TwoFactorFormProps) {
  const { post } = useApi<TwoFactorRequest, TwoFactorResult>();
  const { setJwt } = useAuthenticationContext();
  const { popSuccess } = usePopMessage();


  return <Form
    onSubmit={(values: {twoFactorDigits: string}) => {
      return post('api/authentication/generate-token', {
        ...values,
        twoFactorToken: token,
        timezoneOffset:(new Date()).getTimezoneOffset(),
      });
    }}
    inputs={[
      {
        name: 'twoFactorDigits',
        label: '6 digits number received by email',
      },
    ]}
    onSuccess={(response) => {
      setJwt(response.data.jwt);
      popSuccess('You are logged in');
    }}
    submitLabel='Validate'
    renderSubmit={(isSubmitting: boolean) => {
      return <Box marginTop={2} display='flex' justifyContent='end' gap={1}>
        <Button
          type='button'
          size='large'
          onClick={() => setToken(undefined)}
        >
          Back to login form
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          disabled={isSubmitting}
        >
          {
            isSubmitting ? 'Loading' : 'Login'
          }
        </Button>
      </Box>;
    }}
  />;
}