import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { FormFieldWithChange } from './utils';
import FormFieldContainer from './FormFieldContainer';
import { WorkdayDto, WorkdayItemDto } from 'types';
import AddCountryWorkdayDialog from '../../Dialog/AddCountryWorkdayDialog/AddCountryWorkdayDialog';
import { useBoolean } from 'hooks/useBoolean';
import WorkdayCell from 'components/TaxReturn/Fields/WorkdayCell';
import { Path, PathValue } from 'react-hook-form';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

function getTotalOfYear(item: WorkdayItemDto) {
  return item.january + item.february + item.march
    + item.april + item.may + item.june
    + item.july + item.august + item.september
    + item.october + item.november + item.december;
}

function getTotalOfMonth(items: WorkdayItemDto[], month: keyof WorkdayItemDto) {
  let total = 0;
  items.forEach(item => {
    total += Number(item[month]);
  });
  return total;
}

function getTotal(items: WorkdayItemDto[]) {
  let total = 0;
  items.forEach(item => {
    total += getTotalOfYear(item);
  });
  return total;
}

export default function FormFieldWorkday<FormValues extends object>({
  id,
  formField,
  setValue,
  editable,
}: FormFieldWithChange<FormValues>) {
  const workday = formField.data as WorkdayDto;
  const {
    value: visible,
    setTrue: open,
    setFalse: close,
  } = useBoolean();
  const [content, setContent] = useState<Array<WorkdayItemDto>>((workday?.value.length === 0 || !workday?.value) ? [
    {
      country: 'France',
      january: 0,
      february: 0,
      march: 0,
      april: 0,
      may: 0,
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,
      november: 0,
      december: 0
    }
  ] : workday?.value);

  useEffect(() => {
    setValue(id as Path<FormValues>, content as PathValue<FormValues, Path<FormValues>>);
  }, [content]);

  useEffect(() => {
    if (workday && workday.value.length > 0) {
      setContent(workday.value);
    }
  }, [workday]);

  return (
    <FormFieldContainer
      formField={workday}
      formFieldId={id}
      skeletonProps={{ variant: 'text', height: 50 }}
      render={() => {
        return (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" sx={{ fontWeight: 'bold' }}></TableCell>
                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>JAN</TableCell>
                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>FEB</TableCell>
                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>MAR</TableCell>
                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>APR</TableCell>
                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>MAY</TableCell>
                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>JUN</TableCell>
                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>JUL</TableCell>
                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>AUG</TableCell>
                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>SEP</TableCell>
                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>OCT</TableCell>
                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>NOV</TableCell>
                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>DEC</TableCell>
                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>TOTAL</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    content.map((item, index) => {
                      return <TableRow key={`${index}-${item.country}`}>
                        <TableCell
                          align="center"
                          sx={editable ? {
                            fontWeight: 'bold',
                            py: 0,
                            display: 'flex',
                            alignItems: 'center'
                          } : { py: 0 }}
                        >
                          {(index > 0 && editable) && <IconButton
                            color="error"
                            onClick={() => {
                              setContent(content.filter((item, idx) => idx !== index));
                            }}>
                            <DeleteForeverIcon/>
                          </IconButton>}
                          <Typography sx={{ py: 1 }}>{item.country}</Typography>
                        </TableCell>
                        {Object
                          .keys(content[0])
                          .filter(key => key !== 'country' && key !== 'workday_id' && key !== 'request_id' && key !== 'updated_at' && key !== 'position')
                          .map((month) => {
                            return <WorkdayCell
                              editable={editable}
                              key={`${index}-${month}`}
                              index={index}
                              field={month as keyof WorkdayItemDto}
                              content={content}
                              setContent={setContent}
                            />;
                          })}
                        <TableCell align="center"
                          sx={{ fontWeight: 'bold', py: 0 }}
                        >
                          {getTotalOfYear(item)}
                        </TableCell>
                      </TableRow>;
                    })
                  }
                  <TableRow>
                    <TableCell align="center" sx={{ fontWeight: 'bold', py: 1 }}>TOTAL</TableCell>
                    <TableCell align="center"
                      sx={{ fontWeight: 'bold', py: 1 }}>{getTotalOfMonth(content, 'january')}</TableCell>
                    <TableCell align="center"
                      sx={{ fontWeight: 'bold', py: 1 }}>{getTotalOfMonth(content, 'february')}</TableCell>
                    <TableCell align="center"
                      sx={{ fontWeight: 'bold', py: 1 }}>{getTotalOfMonth(content, 'march')}</TableCell>
                    <TableCell align="center"
                      sx={{ fontWeight: 'bold', py: 1 }}>{getTotalOfMonth(content, 'april')}</TableCell>
                    <TableCell align="center"
                      sx={{ fontWeight: 'bold', py: 1 }}>{getTotalOfMonth(content, 'may')}</TableCell>
                    <TableCell align="center"
                      sx={{ fontWeight: 'bold', py: 1 }}>{getTotalOfMonth(content, 'june')}</TableCell>
                    <TableCell align="center"
                      sx={{ fontWeight: 'bold', py: 1 }}>{getTotalOfMonth(content, 'july')}</TableCell>
                    <TableCell align="center"
                      sx={{ fontWeight: 'bold', py: 1 }}>{getTotalOfMonth(content, 'august')}</TableCell>
                    <TableCell align="center"
                      sx={{ fontWeight: 'bold', py: 1 }}>{getTotalOfMonth(content, 'september')}</TableCell>
                    <TableCell align="center"
                      sx={{ fontWeight: 'bold', py: 1 }}>{getTotalOfMonth(content, 'october')}</TableCell>
                    <TableCell align="center"
                      sx={{ fontWeight: 'bold', py: 1 }}>{getTotalOfMonth(content, 'november')}</TableCell>
                    <TableCell align="center"
                      sx={{ fontWeight: 'bold', py: 1 }}>{getTotalOfMonth(content, 'december')}</TableCell>
                    <TableCell align="center"
                      sx={{ fontWeight: 'bold', py: 1 }}>{getTotal(content)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ pt: 2, display: 'flex', justifyContent: 'center' }}>
              {editable && <Button color="primary" variant="contained" onClick={open}>Add country</Button>}
            </Box>
            <AddCountryWorkdayDialog visible={visible} close={close} content={content} setContent={setContent}/>
          </>
        );
      }}
    />
  );
}
