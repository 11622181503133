import React, { useState } from 'react';
import {
  Alert as MuiAlert,
  Box as MuiBox,
  Paper as MuiPaper,
  Typography as MuiTypography
} from '@mui/material';
import LoginForm from 'components/LoginForm/LoginForm';
import TwoFactorForm from '../components/LoginForm/TwoFactorForm';

export default function LoginPage(): React.ReactElement {

  const [token, setToken] = useState<string | undefined>(undefined);

  return <MuiBox
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
    }}
  >
    <MuiPaper
      sx={{
        py: 3,
        px: 2,
        pt: 2,
        width: '30em',
        maxWidth: '100%'
      }}
    >
      <MuiTypography
        sx={{
          pb: 2,
          textTransform: 'uppercase',
          fontSize: '2em',
          textAlign: 'center'
        }}
      >
        MPGLOBALTAX
      </MuiTypography>
      {
        token
          ? <MuiBox>
            <MuiAlert severity='info' icon={<></>}>
              A code has been sent to your email address.
            </MuiAlert>
            <TwoFactorForm token={token} setToken={setToken}/>
          </MuiBox>
          : <LoginForm setToken={setToken} />
      }
    </MuiPaper>
  </MuiBox>;
}
