import React, { useEffect, useState } from 'react';
import { Box, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Container, Draggable, OnDropCallback } from 'react-smooth-dnd';
import { arrayMoveImmutable } from 'array-move';

import CreateFormItemDialog from '../../Dialog/CreateFormItemDialog/CreateFormItemDialog';
import ItemEditor from '../ItemEditor';
import EditSheetTitleDialog from '../../Dialog/EditSheetTitleDialog/EditSheetTitleDialog';
import TitleEditor from '../TitleEditor/TitleEditor';
import InputTextEditor from '../InputTextEditor/InputTextEditor';
import { AdminFormFieldDto, AdminSheetDto, FieldType } from 'types';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { useReorderFields } from 'hooks/useReorderFields';
import InputFileEditor from '../InputFileEditor/InputFileEditor';
import RadioBoxEditor from '../RadioBoxEditor/RadioBoxEditor';
import DatePickerEditor from '../DatePickerEditor/DatePickerEditor';
import WorkdayEditor from '../WorkdayEditor/WorkdayEditor';

import './SheetEditor.css';
import CheckBoxEditor from 'components/FormEditor/CheckBoxEditor/CheckBoxEditor';
import InputGroupEditor from 'components/FormEditor/InputGroupEditor/InputGroupEditor';

export interface AdminSheetEditorProps {
  sheet: AdminSheetDto;
}

export default function SheetEditor({
  sheet
}: AdminSheetEditorProps) {
  const listItemBorder = '1px solid #dddddd';
  const [createVisible, setCreateVisible] = useState<boolean>(false);
  const [editVisible, setEditVisible] = useState<boolean>(false);
  const [fields, setFields] = useState<AdminFormFieldDto[]>(sheet.fields);
  const { submit } = useReorderFields(sheet.id);

  useEffect(() => {
    setFields(sheet.fields);
  }, [sheet]);

  const onDrop: OnDropCallback = ({ removedIndex, addedIndex }) => {
    if (removedIndex !== null && addedIndex !== null) {
      const updatedList = arrayMoveImmutable(fields, removedIndex, addedIndex);
      setFields(updatedList);
      submit(updatedList.map(field => field.id));
    }
  };

  return (
    <>
      <List sx={{ mx: 2, p: 0, my: 1, border: listItemBorder, borderTop: 'none' }}>
        <ItemEditor
          label="Page title"
          actions={
            <>
              <ListItemButton sx={{ flex: 0 }} onClick={() => {
                setEditVisible(true);
              }}>
                Edit
              </ListItemButton>
              <EditSheetTitleDialog visible={editVisible} close={() => {
                setEditVisible(false);
              }}
              sheet={sheet}/>
            </>
          }>
          <ListItemText sx={{ px: 1, textAlign: 'center' }} primary={sheet.name}/>
        </ItemEditor>
        <Container
          dragHandleSelector=".drag-handle"
          lockAxis="y"
          onDrop={onDrop}
          dragClass="dragging"
        >
          {fields.map(field => {
            return <Draggable key={field.id}>
              <ListItem>
                <>
                  <ListItemIcon className="drag-handle" sx={{ px: 2, cursor: 'grab' }}>
                    <DragHandleIcon/>
                  </ListItemIcon>
                  {
                    field.type === FieldType.TITLE && <TitleEditor field={field}/>
                  }
                  {
                    field.type === FieldType.INPUT_TEXT && <InputTextEditor field={field}/>
                  }
                  {
                    field.type === FieldType.INPUT_FILE && <InputFileEditor field={field}/>
                  }
                  {
                    field.type === FieldType.RADIO_BOX && <RadioBoxEditor field={field}/>
                  }
                  {
                    field.type === FieldType.DATE_PICKER && <DatePickerEditor field={field}/>
                  }
                  {
                    field.type === FieldType.WORKDAY && <WorkdayEditor field={field}/>
                  }
                  {
                    field.type === FieldType.CHECK_BOX && <CheckBoxEditor field={field}/>
                  }
                  {
                    field.type === FieldType.INPUT_GROUP && <InputGroupEditor field={field}/>
                  }
                </>
              </ListItem>
            </Draggable>;
          })}
        </Container>

      </List>
      <Box sx={{ display: 'flex', justifyContent: 'center', py: 1 }}>
        <Button variant={'outlined'} onClick={() => {
          setCreateVisible(true);
        }}>Add new item</Button>
        <CreateFormItemDialog visible={createVisible} close={() => {
          setCreateVisible(false);
        }} sheet={sheet}/>
      </Box>
    </>
  );
}
