import { CreateEmployeeRequestDto } from 'types';
import { useSubmitForm, UseSubmitFormOptions } from 'hooks/useSubmitForm';

export interface CreateEmployeeRequestFormValues {
  lastName?: string;
  firstName?: string;
  email?: string;
}

export function useCreateEmployeeRequest(props: UseSubmitFormOptions<CreateEmployeeRequestFormValues>): [
  submit: (values: CreateEmployeeRequestFormValues) => Promise<CreateEmployeeRequestDto>,
  loading: boolean,
  errorOccured: boolean,
] {
  return useSubmitForm<CreateEmployeeRequestFormValues, CreateEmployeeRequestDto>({
    ...props,
    urlPath: 'api/employers/request-employees/submit',
  });
}
