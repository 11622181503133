import React from 'react';
import { Box as MuiBox, Button as MuiButton, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import VerticalTabs from '../Tabs/VerticalTabs/VerticalTabs';
import SheetViewer from './SheetViewer';
import { useSaveTaxReturn } from 'hooks/TaxReturn/useSaveTaxReturn';
import { useTaxReturnRequestContext } from 'hooks/TaxReturn/TaxReturnContext';
import Paper from 'components/Paper/Paper';
import useSubmitTaxReturnRequest from 'hooks/TaxReturnRequest/useSubmitTaxReturnRequest';
import { useBoolean } from 'hooks/useBoolean';
import DialogConfirmation from 'components/Dialog/DialogConfirmation';

interface TaxReturnViewerProps {
  editable: boolean;
}

export default function TaxReturnViewer({ editable }: TaxReturnViewerProps) {
  const { handleSubmit, control, setValue } = useForm();
  const { request: { id: requestId, taxReturn } } = useTaxReturnRequestContext();
  const {
    value: confirmVisible,
    setTrue: openConfirm,
    setFalse: closeConfirm,
  } = useBoolean();
  const [loadingSubmit, , requestSubmit] = useSubmitTaxReturnRequest(requestId, closeConfirm);
  const { saveFields, loading } = useSaveTaxReturn(requestId, {});
  const { saveFields: saveAndSubmitFields, loading: loadingSaving } = useSaveTaxReturn(requestId, {
    onSuccess: requestSubmit
  });

  const saveDraft = handleSubmit(saveFields);
  const saveAndSubmit = handleSubmit(saveAndSubmitFields);


  return (
    <Paper>
      <MuiBox>
        <MuiBox sx={{ py: 2, px: 9, textAlign: 'right' }}>
          {editable && (
            <>
              <MuiButton
                variant="outlined"
                color="success"
                onClick={saveDraft}
                disabled={loading}
              >
                {loading ? 'Please wait' : 'Save draft'}
              </MuiButton>
              <MuiButton
                variant="contained"
                color="success"
                onClick={openConfirm}
                disabled={loadingSubmit}
                sx={{ ml: 2 }}
              >
                {loadingSubmit ? 'Please wait' : 'Submit tax return'}
              </MuiButton>
            </>
          )}
        </MuiBox>
        <div
          style={{
            display: 'flex'
          }}
        >
          {taxReturn
            ? (
              <VerticalTabs
                items={taxReturn.form.sheets.map(sheet => {
                  return {
                    label: sheet.name,
                    panel: <SheetViewer
                      sheet={sheet}
                      control={control}
                      setValue={setValue}
                      editable={editable}
                    />
                  };
                })}
              />
            )
            : (
              <p>Please wait</p>
            )}
        </div>
        <MuiBox sx={{ py: 2, px: 9, textAlign: 'right' }}>
          {editable && (
            <>
              <MuiButton
                variant="outlined"
                color="success"
                onClick={saveDraft}
                disabled={loading}
              >
                {loading ? 'Please wait' : 'Save draft'}
              </MuiButton>
              <MuiButton
                variant="contained"
                color="success"
                onClick={openConfirm}
                disabled={loadingSubmit}
                sx={{ ml: 2 }}
              >
                {loadingSubmit ? 'Please wait' : 'Submit tax return'}
              </MuiButton>
            </>
          )}
        </MuiBox>
      </MuiBox>
      <DialogConfirmation
        onCancel={closeConfirm}
        onConfirm={saveAndSubmit}
        loading={loadingSubmit || loadingSaving}
        open={confirmVisible}
      >
        <Typography>
          If you submit your tax return, you should not edit it before your lawyer review it. If you just want to save a
          partial form, please click &quot;Save draft&quot; button
        </Typography>
      </DialogConfirmation>
    </Paper>
  );
}
