import React from 'react';

import BasicDialog from 'components/Dialog/BasicDialog';
import BaseForm from '../../Form/BaseForm/BaseForm';
import { Alert } from '@mui/material';
import { UserDto } from 'types';
import { CreateEmployeeFormValues, useCreateEmployee } from 'hooks/Employee/useCreateEmployee';
import BaseSelect from 'components/Form/BaseForm/Fields/BaseSelect';

interface CreateEmployeeDialogProps {
  companyId: string;
  visible: boolean;
  close: () => void;
  fetchCompany: () => void;
  employers: UserDto[];
}

export default function CreateEmployeeDialog({
  companyId,
  visible,
  close,
  fetchCompany,
  employers,
}: CreateEmployeeDialogProps) {

  const [submit] = useCreateEmployee(companyId, {
    onSuccess: () => {
      fetchCompany();
      close();
    }
  });

  return (
    <BasicDialog
      open={visible}
      onClose={close}
      title="Create new employee"
    >
      <Alert color="info">
        When you create a new employee, an email will be sent to the email adress created
      </Alert>
      <BaseForm
        urlValidation={`api/admin/companies/${companyId}/employees/create/validate`}
        initialValues={{ firstName: '', lastName: '', email: '' }}
        inputs={[
          { name: 'firstName', label: 'First name' },
          { name: 'lastName', label: 'Last name' },
          { name: 'email', label: 'Email' },
          {
            name: 'employerId',
            label: 'Employer',
            render: () => {
              return <BaseSelect
                options={employers}
                getOptionValue={option => option.id}
                getOptionLabel={option => `${option.firstName} ${option.lastName}`}
              />;
            }
          }
        ]}
        onSubmit={(values: CreateEmployeeFormValues) => {
          return submit(values);
        }}
      />
    </BasicDialog>
  );
}