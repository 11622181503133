import React, { useState } from 'react';
import { Box as MuiBox, List as MuiList, Typography as MuiTypography } from '@mui/material';

import { FormFieldProps } from './utils';
import FormFieldContainer from './FormFieldContainer';
import { useInputFileUpload } from 'hooks/InputFile/useInputFileUpload';
import FileItem from './FileItem';
import { useTaxReturnRequestContext } from 'hooks/TaxReturn/TaxReturnContext';
import BaseInputFile from '../../Form/BaseForm/Fields/BaseInputFIle';
import { InputFileDto } from 'types';

export default function FormFieldInputFile<FormValues>({ formField, editable }: FormFieldProps<FormValues>) {
  const { request: { id: requestId } } = useTaxReturnRequestContext();
  const [inputFile, setInputFile] = useState<InputFileDto>(formField.data as InputFileDto);
  const { upload, progress } = useInputFileUpload({
    requestId: requestId,
    inputId: formField.id,
    onSuccess: updatedInput => {
      setInputFile(updatedInput);
    }
  });

  return (
    <FormFieldContainer
      formField={inputFile}
      formFieldId={formField.id}
      skeletonProps={{ variant: 'text', height: 50 }}
      render={field => {
        return (
          <>
            {field.description && (
              <MuiTypography>{field.description}</MuiTypography>
            )}

            <MuiBox sx={{ display: 'flex', alignItems: 'center' }}>
              {editable && (
                <>
                  <BaseInputFile
                    name={`${field.id}-input-file`}
                    label={`${field.label} (2Mo max)`}
                    isValidating={false}
                    violations={[]}
                    percentage={progress}
                    displayFileName={false}
                    onChange={event => {
                      const files = event.target.files;
                      if (files === null) {
                        return;
                      }

                      const file: File | null = files[0] ?? null;
                      if (file !== null) {
                        upload(file);
                      }
                    }}
                  />
                </>
              )}

              <MuiList sx={{ marginLeft: 4 }}>
                {field.files.map(file => {
                  return (
                    <FileItem
                      editable={editable}
                      inputFIleId={formField.id}
                      setInputFile={setInputFile}
                      file={file}
                      key={file.id}
                    />
                  );
                })}
              </MuiList>
            </MuiBox>
          </>
        );
      }}
    />
  );
}
