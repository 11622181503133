import React, { useEffect, useMemo, useState } from 'react';
import {
  CircularProgress as MuiCircularProgress,
  Divider as MuiDivider,
  Grid as MuiGrid,
  List as MuiList,
  ListItem as MuiListItem,
  TablePagination as MuiTablePagination,
  Typography
} from '@mui/material';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { Box as MuiBox } from '@mui/system';

import EmptyOnError from '../EmptyOnError/EmptyOnError';
import { FetchDataFunction, PaginationType, SearchResultDto } from 'types';

export type TableHeaderFunction<DataType, FilterType = undefined> = (
  fetchedData: SearchResultDto<DataType> | undefined,
  filter: Partial<FilterType>,
  setFilter: (newFilter: Partial<FilterType>) => void
) => React.ReactNode

export interface TableProps<DataType extends object, FilterType> {
  // Table - general
  fetchData?: FetchDataFunction<SearchResultDto<DataType>, FilterType>;
  // Header
  header?: (
    filter: Partial<FilterType>,
    setFilter: (newFilter: Partial<FilterType>) => void,
    fetchedData?: SearchResultDto<DataType>
  ) => React.ReactElement;
  // Pagination
  pagination: PaginationType;
  setPagination: (newPagination: PaginationType) => void;
  //  filters
  filter: Partial<FilterType>;
  setFilter: (newFilter: Partial<FilterType>) => void;
  // Custom render
  renderRow: (data: DataType) => React.ReactElement;
}

function emptyResult(component: React.ReactElement): React.ReactElement {
  return (
    <MuiBox
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Typography
        sx={{
          my: 5,
          mx: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        color="text.secondary"
        align="center"
      >
        {component}
      </Typography>
    </MuiBox>
  );
}

export default function Table<DataType extends object, FilterType>({
  // Table - general
  fetchData,
  // Header
  header,
  // Pagination
  pagination,
  setPagination,
  //  filters
  filter,
  setFilter,
  renderRow
}: TableProps<DataType, FilterType>): React.ReactElement {
  const [fetchedData, setFetchedData] = useState<SearchResultDto<DataType> | undefined>(undefined);
  const [isOnError, setIsOnError] = useState<boolean>(false);

  // This variable is increase each time we want to re-fetch data
  const [triggerUpdate, setTriggerUpdate] = useState<number>(0);

  function innerSetPagination(newPagination: PaginationType): void {
    setPagination(newPagination);
  }

  // Table header
  const tableHeader = useMemo(() => {
    // Go back to pagination when filter is update
    const innerSetFilter = (filter: Partial<FilterType>) => {
      setPagination({ ...pagination, page: 1 });
      setFilter(filter);
    };
    return (header != null) && header(filter, innerSetFilter, fetchedData);
  }, [header, fetchedData, filter, setFilter, pagination, setPagination]);

  // When the parameters change, we recall the function to retrieve corresponding data
  useEffect(() => {
    let isOutdated = false;
    // Fetch new data
    const loadNewData = async function loadNewData(): Promise<void> {
      try {
        if (fetchData !== undefined) {
          setIsOnError(false);
          setFetchedData(undefined);
          const data = await fetchData(pagination, filter);
          if (!isOutdated) {
            setFetchedData(data);
          }
        }
      } catch (e) {
        setFetchedData(undefined);
        setIsOnError(true);
      }
    };
    loadNewData();
    return (): void => {
      isOutdated = true;
    };
  }, [pagination, filter, triggerUpdate, fetchData]);

  return (
    <div className="tableContainer">
      {tableHeader}
      <MuiList sx={{ p: 0 }}>
        {fetchedData === undefined
          ? (
            <MuiBox
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {isOnError
                ? emptyResult(
                  <EmptyOnError reload={() => setTriggerUpdate(val => ++val)}/>
                )
                : emptyResult(<MuiCircularProgress/>)}
            </MuiBox>
          )
          : fetchedData.items.length === 0
            ? (
              emptyResult(
                <>
                  <span>Aucun résultat trouvé </span>
                  <SentimentVeryDissatisfiedIcon
                    sx={{ fontSize: '1.5em', ml: 1 }}
                  />
                </>
              )
            )
            : (
              fetchedData.items.map((data, indexRow) => {
                return (
                  <MuiListItem sx={{ p: 0 }} key={indexRow}>
                    <MuiGrid container flexDirection="column">
                      {renderRow(data)}
                      <MuiDivider/>
                    </MuiGrid>
                  </MuiListItem>
                );
              })
            )}
      </MuiList>
      <MuiTablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={fetchedData?.total ?? 0}
        rowsPerPage={pagination.pageSize}
        page={pagination.page - 1}
        onPageChange={(_event, newPage) => {
          innerSetPagination({
            page: newPage + 1,
            pageSize: pagination.pageSize
          });
        }}
        onRowsPerPageChange={event => {
          innerSetPagination({
            page: 1,
            pageSize: parseInt(event.target.value, 10)
          });
        }}
      />
    </div>
  );
}
