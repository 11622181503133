import React from 'react';
import { ListItemButton, ListItemText } from '@mui/material';
import ItemEditor from '../ItemEditor';
import { AdminFormFieldDto } from 'types';
import { useFetchAdminInputFile } from 'hooks/useFetchDto';
import UpdateFormItemDialog from 'components/Dialog/UpdateFormItemDialog';
import { useBoolean } from 'hooks/useBoolean';

export interface InputFileEditorProps {
  field: AdminFormFieldDto;
}

export default function InputFileEditor({
  field
}: InputFileEditorProps) {
  const {
    value: visible,
    setTrue: open,
    setFalse: close,
  } = useBoolean();
  return (
    <ItemEditor
      label={'INPUT FILE'}
      actions={<>
        <ListItemButton sx={{ flex: 0 }} onClick={open}>Edit</ListItemButton>
        {
          visible && <UpdateFormItemDialog
            visible={visible}
            close={close}
            hookFetchDto={useFetchAdminInputFile}
            itemId={field.id}
            urlUpdate={`api/admin/input-files/${field.id}/update`}
            urlValidation={`api/admin/input-files/${field.id}/update/validate`}
            title="Update input file"
            initials={item => {
              return {
                label: item.label,
                description: item.description ?? ''
              };
            }}
            inputs={[
              { name: 'label', label: 'Label' },
              { name: 'description', label: 'Description' }
            ]}
          />
        }
      </>}>
      <ListItemText sx={{ px: 1, textAlign: 'center' }} primary={field.label}/>
    </ItemEditor>
  );
}
