import { UserDto } from 'types';
import { useSubmitForm, UseSubmitFormOptions } from 'hooks/useSubmitForm';

export interface CreateEmployerFormValues {
  lastName?: string;
  firstName?: string;
  email?: string;
}

export function useCreateEmployer(companyId: string, props: UseSubmitFormOptions<CreateEmployerFormValues>): [
  submit: (values: CreateEmployerFormValues) => Promise<UserDto>,
  loading: boolean,
  errorOccured: boolean,
] {
  return useSubmitForm<CreateEmployerFormValues, UserDto>({
    ...props,
    urlPath: `api/admin/companies/${companyId}/employers/create`,
  });
}
