import React from 'react';
import { Typography as MuiTypography } from '@mui/material';

import { FormFieldWithChange } from './utils';
import FormFieldContainer from './FormFieldContainer';
import ControlledCheckBox from 'components/Form/ControlledInputs/ControlledCheckBox';
import { CheckBoxDto } from 'types';

export default function FormFieldCheckBox<FormValues extends object>({
  id,
  formField,
  control,
  editable,
}: FormFieldWithChange<FormValues>) {
  const checkBox = formField.data as CheckBoxDto;
  return (
    <FormFieldContainer
      formField={checkBox}
      formFieldId={id}
      skeletonProps={{ variant: 'text', height: 50 }}
      render={field => {
        return (
          <>
            {field.description && (
              <MuiTypography>{field.description}</MuiTypography>
            )}
            <ControlledCheckBox
              label={field.label}
              name={id}
              control={control}
              disabled={!editable}
              defaultValue={field.value as boolean ?? undefined}
            />
          </>
        );
      }}
    />
  );
}
