import React from 'react';

import BasicDialog from 'components/Dialog/BasicDialog';
import { TaxReturnSummaryDto, UpdateTaxReturnFormValues } from 'types';
import { useUpdateTaxReturn } from 'hooks/TaxReturn/useUpdateTaxReturn';
import BaseForm from '../../Form/BaseForm/BaseForm';

interface EditTaxReturnDialogProps {
  visible: boolean;
  close: () => void;
  taxReturn: TaxReturnSummaryDto;
}

const EditTaxReturnDialog = ({
  visible,
  close,
  taxReturn
}: EditTaxReturnDialogProps) => {
  const { submit } = useUpdateTaxReturn(taxReturn.id, {
    onSuccess: () => {
      close && close();
    }
  });
  const initials: UpdateTaxReturnFormValues = {
    name: taxReturn.name
  };

  return (
    <BasicDialog
      open={visible}
      onClose={close}
      title="Update tax return"
    >
      <BaseForm
        urlValidation={`api/admin/tax-returns/${taxReturn.id}/update/validate`}
        initialValues={initials}
        inputs={[{ name: 'name', label: 'Name' }]}
        onSubmit={submit}
      />
    </BasicDialog>
  );
};

export default EditTaxReturnDialog;
