import axios, { AxiosError } from 'axios';
import { useHandleBackendContext } from 'hooks/HandleBackendContext';
import { useState } from 'react';
import { InputFileDto } from 'types';
import { useAuthenticationContext } from '../../context/AuthenticationContext';

interface RemoveFileProps {
  requestId: string;
  inputId: string;
  onSuccess?: (input: InputFileDto) => void;
}

interface RemoveFileResult {
  loading: boolean;
  errorOccured: boolean;
  callBackend: (fileId: string) => void;
}

export function useRemoveFile({
  requestId,
  inputId,
  onSuccess
}: RemoveFileProps): RemoveFileResult {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorOccured, setErrorOccured] = useState<boolean>(false);
  const { accessToken } = useAuthenticationContext();
  const { handleBackendError } = useHandleBackendContext();

  const callBackend = (fileId: string) => {
    setLoading(true);
    setErrorOccured(false);

    axios
      .post<InputFileDto>(
        `${process.env.REACT_APP_BASE_API_URI}/api/requests/${requestId}/input-files/${inputId}/files/${fileId}/remove`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      .then(response => {
        (onSuccess != null) && onSuccess(response.data);
      })
      .catch((err: AxiosError) => {
        setErrorOccured(true);
        handleBackendError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    loading,
    errorOccured,
    callBackend
  };
}
