import React from 'react';
import { Dialog as MuiDialog, DialogContent, DialogProps as MuiDialogProps, DialogTitle } from '@mui/material';

export interface BasicDialogProps extends Omit<MuiDialogProps, 'title'> {
  title?: React.ReactNode;
}

export default function BasicDialog({ children, title, ...props }: BasicDialogProps) {
  const customDefault: MuiDialogProps = {
    ...props,
    fullWidth: true,
    maxWidth: 'xs'
  };
  const finalProps: MuiDialogProps = { ...customDefault, ...props };
  return <MuiDialog {...finalProps} >
    {title && <DialogTitle>{title}</DialogTitle>}
    <DialogContent>
      {children}
    </DialogContent>
  </MuiDialog>;
}