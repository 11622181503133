import React from 'react';
import { Box, ListItem } from '@mui/material';

export interface ItemEditorProps {
  label: string
  children: React.ReactElement
  actions: React.ReactElement
}

export default function ItemEditor ({
  label, children, actions
}: ItemEditorProps) {
  return (
    <ListItem component={'div'} >
      <Box sx={{ flex: 1, display: 'flex' }}>
        <ListItem component={'div'} sx={{
          fontWeight: 'bold',
          py: 0,
          px: 1,
          flex: 0,
          whiteSpace: 'nowrap',
          textTransform: 'uppercase'
        }} >
          {label}
        </ListItem>
        <ListItem component={'div'} sx={{ py: 0, px: 1, flex: 1 }} >
          {children}
        </ListItem>
      </Box>
      {actions}
    </ListItem>
  );
}
