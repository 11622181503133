import React from 'react';
import Page from 'components/Page/Page';
import { useFetchCreateEmployeeRequests } from 'hooks/useFetchDto';
import { Alert, Box, Button, Chip, Grid, List, ListItem, ListItemText, Paper, Typography } from '@mui/material';
import AsyncComponent from 'components/AsyncComponent/AsyncComponent';
import { useBoolean } from 'hooks/useBoolean';
import AddEmployeeRequestDialog from 'components/Dialog/AddEmployeeRequestDialog/AddEmployeeRequestDialog';
import { createEmployeeRequestStatusMapping } from 'types';

export default function EmployerHomePage(): React.ReactElement {

  const [requests, loading, errorOccured, fetch] = useFetchCreateEmployeeRequests();
  const {
    value: visible,
    setTrue: open,
    setFalse: close,
  } = useBoolean();

  return <Page title="Dashboard">
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid xs={12} item sx={{ justifyContent: 'center', display: 'flex' }}>
        <AsyncComponent loading={loading} errorOccured={errorOccured} reload={fetch} result={requests}>
          {
            loadedRequests => {
              return <Paper sx={{ p: 2, minWidth: 550 }}>
                {
                  loadedRequests.length === 0 && <Alert color="warning" sx={{ alignItems: 'center' }}>
                    <Typography>No create employee request submitted
                      <Button variant="text" color="warning" onClick={open}>Create one</Button>
                    </Typography>
                  </Alert>
                }
                {
                  loadedRequests.length > 0 && <>
                    <Box sx={{ textAlign: 'right' }}>
                      <Button onClick={open}>New request</Button>
                    </Box>
                    <List>
                      {
                        loadedRequests?.map((request, index) => <ListItem key={index}>
                          <ListItemText
                            primary={`${request.firstName} ${request.lastName}`}
                            secondary={request.email}
                          />
                          <Chip
                            label={createEmployeeRequestStatusMapping[request.status].label}
                            sx={{
                              background: createEmployeeRequestStatusMapping[request.status].backgroundColor,
                              color: createEmployeeRequestStatusMapping[request.status].contentColor,
                            }}
                          />
                        </ListItem>
                        )
                      }
                    </List>
                  </>
                }
              </Paper>;
            }
          }
        </AsyncComponent>
        <AddEmployeeRequestDialog visible={visible} close={close} fetchRequests={fetch}/>
      </Grid>
    </Grid>
  </Page>;
}
