import React from 'react';

import BasicDialog, { BasicDialogProps } from 'components/Dialog/BasicDialog';
import AsyncComponent from 'components/AsyncComponent/AsyncComponent';

export interface AsyncDialogProps<LoadedElement> extends Omit<BasicDialogProps, 'children'> {
  loading: boolean;
  errorOccured: boolean;
  reload: () => void;
  result?: LoadedElement;
  children: (result: LoadedElement) => React.ReactElement;
}

export default function AsyncDialog<LoadedElement>({
  loading,
  errorOccured,
  reload,
  result,
  children,
  ...props
}: AsyncDialogProps<LoadedElement>) {

  return (
    <BasicDialog {...props} >
      <AsyncComponent
        loading={loading}
        errorOccured={errorOccured}
        reload={reload}
        result={result}
      >
        {children}
      </AsyncComponent>
    </BasicDialog>
  );
}
