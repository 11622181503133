import { UserDto } from 'types';
import { useSubmitForm, UseSubmitFormOptions } from 'hooks/useSubmitForm';

export interface CreateEmployeeFormValues {
  lastName?: string;
  firstName?: string;
  email?: string;
  employerId?: string;
}

export function useCreateEmployee(companyId: string, props: UseSubmitFormOptions<CreateEmployeeFormValues>): [
  submit: (values: CreateEmployeeFormValues) => Promise<UserDto>,
  loading: boolean,
  errorOccured: boolean,
] {
  return useSubmitForm<CreateEmployeeFormValues, UserDto>({
    ...props,
    urlPath: `api/admin/companies/${companyId}/employees/create`,
  });
}
