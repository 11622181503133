import React, { useState } from 'react';

import { useSearchTaxReturns } from 'hooks/TaxReturn/useSearchTaxReturns';
import { FilterDashboardTaxReturn, PaginationType } from 'types';
import Table from '../List';
import { ListItemTaxReturn } from './ListItemTaxReturn';

export function ListTaxReturn (): React.ReactElement {
  const searchTaxReturn = useSearchTaxReturns();
  const [pagination, setPagination] = useState<PaginationType>({
    page: 1,
    pageSize: 10
  });
  const [filter, setFilter] = useState<FilterDashboardTaxReturn>({});
  const refreshDashboard = () => {
    setFilter({ ...filter });
  };

  return (
    <Table
      pagination={pagination}
      setPagination={setPagination}
      filter={filter}
      setFilter={setFilter}
      fetchData={searchTaxReturn}
      renderRow={taxReturn => {
        return <ListItemTaxReturn taxReturn={taxReturn} refreshDashboard={refreshDashboard} />;
      }}
    />
  );
}
