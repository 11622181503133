import React from 'react';

import BasicDialog from 'components/Dialog/BasicDialog';
import BaseForm from '../../Form/BaseForm/BaseForm';
import { CreateCompanyFormValues, useCreateCompany } from 'hooks/Company/useCreateCompany';

interface CreateSheetDialogProps {
  visible: boolean;
  close: () => void;
  fetchCompanies: () => void;
}

export default function CreateCompanyDialog({
  visible,
  close,
  fetchCompanies
}: CreateSheetDialogProps) {

  const [submit] = useCreateCompany({
    onSuccess: () => {
      fetchCompanies();
      close();
    }
  });

  return (
    <BasicDialog
      open={visible}
      onClose={close}
      title="Create new company"
    >
      <BaseForm
        urlValidation={'api/admin/companies/create/validate'}
        initialValues={{ name: '' }}
        inputs={[{ name: 'name', label: 'Name' }]}
        onSubmit={(values: CreateCompanyFormValues) => {
          return submit(values);
        }}
      />
    </BasicDialog>
  );
}