import React from 'react';
import { ListItemButton, ListItemText } from '@mui/material';
import ItemEditor from '../ItemEditor';
import { AdminFormFieldDto } from 'types';
import { useFetchAdminTitle } from 'hooks/useFetchDto';
import BaseAsyncSelect from 'components/Form/BaseForm/Fields/BaseAsyncSelect';
import { titleLevels } from 'referentiels';
import UpdateFormItemDialog from 'components/Dialog/UpdateFormItemDialog';
import { useBoolean } from 'hooks/useBoolean';

export interface TitleEditorProps {
  field: AdminFormFieldDto;
}

export default function TitleEditor({
  field
}: TitleEditorProps) {
  const {
    value: visible,
    setTrue: open,
    setFalse: close,
  } = useBoolean();

  return (
    <ItemEditor
      label={'TITLE'}
      actions={
        <>
          <ListItemButton sx={{ flex: 0 }} onClick={open}>Edit</ListItemButton>
          {
            visible && <UpdateFormItemDialog
              visible={visible}
              close={close}
              hookFetchDto={useFetchAdminTitle}
              itemId={field.id}
              urlUpdate={`api/admin/titles/${field.id}/update`}
              urlValidation={`api/admin/titles/${field.id}/update/validate`}
              title="Update title"
              initials={item => {
                return {
                  content: item.content,
                  level: item.level
                };
              }}
              inputs={[
                { name: 'content', label: 'Content' },
                {
                  name: 'level',
                  render: () => {
                    return <BaseAsyncSelect
                      options={titleLevels}
                      loading={false}
                      getOptionValue={option => {
                        return option.level;
                      }}
                      getOptionLabel={option => {
                        return option.label;
                      }}
                    />;
                  },
                  label: 'Level'
                }
              ]}
            />
          }
        </>
      }>
      <ListItemText sx={{ px: 1, textAlign: 'center' }} primary={field.label}/>
    </ItemEditor>
  );
}
