import React from 'react';

import BasicDialog from 'components/Dialog/BasicDialog';
import BaseForm from '../../Form/BaseForm/BaseForm';
import { AdminRadioBoxDto } from 'types';
import { useCreateRadioBoxItem } from 'hooks/RadioBox/RadioBoxItem/useCreateRadioBoxItem';

interface CreateRadioBoxItemDialogProps {
  visible: boolean;
  close: () => void;
  radioBoxId: string;
  setRadioBox: (radioBox: AdminRadioBoxDto) => void;
}

export default function CreateRadioBoxItemDialog({
  visible,
  close,
  radioBoxId,
  setRadioBox
}: CreateRadioBoxItemDialogProps) {

  const { submit } = useCreateRadioBoxItem(radioBoxId, {
    onSuccess: (newAdminRadioBox: AdminRadioBoxDto) => {
      setRadioBox(newAdminRadioBox);
      close();
    }
  });

  return (
    <BasicDialog
      open={visible}
      onClose={close}
      title="New radio box item"
    >
      <BaseForm
        onSubmit={submit}
        urlValidation={`api/admin/radio-boxes/${radioBoxId}/radio-box-items/create/validate`}
        initialValues={{ label: '' }}
        inputs={[
          {
            name: 'label',
            label: 'Label'
          }
        ]}
      />
    </BasicDialog>
  );
}

