import React, { createContext, useContext } from 'react';
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { Path } from 'react-hook-form';

import { ViolationDto } from './types';

interface BaseFormItemContextContent<FormValues extends object> {
  violations: ViolationDto[]
  label: string
  field: ControllerRenderProps<FormValues>
}

export const BaseFormItemContext = createContext<BaseFormItemContextContent<any> | null>(null);

interface BaseFormItemContextResult<FormValues extends object> {
  violations: ViolationDto[]
  field: ControllerRenderProps<FormValues>
  label: string
}

export function useBaseFormItemContext<FormValues extends object> (): BaseFormItemContextResult<FormValues> {
  const context = useContext<BaseFormItemContextContent<FormValues>>(BaseFormItemContext as React.Context<BaseFormItemContextContent<FormValues>>);
  if (context === null) {
    throw new Error('useBaseFormItemContext called outside Context.');
  }

  return {
    violations: context.violations,
    field: context.field,
    label: context.label
  };
}

interface BaseFormItemProviderProps<FormValues extends object> {
  children: React.ReactElement
  violations: ViolationDto[]
  field: ControllerRenderProps<FormValues, keyof FormValues & string & Path<FormValues>>
  label: string
}

export function BaseFormItemProvider<FormValues extends object> ({
  children,
  violations,
  field,
  label
}: BaseFormItemProviderProps<FormValues>) {
  return <BaseFormItemContext.Provider
    value={
      {
        violations,
        field: field as unknown as ControllerRenderProps<any>,
        label
      }
    }
  >
    {children}
  </BaseFormItemContext.Provider>;
}
