import React from 'react';
import { Typography as MuiTypography } from '@mui/material';

import { FormFieldWithChange } from './utils';
import FormFieldContainer from './FormFieldContainer';
import DataViewer from 'components/DataViewer/DataViewer';
import ControlledRadioBox from '../../Form/ControlledInputs/ControlledRadioBox';
import { RadioBoxDto } from 'types';

export default function FormFieldRadioBox<FormValues extends object>({
  id,
  formField,
  control,
  editable
}: FormFieldWithChange<FormValues>) {
  const radioBox = formField.data as RadioBoxDto;
  return (
    <FormFieldContainer
      formFieldId={id}
      formField={radioBox}
      skeletonProps={{ variant: 'text', height: 100 }}
      render={field => {
        return editable
          ? (
            <>
              <MuiTypography>{field.label}</MuiTypography>
              <ControlledRadioBox
                control={control}
                name={id}
                items={field.items.map(item => {
                  return {
                    value: item.id,
                    label: item.label
                  };
                })}
                defaultValue={field.selected?.id ?? undefined}
              />
            </>
          )
          : (
            <DataViewer label={field.label} value={field.selected?.label}/>
          );
      }}
    />
  );
}
