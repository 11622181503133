import axios from 'axios';

import {
  FetchDataFunction,
  FilterDashboardTaxReturn,
  PaginationType,
  SearchResultDto,
  TaxReturnSummaryDto
} from 'types';
import { useAuthenticationContext } from '../../context/AuthenticationContext';

export function useSearchTaxReturns(): FetchDataFunction<SearchResultDto<TaxReturnSummaryDto>,
  FilterDashboardTaxReturn> {
  const { accessToken } = useAuthenticationContext();

  return async (
    pagination: PaginationType,
    filters: Partial<FilterDashboardTaxReturn>
  ) => {
    const axiosResponse = await axios.get<SearchResultDto<TaxReturnSummaryDto>>(
      `${process.env.REACT_APP_BASE_API_URI}/api/admin/tax-returns`,
      {
        params: {
          page: pagination.page,
          pageSize: pagination.pageSize,
          title: filters.title
        },
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return axiosResponse.data;
  };
}
