import React from 'react';

import BasicDialog from 'components/Dialog/BasicDialog';
import BaseForm from '../../Form/BaseForm/BaseForm';
import { CreateCompanyFormValues } from 'hooks/Company/useCreateCompany';
import { TaxReturnSummaryDto } from 'types';
import { useCloneTaxReturn } from 'hooks/TaxReturn/useCloneTaxReturn';

interface CloneTaxReturnDialogProps {
  visible: boolean;
  close: () => void;
  taxReturn: TaxReturnSummaryDto;
  fetchTaxReturns: () => void;
}

export default function CloneTaxReturnDialog({
  visible,
  close,
  taxReturn,
  fetchTaxReturns,
}: CloneTaxReturnDialogProps) {

  const [submit] = useCloneTaxReturn(taxReturn.id, {
    onSuccess: () => {
      fetchTaxReturns();
      close();
    }
  });

  return (
    <BasicDialog
      open={visible}
      onClose={close}
      title={`Clone ${taxReturn.name}`}
    >
      <BaseForm
        urlValidation={`api/admin/tax-returns/${taxReturn.id}/clone/validate`}
        initialValues={{ name: '' }}
        inputs={[{ name: 'name', label: 'Name' }]}
        onSubmit={(values: CreateCompanyFormValues) => {
          return submit(values);
        }}
      />
    </BasicDialog>
  );
}