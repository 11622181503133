import React from 'react';
import { Box, Button, ListItem, ListItemText, Typography } from '@mui/material';
import { CreateEmployeeRequestDto, CreateEmployeeRequestStatus, createEmployeeRequestStatusMapping } from 'types';
import { useBoolean } from 'hooks/useBoolean';
import { useAcceptCreateEmployeeRequest } from 'hooks/CreateEmployeeRequest/useAcceptCreateEmployeeRequest';
import { useDeclineCreateEmployeeRequest } from 'hooks/CreateEmployeeRequest/useDeclineCreateEmployeeRequest';
import DialogConfirmation from 'components/Dialog/DialogConfirmation';

interface ListItemCreateEmployeeRequestProps {
  request: CreateEmployeeRequestDto;
  reload: () => void;
}

export function ListItemCreateEmployeeRequest({
  request,
  reload
}: ListItemCreateEmployeeRequestProps): React.ReactElement {

  const {
    value: visibleAccept,
    setTrue: openAccept,
    setFalse: closeAccept,
  } = useBoolean();
  const {
    value: visibleDecline,
    setTrue: openDecline,
    setFalse: closeDecline,
  } = useBoolean();
  const [submitAccept, loadingAccept] = useAcceptCreateEmployeeRequest(request.id, {
    onSuccess: () => {
      reload();
      closeAccept();
    }
  });
  const [submitDecline, loadingDecline] = useDeclineCreateEmployeeRequest(request.id, {
    onSuccess: () => {
      reload();
      closeAccept();
    }
  });

  return <ListItem>
    <ListItemText
      primary={`${request.employer.firstName} ${request.employer.lastName}`}
      secondary={request.employer.email}
    />
    <ListItemText
      sx={{ textAlign: 'right' }}
      primary={`${request.firstName} ${request.lastName}`}
      secondary={request.email}
    />
    <Box sx={{ pl: 10 }}>
      {
        request.status === CreateEmployeeRequestStatus.PENDING && <>
          <Button color="success" variant="outlined" onClick={openAccept}>Accept</Button>
          <Button color="error" variant="outlined" sx={{ ml: 1 }} onClick={openDecline}>Decline</Button>
          <DialogConfirmation
            onCancel={closeAccept}
            onConfirm={() => {
              return submitAccept();
            }}
            loading={loadingAccept}
            open={visibleAccept}
          >
            <Typography>
              If you accept this request, a new employee will be created and an activation email will be sent
              to {request.email}
            </Typography>
          </DialogConfirmation>
          <DialogConfirmation
            onCancel={closeDecline}
            onConfirm={() => {
              return submitDecline();
            }}
            loading={loadingDecline}
            open={visibleDecline}
          >
            <Typography>
              If you refuse this request, an email will be sent to employer to confirm you refuse his request
            </Typography>
          </DialogConfirmation>
        </>
      }
      {
        request.status !== CreateEmployeeRequestStatus.PENDING &&
        <ListItemText
          primary={createEmployeeRequestStatusMapping[request.status].label}
          sx={{ color: createEmployeeRequestStatusMapping[request.status].backgroundColor }}
        />
      }
    </Box>

  </ListItem>;
}

