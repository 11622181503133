import { SummaryCompanyDto } from 'types';
import { useSubmitForm, UseSubmitFormOptions } from 'hooks/useSubmitForm';

export interface CreateTaxReturnRequestFormValues {
  expirationDate?: string;
  employees: Array<string>;
}

export function useCreateTaxReturnRequests(taxReturnId: string, props: UseSubmitFormOptions<SummaryCompanyDto>): [
  submit: (values: CreateTaxReturnRequestFormValues) => Promise<SummaryCompanyDto>,
  loading: boolean,
  errorOccured: boolean,
] {
  return useSubmitForm<CreateTaxReturnRequestFormValues, SummaryCompanyDto>({
    ...props,
    urlPath: `api/admin/tax-returns/${taxReturnId}/requests/create`,
  });
}
