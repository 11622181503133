import React, { createContext, useContext, useState } from 'react';
import {
  Alert as MuiAlert,
  AlertColor as MuiAlertColor,
  Snackbar as MuiSnackbar,
  SnackbarOrigin as MuiSnackbarOrigin
} from '@mui/material';
import { AxiosError } from 'axios';
import { ErrorDto } from 'types';
import { useAuthenticationContext } from '../context/AuthenticationContext';

export function isErrorBodyAnErrorDto(
  errorBody: unknown
): errorBody is ErrorDto {
  return (
    errorBody !== undefined &&
    errorBody !== null &&
    typeof errorBody === 'object' &&
    (errorBody as ErrorDto).code !== undefined &&
    (errorBody as ErrorDto).message !== undefined
  );
}

interface HandleBackendContextContent {
  handleBackendError: (
    error: AxiosError,
    message?: string,
    onError?: (error: ErrorDto) => void
  ) => void;
  handleBackendSuccess: (message: string) => void;
}

const HandleBackendContext = createContext<HandleBackendContextContent | null>(
  null
);

interface HandleBackendContextResult {
  handleBackendError: (
    error: AxiosError,
    message?: string,
    onError?: (error: ErrorDto) => void
  ) => void;
  handleBackendSuccess: (message: string) => void;
}

export const useHandleBackendContext = (): HandleBackendContextResult => {
  const context = useContext(HandleBackendContext);
  if (context === null) {
    throw new Error('useHandleBackendContext called outside Context.');
  }

  return {
    handleBackendError: context.handleBackendError,
    handleBackendSuccess: context.handleBackendSuccess
  };
};

interface HandleBackendProviderProps {
  children: React.ReactElement;
}

export const HandleBackendProvider = ({
  children
}: HandleBackendProviderProps) => {
  const [notifErrorOpened, setNotifErrorOpened] = useState<boolean>(false);
  const [origin, setOrigin] = useState<MuiSnackbarOrigin>({
    vertical: 'bottom',
    horizontal: 'left'
  });
  const [severity, setSeverity] = useState<MuiAlertColor>('success');
  const { logout } = useAuthenticationContext();
  const [message, setMessage] = useState<string>('');
  const handleBackendError = (
    err: AxiosError,
    customMessage?: string,
    onError?: (error: ErrorDto) => void
  ) => {
    const errorBody = err.response?.data;
    if (isErrorBodyAnErrorDto(errorBody)) {
      (onError != null) && onError(errorBody);
    }
    setNotifErrorOpened(true);
    setSeverity('error');
    setOrigin({
      vertical: 'bottom',
      horizontal: 'left'
    });
    if (customMessage) {
      setMessage(customMessage);
    } else {
      setMessage(
        `An error with ${err.response?.status ?? 'unknown'} status happened`
      );
    }

    if (err.response?.status === 401 || err.response?.status === 403) {
      logout();
    }
  };
  const handleBackendSuccess = (message: string) => {
    setNotifErrorOpened(true);
    setSeverity('success');
    setMessage(message);
    setOrigin({ vertical: 'top', horizontal: 'center' });
  };
  const handleClose = () => {
    setNotifErrorOpened(false);
  };
  return (
    <>
      <MuiSnackbar
        anchorOrigin={origin}
        open={notifErrorOpened}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <MuiAlert
          onClose={handleClose}
          severity={severity}
          sx={{ width: '100%' }}
        >
          {message}
        </MuiAlert>
      </MuiSnackbar>
      <HandleBackendContext.Provider
        value={{
          handleBackendError,
          handleBackendSuccess
        }}
      >
        {children}
      </HandleBackendContext.Provider>
    </>
  );
};
