import axios, { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import {
  AdminInputFileDto,
  AdminInputGroupDto,
  AdminInputTextDto,
  AdminRadioBoxDto,
  AdminTaxReturnDto,
  AdminTitleDto,
  CheckBoxDto,
  CompanyDto,
  CreateEmployeeRequestDto,
  DatePickerDto,
  ErrorDto,
  FetchDataFunction,
  FilterTaxReturnRequestDto,
  InputFileDto,
  InputGroupDto,
  InputTextDto,
  PaginationType,
  RadioBoxDto,
  SearchResultDto,
  SummaryCompanyDto,
  TaxReturnDto,
  TaxReturnRequestDto,
  TaxReturnRequestSummaryDto,
  TitleDto,
  UserDto,
  WorkdayDto
} from 'types';
import useApi from './api';
import { useBoolean } from './useBoolean';
import { useAuthenticationContext } from '../context/AuthenticationContext';
import { usePopMessage } from '../context/PopMessageContext';


export function useFetchAdminRadioBox(radioBoxId: string) {
  return useFetchDto<AdminRadioBoxDto>({ urlPath: `api/admin/radio-boxes/${radioBoxId}` });
}

export function useFetchAdminInputFile(inputId: string) {
  return useFetchDto<AdminInputFileDto>({ urlPath: `api/admin/input-files/${inputId}` });
}

export function useFetchAdminInputText(inputId: string) {
  return useFetchDto<AdminInputTextDto>({ urlPath: `api/admin/input-texts/${inputId}` });
}

export function useFetchAdminTitle(titleId: string) {
  return useFetchDto<AdminTitleDto>({ urlPath: `api/admin/titles/${titleId}` });
}

export function useFetchCompany(id?: string) {
  return useFetchDto<CompanyDto>({ urlPath: `api/admin/companies/${id}` });
}

export function useFetchAdminInputGroup(inputGroupId: string) {
  return useFetchDto<AdminInputGroupDto>({ urlPath: `api/admin/input-groups/${inputGroupId}` });
}

export function useFetchInputGroup(requestId: string, inputGroupId: string) {
  return useFetchDto<InputGroupDto>({ urlPath: `api/requests/${requestId}/input-groups/${inputGroupId}` });
}

export function useFetchAdminTaxReturn(taxReturnId: string) {
  return useFetchDto<AdminTaxReturnDto>({ urlPath: `api/admin/tax-returns/${taxReturnId}` });
}

export function useFetchCheckBox(requestId: string, checkBoxId: string) {
  return useFetchDto<CheckBoxDto>({ urlPath: `api/requests/${requestId}/check-boxes/${checkBoxId}` });
}

export function useFetchDatePicker(requestId: string, datePickerId: string) {
  return useFetchDto<DatePickerDto>({ urlPath: `api/requests/${requestId}/date-pickers/${datePickerId}` });
}

export function useFetchInputFile(requestId: string, inputFileId: string) {
  return useFetchDto<InputFileDto>({ urlPath: `api/requests/${requestId}/input-files/${inputFileId}` });
}

export function useFetchInputText(requestId: string, inputId: string) {
  return useFetchDto<InputTextDto>({ urlPath: `api/requests/${requestId}/input-texts/${inputId}` });
}

export function useFetchRadioBox(requestId: string, radioBoxId: string) {
  return useFetchDto<RadioBoxDto>({ urlPath: `api/requests/${requestId}/radio-boxes/${radioBoxId}` });
}

export function useFetchTitle(titleId: string) {
  return useFetchDto<TitleDto>({ urlPath: `api/titles/${titleId}` });
}

export function useFetchCompanies() {
  return useFetchDto<Array<SummaryCompanyDto>>({ urlPath: 'api/admin/companies' });
}

export function useFetchCreateEmployeeRequests() {
  return useFetchDto<Array<CreateEmployeeRequestDto>>({ urlPath: 'api/employers/request-employees' });
}

export function useFetchAdminCreateEmployeeRequests() {
  return useFetchDto<Array<CreateEmployeeRequestDto>>({ urlPath: 'api/admin/request-employees' });
}

export function useFetchEmployees() {
  return useFetchDto<Array<UserDto>>({ urlPath: 'api/admin/employees' });
}

export function useFetchRequestOfTaxReturn(taxReturnId: string) {
  return useFetchDto<Array<TaxReturnRequestDto>>({ urlPath: `api/admin/tax-returns/${taxReturnId}/requests` });
}

export function useFetchEmployeeTaxReturnRequests() {
  return useFetchDto<Array<TaxReturnRequestDto>>({ urlPath: 'api/employees/tax-return-requests' });
}

export function useFetchTaxReturnRequest(requestId?: string) {
  return useFetchDto<TaxReturnRequestDto>({ urlPath: `api/tax-return-requests/${requestId}` });
}

export function useFetchWorkday(requestId: string, workdayId: string) {
  return useFetchDto<WorkdayDto>({ urlPath: `api/requests/${requestId}/workdays/${workdayId}` });
}

export function useFetchTaxReturn(taxReturnId: string): [
    TaxReturnDto | undefined,
  boolean,
  boolean,
  () => void,
  (dto: TaxReturnDto) => void,
    number | undefined,
    string | undefined,
    ErrorDto | undefined
] {
  const [error, setError] = useState<ErrorDto | undefined>(undefined);
  const { popError } = usePopMessage();
  return [
    ...useFetchDto<TaxReturnDto>({
      urlPath: `api/tax-returns/${taxReturnId}`,
      onError: (axiosError, errorDto) => {
        if (errorDto) {
          setError(errorDto);
        } else {
          popError(axiosError);
        }
      }
    }),
    error
  ];
}

export function useSearchTaxReturnRequestWaitingForAdmin(): FetchDataFunction<SearchResultDto<TaxReturnRequestSummaryDto>, FilterTaxReturnRequestDto> {
  const { accessToken } = useAuthenticationContext();

  return async (
    pagination: PaginationType,
    filters: Partial<FilterTaxReturnRequestDto>
  ) => {
    const axiosResponse = await axios.get<SearchResultDto<TaxReturnRequestSummaryDto>>(
      `${process.env.REACT_APP_BASE_API_URI}/api/admin/requests/waiting-for-admin`,
      {
        params: {
          page: pagination.page,
          pageSize: pagination.pageSize,
          status: filters.status,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return axiosResponse.data;
  };
}

export function useFetchTaxReturnRequestWaitingForAdmin() {
  return useFetchDto<SearchResultDto<TaxReturnRequestSummaryDto>>(
    { urlPath: 'api/admin/requests/waiting-for-admin' }
  );
}

export function useFetchTaxReturnRequestWaitingForEmployee() {
  return useFetchDto<SearchResultDto<TaxReturnRequestSummaryDto>>(
    { urlPath: 'api/admin/requests/waiting-for-employee' }
  );
}

interface UseFetchDtoOptions {
  urlPath: string;
  onError?: (error: AxiosError, errorDto?: ErrorDto) => void;
}

export function useFetchDto<DtoType>({ urlPath, onError }: UseFetchDtoOptions): [
    DtoType | undefined,
  boolean,
  boolean,
  () => void,
  (dto: DtoType) => void,
    number | undefined,
    string | undefined,
] {
  const [dto, setDto] = useState<DtoType>();
  const { get } = useApi<void, DtoType>();
  const { value: loading, setTrue: start, setFalse: stop } = useBoolean();
  const { value: errorOccured, setBoolean: setErrorOccured } = useBoolean();

  const submit = useCallback(() => {
    start();
    setErrorOccured(false);
    get(urlPath)
      .then(response => setDto(response.data))
      .catch((err) => {
        onError && onError(err);
        setErrorOccured(true);
      })
      .finally(stop);
  }, []);

  return [
    dto,
    loading,
    errorOccured,
    submit,
    setDto,
    200,
    undefined,
  ];
}
