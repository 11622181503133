import * as React from 'react';
import { taxReturnRequestStatusMapping, TaxReturnRequestStatut } from 'types';
import { Chip, ChipProps } from '@mui/material';
import { deepmerge } from '@mui/utils';

interface ChipTaxReturnRequestProps extends ChipProps {
  status: TaxReturnRequestStatut;
}

export default function ChipTaxReturnRequest({ status, ...props }: ChipTaxReturnRequestProps) {
  return <Chip
    {...props}
    label={taxReturnRequestStatusMapping[status].label}
    sx={deepmerge({
      background: taxReturnRequestStatusMapping[status].backgroundColor,
      color: taxReturnRequestStatusMapping[status].contentColor,
    }, props.sx ?? {})}
  />;
}
