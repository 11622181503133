import React from 'react';
import {
  Paper as MuiPaper,
  PaperProps as MuiPaperProps,
  Typography as AntdTypography
} from '@mui/material';

interface PaperWithTitleProps extends MuiPaperProps {
  title: string
}

export default function PaperWithTitle
({ title, children, ...props }: PaperWithTitleProps) {
  return (
    <MuiPaper
      {...props}
    >
      <AntdTypography
        variant="h5"
        component="h2" sx={{
          textTransform: 'uppercase',
          py: 1,
          mb: 2,
          background: '#eeeeee',
          textAlign: 'center'
        }}
      >{title}</AntdTypography>
      {children}
    </MuiPaper>
  );
}
