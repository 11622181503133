import { Path } from 'react-hook-form';

export interface LoginFormValues {
  email?: string;
  password?: string;
  remember?: boolean;
}

export interface LoginResponseDto {
  token: string;
  isAdmin: boolean;
}

export interface ErrorDto {
  message: string;
  code: ErrorCode;
  displayedMessage: string | null;
}

export enum ErrorCode {
  'TAX_RETURN_FORBIDDEN' = 'TAX_RETURN_FORBIDDEN',
}

export enum TaxReturnStatus {
  'WAITING_FOR_EMPLOYEE' = 'WAITING_FOR_EMPLOYEE',
}

export interface UserDto {
  id: string;
  lastName: string;
  firstName: string;
  email: string;
  requests: TaxReturnRequestSummaryDto[];
}

export interface UserSummaryDto {
  id: string;
  lastName: string;
  firstName: string;
  email: string;
}

export interface FileDto {
  id: string;
  fileName: string;
  mime: string;
}

export interface LoginToken {
  isAdmin: boolean;
  isEmployer: boolean;
  isEmployee: boolean;
  token: string;
}

export interface SearchResultDto<DtoType> {
  items: DtoType[];
  total: number;
  page: number;
  pageSize: number;
}

export enum FieldType {
  'TITLE' = 'TITLE',
  'INPUT_FILE' = 'INPUT_FILE',
  'INPUT_TEXT' = 'INPUT_TEXT',
  'DATE_PICKER' = 'DATE_PICKER',
  'RADIO_BOX' = 'RADIO_BOX',
  'CHECK_BOX' = 'CHECK_BOX',
  'WORKDAY' = 'WORKDAY',
  'INPUT_GROUP' = 'INPUT_GROUP',
}

export interface FormFieldDto<FormValues> {
  id: string & Path<FormValues>;
  type: FieldType;
  data: unknown;
}

export interface FormSheetDto<FormValues> {
  id: string;
  name: string;
  clonable: boolean;
  fields: Array<FormFieldDto<FormValues>>;
}

export interface FormDto<FormValues> {
  id: string;
  sheets: Array<FormSheetDto<FormValues>>;
}

export interface TaxReturnDto {
  id: string;
  name: string;
  form: FormDto<any>;
  status: TaxReturnStatus;
}

export interface TaxReturnRequestSummaryDto {
  id: string;
  user: UserSummaryDto;
  taxReturnName: string;
  status: TaxReturnRequestStatut;
  expirationDate: string;
}

export interface TaxReturnRequestDto {
  id: string;
  user: UserDto;
  status: TaxReturnRequestStatut;
  expirationDate: string;
  editable: boolean;
  taxReturn: TaxReturnDto;
  file?: FileDto;
}

export enum TaxReturnRequestStatut {
  'WAITING_FOR_EMPLOYEE' = 'WAITING_FOR_EMPLOYEE',
  'WAITING_ADMIN_REVIEW' = 'WAITING_ADMIN_REVIEW',
  'WAITING_AFTER_REJECTED' = 'WAITING_AFTER_REJECTED',
  'WAITING_FOR_RESULT_FILE' = 'WAITING_FOR_RESULT_FILE',
  'COMPLETED' = 'COMPLETED',
  'CLOSED' = 'CLOSED',
}

type TaxReturnRequestStatutMappingType = {
  [key in TaxReturnRequestStatut]: {
    contentColor: string;
    backgroundColor: string;
    label: string;
  };
};

export const taxReturnRequestStatusMapping: TaxReturnRequestStatutMappingType = {
  WAITING_FOR_EMPLOYEE: {
    backgroundColor: 'rgb(124, 208, 255)',
    contentColor: '#FFFFFF',
    label: 'Waiting for employee'
  },
  WAITING_ADMIN_REVIEW: {
    backgroundColor: 'rgb(255, 122, 0)',
    contentColor: '#FFFFFF',
    label: 'Waiting for admin review'
  },
  WAITING_AFTER_REJECTED: {
    backgroundColor: 'rgb(167,27,104)',
    contentColor: '#FFFFFF',
    label: 'Waiting after rejected'
  },
  CLOSED: {
    backgroundColor: 'rgb(0, 0, 0)',
    contentColor: '#FFFFFF',
    label: 'Closed'
  },
  COMPLETED: {
    backgroundColor: 'rgb(0, 131, 204)',
    contentColor: '#FFFFFF',
    label: 'Completed'
  },
  WAITING_FOR_RESULT_FILE: {
    backgroundColor: 'rgb(205,48,0)',
    contentColor: '#FFFFFF',
    label: 'Waiting for file'
  }
};

export interface FilterTaxReturnRequestDto {
  status: unknown;
}

export interface UpdateTaxReturnFormValues {
  name?: string;
}

export interface UpdateSheetFormValues {
  name?: string;
}

export interface CreateSheetFormValues {
  name?: string;
}

export interface UpdateInputFileFormValues {
  label: string;
  description: string;
}

export interface UpdateTitleFormValues {
  content: string | null;
  level: number | null;
}

export interface UpdateInputTextFormValues {
  label: string;
  type: string;
  description: string;
  required: boolean;
}

export interface RadioBoxItemFormValues {
  label: string;
}

export interface InputGroupItemFormValues {
  label: string;
}

export interface TaxReturnSummaryDto {
  id: string;
  name: string;
  nbRequests: number;
  deletable: boolean;
}

export interface AdminTaxReturnDto {
  id: string;
  name: string;
  form: AdminFormDto;
  status: TaxReturnStatus;
  nbUsers: number;
  createdAt: string;
  updatedAt: string;
  createdBy: UserDto;
  updatedBy: UserDto;
  deletable: boolean;
}

export interface AdminFormDto {
  id: string;
  sheets: AdminSheetDto[];
  createdAt: string;
  updatedAt: string;
  createdBy: UserDto;
  updatedBy: UserDto;
}

export interface AdminSheetDto {
  id: string;
  name: string;
  position: number;
  clonable: boolean;
  fields: AdminFormFieldDto[];
  createdAt: string;
  updatedAt: string;
  createdBy: UserDto;
  updatedBy: UserDto;
}

export interface AdminFormFieldDto {
  id: string;
  type: FieldType;
  label: string;
  createdAt: string;
  updatedAt: string;
  createdBy: UserDto;
  updatedBy: UserDto;
}

export interface ModelDto {
  id: string;
}

export interface SummaryCompanyDto extends ModelDto {
  name: string;
  nbEmployers: number;
  nbEmployees: number;
}

export enum CreateEmployeeRequestStatus {
  'PENDING' = 'PENDING',
  'ACCEPTED' = 'ACCEPTED',
  'DECLINED' = 'DECLINED',
}

export interface CreateEmployeeRequestDto extends ModelDto {
  firstName: string;
  lastName: string;
  email: string;
  status: CreateEmployeeRequestStatus;
  employer: UserDto;
}

type CreateEmployeeRequestStatusMapping = {
  [key in CreateEmployeeRequestStatus]: {
    contentColor: string;
    backgroundColor: string;
    label: string;
  };
};

export const createEmployeeRequestStatusMapping: CreateEmployeeRequestStatusMapping = {
  PENDING: {
    contentColor: '#FFFFFF',
    backgroundColor: 'rgb(255, 122, 0)',
    label: 'Waiting for admin approval',
  },
  DECLINED: {
    contentColor: '#FFFFFF',
    backgroundColor: 'rgb(167,27,104)',
    label: 'Declined by admin',
  },
  ACCEPTED: {
    contentColor: '#FFFFFF',
    backgroundColor: 'rgb(16, 205, 0)',
    label: 'Accepted by admin',
  }
};

export interface CompanyDto extends ModelDto {
  id: string;
  name: string;
  employers: UserDto[];
  employees: UserDto[];
}

export interface TitleDto extends ModelDto {
  level: number;
  content: string;
}

export interface AdminTitleDto extends TitleDto {
  createdAt: string;
  updatedAt: string;
  createdBy: UserDto;
  updatedBy: UserDto;
}

export interface InputFileDto extends ModelDto {
  label: string;
  description: string | null;
  files: FileDto[];
}

export interface AdminInputFileDto extends InputFileDto {
  createdAt: string;
  updatedAt: string;
  createdBy: UserDto;
  updatedBy: UserDto;
}

export interface InputGroupItemDto {
  id: string;
  label: string;
  inSummary: string;
}

export interface InputGroupItemValueDto {
  input_group_field_id: string;
  value: string;
}

export interface InputGroupValueto {
  id: string;
  fields: InputGroupItemValueDto[];
}

export interface InputGroupDto extends ModelDto {
  id: string;
  title: string;
  description: string | null;
  fields: InputGroupItemDto[];
  values: InputGroupValueto[] | null;
}

export interface AdminInputGroupDto extends InputGroupDto {
  createdAt: string;
  updatedAt: string;
  createdBy: UserDto;
  updatedBy: UserDto;
}

export interface InputTextDto extends ModelDto {
  label: string;
  description: string | null;
  type: string;
  required: boolean;
  value: string | null;
}

export interface CheckBoxDto {
  id: string;
  label: string;
  description: string | null;
  value: boolean | null;
}

export interface WorkdayItemDto {
  country: string;
  january: number;
  february: number;
  march: number;
  april: number;
  may: number;
  june: number;
  july: number;
  august: number;
  september: number;
  october: number;
  november: number;
  december: number;
}

export interface WorkdayDto {
  id: string;
  label: string;
  year: number;
  value: WorkdayItemDto[];
}

export interface DatePickerDto {
  id: string;
  label: string;
  description: string | null;
  required: boolean;
  value: string | null;
}

export interface AdminInputTextDto extends InputTextDto {
  createdAt: string;
  updatedAt: string;
  createdBy: UserDto;
  updatedBy: UserDto;
}

export interface RadioBoxItemDto {
  id: string;
  label: string;
}

export interface RadioBoxDto {
  id: string;
  label: string;
  description?: string;
  items: RadioBoxItemDto[];
  selected: RadioBoxItemDto | null;
}

export interface AdminRadioBoxDto extends RadioBoxDto {
  createdAt: string;
  updatedAt: string;
  createdBy: UserDto;
  updatedBy: UserDto;
}

export interface FilterDashboardTaxReturn {
  title?: string;
}

/*
 * PAGINATION
 */

export interface PaginationType {
  page: number;
  pageSize: number;
}

/*
 * COLUMN
 */

export type FetchDataFunction<DataType, FilterType = undefined> = (
  pagination: PaginationType,
  filter: Partial<FilterType>
) => DataType | Promise<DataType>
