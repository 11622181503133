import React from 'react';
import { Outlet } from 'react-router-dom';

import LoginPage from 'views/login';
import ForbiddenPage from 'views/errors/ForbiddenPage';
import { useAuthenticationContext } from '../../context/AuthenticationContext';


export default function AdminPage(): React.ReactElement {
  const { accessToken, role } = useAuthenticationContext();

  if (!accessToken) {
    return <LoginPage/>;
  }

  if (role !== 'ADMIN') {
    return <ForbiddenPage/>;
  }


  return <Outlet />;
}
