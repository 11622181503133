import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { useHandleBackendContext } from 'hooks/HandleBackendContext';
import { useState } from 'react';
import { InputFileDto } from 'types';
import { useAuthenticationContext } from '../../context/AuthenticationContext';

interface InputFileUploadProps {
  requestId: string;
  inputId: string;
  onSuccess?: (inputFileDto: InputFileDto) => void;
}

interface InputFileUploadResult {
  upload: (file: File) => void;
  progress: number;
}

export function useInputFileUpload({
  requestId,
  inputId,
  onSuccess
}: InputFileUploadProps): InputFileUploadResult {
  const { accessToken } = useAuthenticationContext();
  const [progress, setProgress] = useState<number>(0);
  const { handleBackendError } = useHandleBackendContext();

  const upload = (file: File) => {
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      onUploadProgress: progressEvent => {
        const { loaded, total } = progressEvent;
        const percentage = Math.floor((loaded * 100) / (total ?? 1));
        setProgress(percentage);
      }
    };

    const formData = new FormData();
    formData.append('file', file);

    axios
      .post<InputFileDto>(
        `${process.env.REACT_APP_BASE_API_URI}/api/requests/${requestId}/input-files/${inputId}/upload-file`,
        formData,
        config
      )
      .then(response => {
        (onSuccess != null) && onSuccess(response.data);
      })
      .catch((error: AxiosError) => {
        handleBackendError(error);
      })
      .finally(() => {
        setProgress(0);
      });
  };

  return {
    upload,
    progress
  };
}
