import React from 'react';
import { ListItemText } from '@mui/material';
import ItemEditor from '../ItemEditor';
import { AdminFormFieldDto } from 'types';

export interface WorkdayEditorEditorProps {
  field: AdminFormFieldDto;
}

export default function WorkdayEditor({
  field
}: WorkdayEditorEditorProps) {
  return (
    <ItemEditor
      label={'WORKDAY'}
      actions={<></>}
    >
      <ListItemText sx={{ px: 1, textAlign: 'center' }} primary={field.label}/>
    </ItemEditor>
  );
}
