import React from 'react';
import { Control, Controller, Path, PathValue } from 'react-hook-form';
import { DesktopDatePicker } from '@mui/x-date-pickers';


interface ControlledDatePickerProps<FormValues extends object> {
  label?: string;
  control: Control<FormValues, object>;
  name: string & Path<FormValues>;
  defaultValue?: Date;
}

export default function ControlledDatePicker<FormValues extends object>({
  control,
  name,
  label,
  defaultValue,
}: ControlledDatePickerProps<FormValues>) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={
        defaultValue as
          | PathValue<FormValues, string & Path<FormValues>>
          | undefined
      }
      render={({ field }) => {
        return (
          <DesktopDatePicker
            {...field}
            label={label}
            format="dd/MM/yyyy"
            slotProps={{
              textField: {
                error: false,
                size: 'small',
                variant: 'outlined',
                margin: 'normal',
                fullWidth: true,
              }
            }}
          />
        );
      }}
    />
  );
}
