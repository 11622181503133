import { AdminFormDto } from 'types';
import useApi from '../api';

interface UpdateFormSheetClonableResult {
  submit: (checked: boolean) => Promise<AdminFormDto>;
  loading: boolean;
  errorOccured: boolean;
}

export function useUpdateFormSheetClonable(sheetId: string): UpdateFormSheetClonableResult {

  const { post, postLoading,postError } = useApi<{clonable: boolean}, AdminFormDto>();
  const submitFunction = async (
    checked: boolean
  ) => {
    return post(`api/admin/sheets/${sheetId}/update-clonable`, { clonable: checked })
      .then(response => {
        return Promise.resolve(response.data);
      });
  };

  return {
    loading: postLoading,
    errorOccured: postError,
    submit: submitFunction
  };
}
