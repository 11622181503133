import { AdminTaxReturnDto, UpdateTaxReturnFormValues } from 'types';
import useApi from '../api';

interface UpdateTaxReturnProps {
  onSuccess?: (response: AdminTaxReturnDto) => void;
  willCallBackend?: () => void;
}

interface UpdateTaxReturnResult {
  submit: (values: UpdateTaxReturnFormValues) => Promise<AdminTaxReturnDto>;
  loading: boolean;
  errorOccured: boolean;
}

export function useUpdateTaxReturn(taxReturnId: string, {
  onSuccess,
}: UpdateTaxReturnProps): UpdateTaxReturnResult {

  const { post, postLoading,postError } = useApi<UpdateTaxReturnFormValues, AdminTaxReturnDto>();
  const submitFunction = async (
    values: UpdateTaxReturnFormValues
  ) => {
    return post(`api/admin/tax-returns/${taxReturnId}/update`, values)
      .then(response => {
        onSuccess && onSuccess(response.data);
        return Promise.resolve(response.data);
      });
  };

  return {
    loading: postLoading,
    errorOccured: postError,
    submit: submitFunction
  };
}
