import React from 'react';

import { Box, Chip, CircularProgress, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';

import { useBaseFormItemContext } from '../BaseFormItemContext';
import { useBaseFormContext } from '../BaseFormContext';

interface BaseAsyncSelectProps<OptionType> {
  options: OptionType[];
  loading: boolean;
  getOptionValue: (option: OptionType) => string;
  getOptionLabel: (option: OptionType) => string;
  multiple?: boolean;
}

export default function BaseAsyncSelect<OptionType, FormValues extends object>({
  options,
  loading,
  getOptionLabel,
  getOptionValue,
  multiple,
}: BaseAsyncSelectProps<OptionType>) {
  const { formState: { isValidating } } = useBaseFormContext<FormValues>();
  const { violations, field, label } = useBaseFormItemContext<FormValues>();
  const displayError = violations.length > 0 && !isValidating;

  return <FormControl
    variant="outlined"
    disabled={loading}
    fullWidth
    error={displayError}
    margin="normal"
    sx={{ my: 1 }}
  >
    <InputLabel>{label}</InputLabel>
    <Select
      {...field}
      label={label}
      renderValue={selected => {
        if (loading) {
          return <CircularProgress/>;
        }

        if (multiple) {
          const selecteds = selected as Array<string>;
          return <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {multiple ? selecteds.map(value => {
              const item =
                options.filter(option => getOptionValue(option) === value)[0] ?? null;
              return item ? (
                <Chip key={value} label={getOptionLabel(item)}/>
              ) : (
                <></>
              );
            }) : selected}
          </Box>;
        }

        const optionSelected = options.filter(option => getOptionValue(option) === selected)[0] ?? null;
        return optionSelected
          ? <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {getOptionLabel(optionSelected)}
          </Box>
          : <></>;
      }}
      multiple={multiple}
    >
      {options.map(option => (
        <MenuItem key={getOptionValue(option)} value={getOptionValue(option)}>
          {getOptionLabel(option)}
        </MenuItem>
      ))}
    </Select>
    {violations.length > 0 && !isValidating && (
      <FormHelperText>{violations[0].message}</FormHelperText>
    )}
  </FormControl>;
}
