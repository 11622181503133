import { useState } from 'react';
import axios, { AxiosError } from 'axios';
import { useHandleBackendContext } from 'hooks/HandleBackendContext';
import { useAuthenticationContext } from '../../context/AuthenticationContext';

interface FormValueType {
  [x: string]: any;
}

interface SaveTaxReturnProps {
  onSuccess?: () => void,
}

interface SaveTaxReturnResult {
  saveFields: (values: FormValueType) => void;
  loading: boolean;
  errorOccured: boolean;
}

export function useSaveTaxReturn(requestId: string, { onSuccess }: SaveTaxReturnProps): SaveTaxReturnResult {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorOccured, setErrorOccured] = useState<boolean>(false);
  const { accessToken } = useAuthenticationContext();
  const { handleBackendError, handleBackendSuccess } =
    useHandleBackendContext();

  const saveTaxReturn = (values: FormValueType) => {
    const fieldIds = Object.keys(values);
    if (fieldIds.length === 0) {
      return;
    }

    setLoading(true);
    setErrorOccured(false);

    axios
      .post(
        `${process.env.REACT_APP_BASE_API_URI}/api/requests/${requestId}/save-draft`,
        values,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      .then(() => {
        onSuccess && onSuccess();
        handleBackendSuccess('Tax return draft saved');
      })
      .catch((error: AxiosError) => {
        setLoading(false);
        setErrorOccured(true);
        handleBackendError(
          error,
          `Unable to save form: error with status ${error.response?.status}`
        );
      }).then(() => {
        setLoading(false);
      });
  };

  return {
    saveFields: saveTaxReturn,
    loading,
    errorOccured
  };
}
