import React from 'react';

import BasicDialog from 'components/Dialog/BasicDialog';
import { InputGroupDto } from 'types';
import { useForm } from 'react-hook-form';
import ControlledInputText from 'components/Form/ControlledInputs/ControlledInputText';
import { Box, Button } from '@mui/material';
import useSaveInputGroupValue from 'hooks/InputGroup/useSaveInputGroupValue';
import { useTaxReturnRequestContext } from 'hooks/TaxReturn/TaxReturnContext';

interface AddInputGroupValueDialogProps {
  visible: boolean;
  close: () => void;
  inputGroup: InputGroupDto;
  setInputGroup: (inputGroup: InputGroupDto) => void;
}

export default function AddInputGroupValueDialog({
  visible,
  close,
  inputGroup,
  setInputGroup,
}: AddInputGroupValueDialogProps) {

  const { handleSubmit, control, setValue } = useForm();
  const { request: { id: requestId } } = useTaxReturnRequestContext();
  const { submit } = useSaveInputGroupValue({
    requestId: requestId,
    inputGroupId: inputGroup.id,
    onSuccess: (groupe) => {
      setInputGroup(groupe);
      inputGroup.fields.forEach(field => {
        setValue(field.id, '');
      });
    }
  });

  return (
    <BasicDialog
      open={visible}
      onClose={close}
      maxWidth="sm"
      title={`Add new ${inputGroup.title}`}
    >
      <form onSubmit={handleSubmit(submit)}>
        {
          inputGroup.fields.map(field => {
            return <ControlledInputText
              key={field.id}
              isValidating={false}
              violations={[]}
              control={control}
              name={field.id}
              label={field.label}
            />;
          })
        }
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button variant="contained" type="submit">Submit</Button>
        </Box>
      </form>
    </BasicDialog>
  );
}

