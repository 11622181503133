import React from 'react';
import BaseForm from 'components/Form/BaseForm/BaseForm';
import BaseCheckBox from 'components/Form/BaseForm/Fields/BaseCheckBox';
import BasicDialog from 'components/Dialog/BasicDialog';
import useDeclineTaxReturnRequest from 'hooks/TaxReturnRequest/useDeclineTaxReturnRequest';

export interface DeclineTaxReturnValues {
  reasons: string;
  editable: boolean;
}

export default function DeclineTaxReturnRequestDialog({
  visible,
  close,
}: {
  visible: boolean,
  close: () => void,
}): React.ReactElement {

  const [, , submit] = useDeclineTaxReturnRequest(close);

  return <BasicDialog open={visible} onClose={close} title={'Decline tax return informations'}>
    <BaseForm
      initialValues={{ reasons: '', editable: true }}
      onSubmit={(submittedValues: DeclineTaxReturnValues) => {
        return submit(submittedValues);
      }}
      inputs={[
        {
          name: 'reasons',
          label: 'Reasons'
        },
        {
          name: 'editable',
          label: 'Set form editable by employee',
          render: () => {
            return <BaseCheckBox/>;
          }
        }
      ]}/>
  </BasicDialog>;
}