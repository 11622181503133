import React from 'react';
import {
  Button as MuiButton,
  DialogActions as MuiDialogActions,
  DialogProps as MuiDialogProps,
  DialogTitle
} from '@mui/material';

import Dialog from 'components/Dialog/BasicDialog';

interface DialogConfirmationProps extends MuiDialogProps {
  onCancel: () => void;
  onConfirm: () => void;
  loading: boolean;
}

function DialogConfirmation({
  onCancel,
  onConfirm,
  loading,
  children,
  ...props
}: DialogConfirmationProps) {
  return (
    <Dialog {...props} onClose={onCancel}>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'center' }}>Are you sure ?</DialogTitle>
      {children}
      <MuiDialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
        <MuiButton onClick={onCancel}>Cancel</MuiButton>
        <MuiButton
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={onConfirm}
        >
          {loading ? 'Please wait...' : 'Confirm'}
        </MuiButton>
      </MuiDialogActions>
    </Dialog>
  );
}

export default DialogConfirmation;
