import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { useHandleBackendContext } from 'hooks/HandleBackendContext';
import { useState } from 'react';
import { TaxReturnRequestDto } from 'types';
import { useTaxReturnRequestContext } from 'hooks/TaxReturn/TaxReturnContext';
import { useAuthenticationContext } from '../../context/AuthenticationContext';


interface TaxReturnUploadResult {
  upload: (file: File) => void;
  progress: number;
}

export function useUploadFileTaxReturnRequest(): TaxReturnUploadResult {
  const { accessToken } = useAuthenticationContext();
  const [progress, setProgress] = useState<number>(0);
  const { handleBackendError } = useHandleBackendContext();
  const { request: { id: requestId }, setRequest } = useTaxReturnRequestContext();

  const upload = (file: File) => {
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      onUploadProgress: progressEvent => {
        const { loaded, total } = progressEvent;
        const percentage = Math.floor((loaded * 100) / (total ?? 1));
        setProgress(percentage);
      }
    };

    const formData = new FormData();
    formData.append('file', file);

    axios
      .post<TaxReturnRequestDto>(
        `${process.env.REACT_APP_BASE_API_URI}/api/admin/requests/${requestId}/upload`,
        formData,
        config
      )
      .then(response => {
        setRequest(response.data);
      })
      .catch((error: AxiosError) => {
        handleBackendError(error);
      })
      .finally(() => {
        setProgress(0);
      });
  };

  return {
    upload,
    progress
  };
}
