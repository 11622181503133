import React from 'react';
import BaseForm from '../../Form/BaseForm/BaseForm';
import { useCreateFormItem } from 'hooks/useCreateFormItem';
import { BaseInputProps } from 'components/Form/BaseForm/types';

interface CreateCheckBoxFormProps<FormValues extends object> {
  sheetId: string;
  closeModal: () => void;
  initials: FormValues;
  urlSubmit: string;
  urlValidation: string;
  inputs: BaseInputProps<FormValues>[];
}

export default function CreateFormItemForm<FormValues extends object>({
  sheetId,
  closeModal,
  initials,
  inputs,
  urlSubmit,
  urlValidation
}: CreateCheckBoxFormProps<FormValues>) {
  const [submit] = useCreateFormItem(sheetId, urlSubmit, closeModal);

  return (
    <BaseForm
      urlValidation={urlValidation}
      initialValues={initials}
      inputs={inputs}
      onSubmit={submit}
    />
  );
}
