import { UserDto } from 'types';
import { useSubmitForm, UseSubmitFormOptions } from 'hooks/useSubmitForm';

export interface UpdateEmployeeEmailFormValues {
  email?: string;
}

export function useUpdateEmployeeEmail(employeeId: string, props: UseSubmitFormOptions<UpdateEmployeeEmailFormValues>): [
  submit: (values: UpdateEmployeeEmailFormValues) => Promise<UserDto>,
  loading: boolean,
  errorOccured: boolean,
] {
  return useSubmitForm<UpdateEmployeeEmailFormValues, UserDto>({
    ...props,
    urlPath: `api/admin/employees/${employeeId}/update-email`,
  });
}