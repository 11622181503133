import React, { createContext, useContext } from 'react';
import { Control, FormState } from 'react-hook-form';

import { ViolationDto } from './types';

interface BaseFormContextContent<FormValues extends object> {
  formState: FormState<FormValues>
  control: Control<FormValues>
  violations: ViolationDto[]
}

export const BaseFormContext = createContext<BaseFormContextContent<any> | null>(null);

interface BaseFormContextResult<FormValues extends object> {
  formState: FormState<FormValues>
  control: Control<FormValues>
  violations: ViolationDto[]
}

export function useBaseFormContext<FormValues extends object> (): BaseFormContextResult<FormValues> {
  const context = useContext<BaseFormContextContent<FormValues>>(BaseFormContext as React.Context<BaseFormContextContent<FormValues>>);
  if (context === null) {
    throw new Error('useBaseFormContext called outside Context.');
  }

  return {
    formState: context.formState,
    control: context.control,
    violations: context.violations
  };
}

interface BaseFormProviderProps<FormValues extends object> {
  children: React.ReactElement
  formState: FormState<FormValues>
  control: Control<FormValues>
  violations: ViolationDto[]
}

export function BaseFormProvider<FormValues extends object> ({
  children,
  formState,
  violations,
  control
}: BaseFormProviderProps<FormValues>) {
  return <BaseFormContext.Provider
    value={
      {
        formState,
        violations,
        control
      }
    }>
    {children}
  </BaseFormContext.Provider>;
}
