import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import TabPanel, { TabPanelProps } from '../TabPanel/TabPanel';
import { Box } from '@mui/material';

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
}

export interface TabsItemProps {
  label: React.ReactNode;
  panel: React.ReactElement;
  props?: Omit<TabPanelProps, 'children' | 'activeIndex' | 'index'>;
}

interface VerticalTabsProps {
  items: TabsItemProps[];
  tabAction?: React.ReactElement;
}

export default function VerticalTabs({ items, tabAction }: VerticalTabsProps) {
  const [value, setValue] = React.useState(0);

  const handleChange = (_event: unknown, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ flexDirection: 'column', width: '100%' }}>
      <Box sx={{ p: 1 }}>
        {(tabAction != null) && tabAction}
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Tabs
          orientation="vertical"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          {
            items.map((item, index) => {
              return <Tab key={index} label={item.label} {...a11yProps(index)} />;
            })
          }
        </Tabs>
        {items.map((item, index) => {
          return (
            <TabPanel {...item.props} key={index} index={index} activeIndex={value}>
              {item.panel}
            </TabPanel>
          );
        })}
      </Box>
    </Box>
  );
}
