import { InputGroupDto } from 'types';
import useApi from '../api';

interface UseSaveInputGroupValueOption {
  requestId: string;
  inputGroupId: string;
  onSuccess: (inputGroup: InputGroupDto) => void;
}

export default function useRemoveInputGroupItem({
  requestId,
  inputGroupId,
  onSuccess
}: UseSaveInputGroupValueOption) {

  const { post, postLoading,postError } = useApi<void, InputGroupDto>();
  const submitFunction = async (
    valueId?: string
  ) => {
    return post(`api/requests/${requestId}/input-groups/${inputGroupId}/items/${valueId}/delete`)
      .then(response => {
        onSuccess && onSuccess(response.data);
        return Promise.resolve(response.data);
      });
  };

  return {
    loading: postLoading,
    errorOccured: postError,
    submit: submitFunction
  };
}