import React from 'react';

import Form from '../Form/Form';
import { InputText } from '../Form/Fields/InputText';
import { AxiosResponse } from 'axios';
import useApi from '../../hooks/api';

interface LoginFormProps{
  setToken: (token: string) => void;
}

interface LoginFormValues{
  email: string;
  password: string;
}

interface LoginFormResult{
  token: string;
}

export default function LoginForm({ setToken }: LoginFormProps) {

  const { post } = useApi<LoginFormValues, LoginFormResult>();

  return <Form
    onSubmit={(values: LoginFormValues) => {
      return post('api/authentication/login', values);
    }}
    inputs={[
      {
        name: 'email',
        label: 'Email'
      },
      {
        name: 'password',
        label: 'Password',
        render: () => {
          return <InputText type='password' />;
        }
      },
    ]}
    onSuccess={(response: AxiosResponse<LoginFormResult>) => {
      setToken(response.data.token);
    }}
    defaultValues={{ email: '', password: '' }}
    submitLabel='Login'
  />;
}