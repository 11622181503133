import React from 'react';
import { ListItemButton, ListItemText } from '@mui/material';
import ItemEditor from '../ItemEditor';
import { AdminFormFieldDto } from 'types';
import { useBoolean } from 'hooks/useBoolean';
import UpdateRadioBoxDialog from 'components/Dialog/UpdateRadioBoxDialog/UpdateRadioBoxDialog';

export interface RadioBoxEditorProps {
  field: AdminFormFieldDto;
}

export default function RadioBoxEditor({
  field
}: RadioBoxEditorProps) {
  const {
    value: visible,
    setTrue: open,
    setFalse: close,
  } = useBoolean();
  return (
    <ItemEditor
      label={'RADIO BOX'}
      actions={<ListItemButton sx={{ flex: 0 }} onClick={open}>Edit</ListItemButton>}>
      <>
        <ListItemText sx={{ px: 1, textAlign: 'center' }} primary={field.label}/>
        {
          visible && <UpdateRadioBoxDialog
            visible={visible}
            close={close}
            radioBoxId={field.id}
          />
        }
      </>
    </ItemEditor>
  );
}
