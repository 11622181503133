import { AdminFormDto, CreateSheetFormValues } from 'types';
import useApi from '../api';

interface CreateFormSheetProps {
  onSuccess: (response: AdminFormDto) => void;
}

interface CreateFormSheetResult {
  submit: (values: CreateSheetFormValues) => Promise<AdminFormDto>;
  loading: boolean;
  errorOccured: boolean;
}

export function useCreateFormSheet(formId: string, {
  onSuccess,
}: CreateFormSheetProps): CreateFormSheetResult {

  const { post, postLoading: loading, postError: errorOccured } = useApi<CreateSheetFormValues, AdminFormDto>();
  const submit = async (values: CreateSheetFormValues) => {
    return post(`api/admin/sheets/${formId}/create`, values)
      .then(response => {
        onSuccess(response.data);
        return Promise.resolve(response.data);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  };

  return { loading, errorOccured, submit };
}
