import { AdminRadioBoxDto, RadioBoxItemFormValues } from 'types';
import useApi from '../../api';

interface CreateRadioBoxItemProps {
  onSuccess?: (response: AdminRadioBoxDto) => void;
  willCallBackend?: () => void;
}

interface CreateRadioBoxResult {
  submit: (values: RadioBoxItemFormValues) => Promise<AdminRadioBoxDto>;
  loading: boolean;
  errorOccured: boolean;
}

export function useCreateRadioBoxItem(radioBoxId: string, {
  onSuccess,
}: CreateRadioBoxItemProps): CreateRadioBoxResult {

  const { post, postLoading,postError } = useApi<RadioBoxItemFormValues, AdminRadioBoxDto>();
  const submitFunction = async (
    values: RadioBoxItemFormValues
  ) => {
    return post(`api/admin/radio-boxes/${radioBoxId}/radio-box-items/create`, values)
      .then(response => {
        onSuccess && onSuccess(response.data);
        return Promise.resolve(response.data);
      });
  };

  return {
    loading: postLoading,
    errorOccured: postError,
    submit: submitFunction
  };
}
