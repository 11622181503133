import React from 'react';
import { Alert, Grid, List, Paper, Typography } from '@mui/material';
import { useFetchAdminCreateEmployeeRequests } from 'hooks/useFetchDto';
import AsyncComponent from 'components/AsyncComponent/AsyncComponent';
import { ListItemCreateEmployeeRequest } from 'components/List/ListCreateEmployeeRequest/ListItemCreateEmployeeRequest';

export function ListCreateEmployeeRequest(): React.ReactElement {
  const [requests, loading, errorOccured, fetch] = useFetchAdminCreateEmployeeRequests();

  return <Grid container spacing={2} sx={{ p: 2 }}>
    <Grid xs={12} item sx={{ justifyContent: 'center', display: 'flex' }}>
      <AsyncComponent loading={loading} errorOccured={errorOccured} reload={fetch} result={requests}>
        {
          loadedRequests => {
            return <Paper sx={{ p: 2, minWidth: 800 }}>
              {
                loadedRequests.length === 0 && <Alert color="warning" sx={{ alignItems: 'center' }}>
                  <Typography>No create employee request submitted</Typography>
                </Alert>
              }
              {
                loadedRequests.length > 0 && <List>
                  {
                    loadedRequests?.map((request, index) =>
                      <ListItemCreateEmployeeRequest
                        key={index}
                        request={request}
                        reload={fetch}
                      />
                    )
                  }
                </List>
              }
            </Paper>;
          }
        }
      </AsyncComponent>
    </Grid>
  </Grid>;
}

