import React from 'react';
import Page from 'components/Page/Page';
import AsyncComponent from 'components/AsyncComponent/AsyncComponent';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import FormEditor from 'components/FormEditor/FormEditor';
import { useFetchAdminTaxReturn } from 'hooks/useFetchDto';
import HttpErrorPage from 'views/errors/HttpErrorPage';
import { AdminFormProvider } from 'hooks/AdminForm/AdminFormContext';
import EmployeeManager from 'components/TaxReturn/EmployeeManager/EmployeeManager';

export default function AdminTaxreturnPage(): React.ReactElement {
  const { id } = useParams<'id'>();
  if (id === undefined) {
    throw new Error('id tax return should be defined');
  }

  const [taxReturn, loading, errorOccured, fetchTaxReturn, , httpStatus, errorMessage] = useFetchAdminTaxReturn(id);

  return <AsyncComponent
    loading={loading}
    errorOccured={errorOccured}
    reload={fetchTaxReturn}
    result={taxReturn}
    renderOnError={() => <HttpErrorPage code={httpStatus} message={errorMessage}/>}
  >
    {(taxReturn) => {
      return <Page title={`Tax return: ${taxReturn.name}`}>
        <AdminFormProvider form={taxReturn.form}>
          <Grid container p={2} spacing={2}>
            <Grid item md={8}>
              <FormEditor/>
            </Grid>
            <Grid item md={4}>
              <EmployeeManager taxReturnId={taxReturn.id}/>
            </Grid>
          </Grid>
        </AdminFormProvider>
      </Page>;
    }}
  </AsyncComponent>;
}
