import React from 'react';
import { useBoolean } from 'hooks/useBoolean';
import useRemoveInputGroupItem from 'hooks/InputGroup/useRemoveInputGroupItem';
import { Box, Button } from '@mui/material';
import DialogConfirmation from 'components/Dialog/DialogConfirmation';
import { InputGroupDto, InputGroupValueto } from 'types';

interface RemoveInputGroupValueProps {
  id: string;
  editable: boolean;
  requestId: string;
  value: InputGroupValueto;
  setInputGroup: (inputGroup: InputGroupDto) => void;
}

export default function RemoveInputGroupValue({
  id,
  requestId,
  setInputGroup,
  editable,
  value,
}: RemoveInputGroupValueProps): React.ReactElement {
  const {
    value: visibleConfirmation,
    setTrue: openConfirmation,
    setFalse: closeConfirmation,
  } = useBoolean();
  const { submit: submitRemove, loading: loadingRemove } = useRemoveInputGroupItem({
    inputGroupId: id,
    requestId: requestId,
    onSuccess: (inputGroup) => {
      closeConfirmation();
      setInputGroup(inputGroup);
    }
  });
  return <Box sx={{ display: 'flex', justifyContent: 'center' }}>
    {editable &&
      <Button variant="contained" color="error" onClick={openConfirmation}>Delete this item</Button>
    }
    <DialogConfirmation
      onCancel={closeConfirmation}
      onConfirm={() => {
        submitRemove(value.id);
      }}
      loading={loadingRemove}
      open={visibleConfirmation}
    />
  </Box>;
}