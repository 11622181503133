import React, { useEffect, useState } from 'react';
import { Box as MuiBox, BoxProps as MuiBoxProps } from '@mui/material';

export interface TabPanelProps extends MuiBoxProps {
  children: React.ReactElement;
  activeIndex: number;
  index: number;
}

const getVisibilityStyle = (hiddenCondition: boolean): any => {
  if (hiddenCondition) {
    return {
      visibility: 'hidden',
      height: 0,
      width: 0
    };
  }
  return {
    visibility: 'visible',
    height: 'inherit',
    flex: 1
  };
};

export default function TabPanel({
  children,
  activeIndex,
  index,
  ...props
}: TabPanelProps) {

  const [alreadyLoaded, setAlreadyLoaded] = useState<boolean>(false);

  useEffect(() => {
    setAlreadyLoaded(activeIndex === index || alreadyLoaded);
  }, [activeIndex]);

  return (
    <div
      role="tabpanel"
      style={{ ...getVisibilityStyle(activeIndex !== index) }}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
    >
      <MuiBox {...props} sx={{ p: 3, ...props.sx }}>{alreadyLoaded && children}</MuiBox>
    </div>
  );
}
