import React from 'react';
import { useBoolean } from 'hooks/useBoolean';
import PaperWithTitle from 'components/Paper/PaperWithTitle';
import { useFetchRequestOfTaxReturn } from 'hooks/useFetchDto';
import AsyncComponent from 'components/AsyncComponent/AsyncComponent';
import { Box, Button, List, ListItem, ListItemText } from '@mui/material';
import moment from 'moment';
import CreateNewTaxReturnRequestDialog
  from 'components/Dialog/CreateNewTaxReturnRequestDialog/CreateNewTaxReturnRequestDialog';
import ChipTaxReturnRequest from 'components/Chip/ChipTaxReturnRequest';
import { ListItemLink } from 'components/ListItemLink/ListItemLink';

interface EmployeeManagerProps {
  taxReturnId: string;
}

export default function EmployeeManager({ taxReturnId }: EmployeeManagerProps) {

  const {
    value: visible,
    setTrue: open,
    setFalse: close,
  } = useBoolean();
  const [requests, loadingRequest, errorOccuredRequest, fetchRequests] = useFetchRequestOfTaxReturn(taxReturnId);

  return (
    <PaperWithTitle title="Users">
      <div>
        <AsyncComponent
          loading={loadingRequest}
          errorOccured={errorOccuredRequest}
          reload={fetchRequests}
          result={requests}
        >
          {loadedRequests => {
            return <>
              <Box sx={{ textAlign: 'right', px: 2 }}>
                <Button onClick={open}>Manage requests</Button>
              </Box>
              <List>
                {
                  loadedRequests.map(request => <ListItem key={request.id}>
                    <ListItemLink to={`/admin/requests/${request.id}`}>
                      <>
                        <ListItemText
                          primary={`${request.user.firstName} ${request.user.lastName}`}
                          secondary={moment.utc(request.expirationDate).local().format('DD/MM/yyyy')}
                        />
                        <ChipTaxReturnRequest status={request.status}/>
                      </>
                    </ListItemLink>
                  </ListItem>)
                }
              </List>
            </>;
          }}
        </AsyncComponent>
        <CreateNewTaxReturnRequestDialog
          visible={visible}
          close={close}
          fetchRequests={fetchRequests}
          taxReturnId={taxReturnId}
        />
      </div>
    </PaperWithTitle>
  );
}
