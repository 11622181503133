import { useCallback, useState } from 'react';

interface UseBooleanResult{
  value: boolean;
  setTrue: () => void;
  setFalse: () => void;
  setBoolean: (newValue: boolean) => void;
  toggleBoolean: () => void;
}

export function useBoolean(defaultValue?: boolean) :  UseBooleanResult{
  const [value, setBoolean] = useState<boolean>(defaultValue ?? false);
  const setTrue = useCallback(() => setBoolean(true), []);
  const setFalse = useCallback(() => setBoolean(false), []);
  const toggleBoolean = useCallback(() => setBoolean(!value), [value]);

  return { value, setTrue, setFalse, setBoolean, toggleBoolean };
}