import React from 'react';
import { Typography as MuiTypography } from '@mui/material';

import { FormFieldWithChange } from './utils';
import FormFieldContainer from './FormFieldContainer';
import DataViewer from 'components/DataViewer/DataViewer';
import ControlledDatePicker from '../../Form/ControlledInputs/ControlledDatePicker';
import moment from 'moment';
import { DatePickerDto } from 'types';

export default function FormFieldDatePicker<FormValues extends object>({
  id,
  formField,
  control,
  editable,
}: FormFieldWithChange<FormValues>) {
  const datePicker = formField.data as DatePickerDto;
  return (
    <FormFieldContainer
      formField={datePicker}
      formFieldId={id}
      skeletonProps={{ variant: 'text', height: 50 }}
      render={field => {
        return (
          <>
            {field.description && (
              <MuiTypography>{field.description}</MuiTypography>
            )}
            {editable
              ? (
                <ControlledDatePicker
                  label={field.label}
                  name={id}
                  control={control}
                  defaultValue={field.value ? moment(field.value).toDate() : undefined}
                />
              )
              : (
                <DataViewer
                  label={field.label}
                  value={field.value ? moment.utc(field.value).local().format('D/M/YYYY') : undefined}
                />
              )}
          </>
        );
      }}
    />
  );
}
