import React from 'react';
import { useBaseFormContext } from 'components/Form/BaseForm/BaseFormContext';
import { useBaseFormItemContext } from 'components/Form/BaseForm/BaseFormItemContext';
import { deepmerge } from '@mui/utils';
import { DatePickerProps, DesktopDatePicker } from '@mui/x-date-pickers';


export default function BaseDatePicker<FormValues extends object, TDate>(props: Omit<DatePickerProps<TDate>, 'onChange' | 'value' | 'renderInput'>) {
  const { formState: { isValidating } } = useBaseFormContext<FormValues>();
  const { violations, field, label } = useBaseFormItemContext<FormValues>();
  const finalProps = deepmerge(field, deepmerge(props, {
    label,
    sx: { my: 1 }
  }));

  return (
    <DesktopDatePicker
      {...finalProps}
      format="dd/MM/yyyy"
      slotProps={{
        textField: {
          helperText: violations.length > 0 && !isValidating && violations[0].message,
          error: violations.length > 0 && !isValidating,
          size: 'small',
          variant: 'outlined',
          margin: 'normal',
          fullWidth: true,
        }
      }}
    />
  );
}
