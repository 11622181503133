import React from 'react';
import { ListItemText } from '@mui/material';
import ItemEditor from '../ItemEditor';
import { AdminFormFieldDto } from 'types';

export interface CheckBoxEditorProps {
  field: AdminFormFieldDto;
}

export default function CheckBoxEditor({
  field
}: CheckBoxEditorProps) {
  return (
    <ItemEditor
      label={'CHECK BOX'}
      actions={<></>}>
      <ListItemText sx={{ px: 1, textAlign: 'center' }} primary={field.label}/>
    </ItemEditor>
  );
}
