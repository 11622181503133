import React from 'react';
import Page from 'components/Page/Page';
import { ListCreateEmployeeRequest } from 'components/List/ListCreateEmployeeRequest/ListCreateEmployeeRequest';

export default function AdminCreateEmployeeRequestsPage(): React.ReactElement {

  return <Page title="Create employee request">
    <ListCreateEmployeeRequest/>
  </Page>;
}
