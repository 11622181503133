import React from 'react';
import { ListItemButton, ListItemText } from '@mui/material';
import ItemEditor from '../ItemEditor';
import { AdminFormFieldDto } from 'types';
import { useFetchAdminInputText } from 'hooks/useFetchDto';
import BaseAsyncSelect from 'components/Form/BaseForm/Fields/BaseAsyncSelect';
import { inputTypes } from 'referentiels';
import UpdateFormItemDialog from 'components/Dialog/UpdateFormItemDialog';
import { useBoolean } from 'hooks/useBoolean';

export interface InputTextEditorProps {
  field: AdminFormFieldDto;
}

export default function InputTextEditor({
  field
}: InputTextEditorProps) {
  const {
    value: visible,
    setTrue: open,
    setFalse: close,
  } = useBoolean();
  return (
    <ItemEditor
      label={'INPUT TEXT'}
      actions={<>
        <ListItemButton sx={{ flex: 0 }} onClick={open}>Edit
        </ListItemButton>
        {
          visible && <UpdateFormItemDialog
            visible={visible}
            close={close}
            hookFetchDto={useFetchAdminInputText}
            itemId={field.id}
            urlUpdate={`api/admin/input-texts/${field.id}/update`}
            urlValidation={`api/admin/input-texts/${field.id}/update/validate`}
            title="Update input text"
            initials={item => {
              return {
                label: item.label,
                description: item.description ?? '',
                type: item.type,
                required: item.required
              };
            }}
            inputs={[
              { name: 'label', label: 'Label' },
              { name: 'description', label: 'Description' },
              {
                name: 'type',
                render: () => {
                  return <BaseAsyncSelect
                    options={inputTypes}
                    loading={false}
                    getOptionValue={option => {
                      return option.value;
                    }}
                    getOptionLabel={option => {
                      return option.label;
                    }}
                  />;
                },
                label: 'Type'
              }
            ]}
          />
        }
      </>}>
      <ListItemText sx={{ px: 1, textAlign: 'center' }} primary={field.label}/>
    </ItemEditor>
  );
}
