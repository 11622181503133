import React, { createContext, useContext, useState } from 'react';
import { AdminFormDto } from 'types';

interface AdminFormContextContent {
  form: AdminFormDto
  setForm: (form: AdminFormDto) => void
}

const AdminFormContext = createContext<AdminFormContextContent | null>(null);

interface AdminFormContextResult {
  form: AdminFormDto
  setForm: (form: AdminFormDto) => void
}

export const useAdminFormContext = (): AdminFormContextResult => {
  const context = useContext(AdminFormContext);
  if (context === null) {
    throw new Error('useAdminFormContext called outside Context.');
  }

  return {
    form: context.form,
    setForm: context.setForm
  };
};

interface AdminFormProviderProps {
  form: AdminFormDto
  children: React.ReactElement
}

export const AdminFormProvider = ({
  form,
  children
}: AdminFormProviderProps) => {
  const [formState, setFormState] = useState<AdminFormDto>(form);
  return (
    <AdminFormContext.Provider
      value={{
        form: formState,
        setForm: setFormState
      }}
    >
      {children}
    </AdminFormContext.Provider>
  );
};
