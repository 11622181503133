import React, { useState } from 'react';
import {
  Button as MuiButton,
  FormControl as MuiFormControl,
  FormHelperText as MuiFormHelperText,
  FormLabel as MuiFormLabel,
  LinearProgress,
  useTheme as useMuiTheme
} from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { ViolationDto } from '../types';

interface InputFileProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement> {
  label: string
  isValidating: boolean
  violations: ViolationDto[]
  percentage?: number
  displayFileName?: boolean
}

export default function BaseInputFile ({
  violations,
  isValidating,
  label,
  percentage,
  displayFileName,
  name,
  ...props
}: InputFileProps) {
  const theme = useMuiTheme();
  const [fileName, setFileName] = useState<string | null>(null);
  const displayError = violations.length > 0 && !isValidating;

  return (
    <MuiFormControl
      error={displayError}
      margin='normal'
      variant='outlined'
      sx={{ my: 1 }}
    >
      <MuiFormLabel
        htmlFor={`upload-${name}`}
        sx={{
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: displayError
            ? theme.palette.error.main
            : theme.palette.grey[400],
          borderRadius: 1
        }}
      >
        <input
          {...props}
          onChange={event => {
            const file = event.target.files?.item(0) ?? null;
            setFileName(file?.name ?? null)
            ;(props.onChange != null) && props.onChange(event);
          }}
          id={`upload-${name}`}
          type='file'
          style={{ display: 'none' }}
          name={name}
        />
        {percentage
          ? (
            <LinearProgress
              sx={{ minWidth: '10em' }}
              variant='determinate'
              value={percentage}
            />
          )
          : (
            <MuiButton
              component='span'
              variant='contained'
              fullWidth
              sx={{ borderRadius: 1 }}
              color={displayError ? 'error' : 'primary'}
            >
              <FileUploadIcon/>{' '}
              {displayFileName === false ? label : fileName ?? label}
            </MuiButton>
          )}
      </MuiFormLabel>
      {violations.length > 0 && !isValidating && (
        <MuiFormHelperText>{violations[0].message}</MuiFormHelperText>
      )}
    </MuiFormControl>
  );
}
