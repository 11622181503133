import React from 'react';
import { Controller, Path } from 'react-hook-form';

import { useBaseFormContext } from './BaseFormContext';
import { BaseFormItemProvider } from './BaseFormItemContext';
import { ViolationDto } from './types';
import BaseInputText from './Fields/BaseInputText';

interface BaseFormItemProps<FormValues> {
  violations: ViolationDto[]
  render?: () => React.ReactElement
  name: keyof FormValues & string & Path<FormValues>
  label: string
}

export default function BaseFormItem<FormValues extends object> ({
  violations,
  render,
  name,
  label
}: BaseFormItemProps<FormValues>) {
  const { control } = useBaseFormContext<FormValues>();
  return <Controller
    name={name}
    control={control}
    render={({ field }) => {
      return (
        <BaseFormItemProvider violations={violations} field={field} label={label}>
          {(render != null) ? render() : <BaseInputText/>}
        </BaseFormItemProvider>
      );
    }}
  />;
}
