import React from 'react';

import BasicDialog from 'components/Dialog/BasicDialog';
import BaseForm from '../../Form/BaseForm/BaseForm';
import {
  CreateEmployeeRequestFormValues,
  useCreateEmployeeRequest
} from 'hooks/CreateEmployeeRequest/useCreateEmployeeRequest';

interface AddEmployeeRequestDialogProps {
  visible: boolean;
  close: () => void;
  fetchRequests: () => void;
}

export default function AddEmployeeRequestDialog({
  visible,
  close,
  fetchRequests
}: AddEmployeeRequestDialogProps) {

  const [submit] = useCreateEmployeeRequest({
    onSuccess: () => {
      fetchRequests();
      close();
    }
  });

  return (
    <BasicDialog
      open={visible}
      onClose={close}
      title="Ask to create new employee account"
    >
      <BaseForm
        urlValidation={'api/employers/request-employees/validate'}
        initialValues={{ firstName: '' }}
        inputs={[
          { name: 'firstName', label: 'Firstname' },
          { name: 'lastName', label: 'Lastname' },
          { name: 'email', label: 'Email' },
        ]}
        onSubmit={(values: CreateEmployeeRequestFormValues) => {
          return submit(values);
        }}
      />
    </BasicDialog>
  );
}