import {
  ListItem as MuiListItem,
  SxProps as MuiSxProps,
  Theme as MuiTheme
} from '@mui/material';
import React, { forwardRef, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface ListItemLinkProps {
  children: React.ReactElement
  to: string
  sx?: MuiSxProps<MuiTheme>
}

export function ListItemLink ({
  children,
  to,
  sx
}: ListItemLinkProps): React.ReactElement {
  const renderLink = useMemo(
    () =>
      forwardRef(function Link (itemProps, ref: React.Ref<HTMLAnchorElement>) {
        return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
      }),
    [to]
  );

  return (
    <MuiListItem button component={renderLink} sx={sx}>
      {children}
    </MuiListItem>
  );
}
