import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import DialogConfirmation from 'components/Dialog/DialogConfirmation';
import { useBoolean } from 'hooks/useBoolean';
import useAcceptTaxReturnRequest from 'hooks/TaxReturnRequest/useAcceptTaxReturnRequest';
import { useTaxReturnRequestContext } from 'hooks/TaxReturn/TaxReturnContext';
import DeclineTaxReturnRequestDialog from 'components/Dialog/DeclineTaxReturnRequest/DeclineTaxReturnRequestDialog';

export default function ReviewRequest() {

  const {
    value: visibleAccept,
    setTrue: openAccept,
    setFalse: closeAccept,
  } = useBoolean();
  const {
    value: visibleDecline,
    setTrue: openDecline,
    setFalse: closeDecline,
  } = useBoolean();
  const { request: { id: requestId } } = useTaxReturnRequestContext();
  const [loadingAccept, , submitAccept] = useAcceptTaxReturnRequest(requestId, closeAccept);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
      <Button variant="outlined" color="error" onClick={openDecline}>Decline informations</Button>
      <Button variant="contained" color="success" sx={{ ml: 2 }} onClick={openAccept}>Accept informations</Button>
      <DialogConfirmation onCancel={closeAccept} onConfirm={submitAccept} loading={loadingAccept} open={visibleAccept}>
        <Typography>
          If you accept this tax return, an email will be sent to employee. You&lsquo;ll have
          possibility to upload tax return file.
        </Typography>
      </DialogConfirmation>
      <DeclineTaxReturnRequestDialog visible={visibleDecline} close={closeDecline}/>
    </Box>
  );
}
