import React from 'react';
import { Button as MuiButton } from '@mui/material';
import { Box as MuiBox } from '@mui/system';

interface EmptyOnErrorProps {
  reload: () => void;
  description?: string;
}

const EmptyOnError = ({ reload, description }: EmptyOnErrorProps) => {
  return (
    <MuiBox
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      <p>{description ?? 'Unexpected error'}</p>
      <MuiButton onClick={reload} variant="contained" color="primary">
        Retry
      </MuiButton>
    </MuiBox>
  );
};

export default EmptyOnError;
