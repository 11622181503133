import React, { useState } from 'react';
import {
  Box as MuiBox,
  DialogContent as MuiDialogContent,
  IconButton as MuiIconButton,
  ListItem as MuiListItem,
  ListItemText as MuiListItemText,
  Typography as MuiTypography
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { FileDto, InputFileDto } from 'types';
import { useRemoveFile } from 'hooks/InputFile/useRemoveFile';
import DialogConfirmation from 'components/Dialog/DialogConfirmation';
import { useTaxReturnRequestContext } from 'hooks/TaxReturn/TaxReturnContext';
import Downloader from '../../Downloader/Downloader';

export interface FileItemProps {
  file: FileDto;
  inputFIleId: string;
  setInputFile: (input: InputFileDto) => void;
  editable: boolean;
}

export default function FileItem({
  file,
  inputFIleId,
  setInputFile,
  editable,
}: FileItemProps) {
  const [confirmVisible, setConfirmVisible] = useState<boolean>(false);
  const { request: { id: requestId } } = useTaxReturnRequestContext();

  const { callBackend, loading } = useRemoveFile({
    requestId: requestId,
    inputId: inputFIleId,
    onSuccess: (updatedInput: InputFileDto) => {
      setInputFile(updatedInput);
      setConfirmVisible(false);
    }
  });
  return (
    <MuiListItem sx={{ p: 0 }} key={file.id}>
      <MuiListItemText
        sx={{ flexGrow: 0, px: 2 }}
        primary={
          <MuiTypography sx={{ fontWeight: 'bold' }}>
            {file.fileName}
          </MuiTypography>
        }
      />
      <MuiBox sx={{ flex: 1, textAlign: 'right' }}>
        <Downloader url={`${process.env.REACT_APP_BASE_API_URI}/api/storage/${file.id}`} fileName={file.fileName}>
          {download => {
            return <MuiIconButton
              color="primary"
              sx={{ ml: 1 }}
              onClick={download}
            >
              <FileDownloadIcon/>
            </MuiIconButton>;
          }}
        </Downloader>
        {editable && (
          <MuiIconButton
            color="error"
            sx={{ mx: 0 }}
            onClick={() => {
              setConfirmVisible(true);
            }}
          >
            <DeleteIcon/>
          </MuiIconButton>
        )}
        <DialogConfirmation
          open={confirmVisible}
          onCancel={() => {
            setConfirmVisible(false);
          }}
          onConfirm={() => {
            callBackend(file.id);
          }}
          loading={loading}
        >
          <MuiDialogContent>
            Are you sure to delete <i>{file.fileName}</i> ?
          </MuiDialogContent>
        </DialogConfirmation>
      </MuiBox>
    </MuiListItem>
  );
}
