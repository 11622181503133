import React, { createContext, useContext } from 'react';
import { TaxReturnRequestDto } from 'types';

interface TaxReturnRequestContextContent {
  request: TaxReturnRequestDto;
  setRequest: (request: TaxReturnRequestDto) => void;
}

const TaxReturnRequestContext = createContext<TaxReturnRequestContextContent | null>(null);

interface TaxReturnRequestContextResult {
  request: TaxReturnRequestDto;
  setRequest: (request: TaxReturnRequestDto) => void;
  editable: boolean;
}

export const useTaxReturnRequestContext = (): TaxReturnRequestContextResult => {
  const context = useContext(TaxReturnRequestContext);
  if (context === null) {
    throw new Error('useTaxReturnRequestContext called outside Context.');
  }

  return {
    request: context.request,
    setRequest: context.setRequest,
    editable: context.request.editable,
  };
};

interface TaxReturnRequestProviderProps {
  request: TaxReturnRequestDto;
  setRequest: (newRequest: TaxReturnRequestDto) => void;
  children: React.ReactElement;
}

export const TaxReturnRequestProvider = ({
  request,
  setRequest,
  children
}: TaxReturnRequestProviderProps) => {
  return (
    <TaxReturnRequestContext.Provider
      value={{
        request,
        setRequest
      }}
    >
      {children}
    </TaxReturnRequestContext.Provider>
  );
};
