import React, { useEffect } from 'react';
import { useAdminFormContext } from 'hooks/AdminForm/AdminFormContext';
import AsyncDialog from 'components/Dialog/AsyncDialog';
import { useUpdateFormItem } from 'hooks/useUpdateFormItem';
import BaseForm from 'components/Form/BaseForm/BaseForm';
import { useFetchAdminInputGroup } from 'hooks/useFetchDto';
import { Alert, Box, Button, Divider, List, ListItem, Typography } from '@mui/material';
import CreateInputGroupItemDialog from 'components/Dialog/CreateInputGroupItemDialog/CreateInputGroupItemDialog';
import { useBoolean } from 'hooks/useBoolean';

interface UpdateFormItemDialogProps {
  visible: boolean;
  close: () => void;
  inputGroupId: string,
}

export default function UpdateInputGroupDialog({
  visible,
  close,
  inputGroupId,
}: UpdateFormItemDialogProps) {

  const { setForm, form } = useAdminFormContext();
  const {
    value: visibleAddItem,
    setTrue: openAddItem,
    setFalse: closeAddItem
  } = useBoolean();
  const [inputGroup, loading, errorOccured, fetchInputGroup, setInputGroup] = useFetchAdminInputGroup(inputGroupId);
  const { submit } = useUpdateFormItem(`api/admin/input-groups/${inputGroupId}/update`, {
    onSuccess: response => {
      setForm(response);
      close();
    }
  });

  useEffect(() => {
    fetchInputGroup();
  }, [form]);

  return <AsyncDialog
    open={visible}
    onClose={close}
    title="Update input group"
    loading={loading}
    errorOccured={errorOccured}
    reload={fetchInputGroup}
    result={inputGroup}
  >
    {(loadedInputGroup) => {
      return <>
        <Box sx={{ mb: 3 }}>
          <Typography sx={{ fontWeight: 'bold' }}>Informations</Typography>
          <BaseForm
            onSubmit={submit}
            urlValidation={`api/admin/input-groups/${inputGroupId}/update/validate`}
            initialValues={{
              title: loadedInputGroup?.title ?? '',
              description: loadedInputGroup?.description ?? ''
            }}
            inputs={[
              {
                label: 'Title',
                name: 'title'
              },
              {
                label: 'Description',
                name: 'description'
              }
            ]}
          />
        </Box>
        <Box>
          {
            inputGroup?.fields.length === 0 ?
              <Alert severity="warning">
                No item created yet
              </Alert> :
              <>
                <Typography sx={{ fontWeight: 'bold' }}>Items</Typography>
                <Divider/>
                <List>
                  {
                    inputGroup?.fields.map(item => {
                      return <ListItem key={item.id}>{item.label}</ListItem>;
                    })
                  }
                </List>
              </>
          }

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button variant="contained" sx={{ mt: 1 }} onClick={openAddItem}>Add field</Button>
            <CreateInputGroupItemDialog
              visible={visibleAddItem}
              close={closeAddItem}
              inputGroupId={inputGroupId}
              setInputGroup={setInputGroup}
            />
          </Box>
        </Box>
      </>;
    }}
  </AsyncDialog>;
}