import React from 'react';
import {
  Box as MuiBox,
  Skeleton as MuiSkeleton,
  SkeletonProps as MuiSkeletonProps
} from '@mui/material';

interface FormFieldContainerProps<FormFieldType> {
  render: (formField: FormFieldType) => React.ReactElement
  formFieldId: string
  formField?: FormFieldType
  skeletonProps: MuiSkeletonProps
}

export default function FormFieldContainer<FormFieldType> ({
  render,
  formFieldId,
  formField,
  skeletonProps
}: FormFieldContainerProps<FormFieldType>) {
  return (
    <MuiBox sx={{ mt: '1.5em' }} id={formFieldId}>
      {formField
        ? (
          render(formField)
        )
        : (
          <MuiSkeleton {...skeletonProps} animation='wave' />
        )}
    </MuiBox>
  );
}
