import React from 'react';
import { Typography as MuiTypography } from '@mui/material';

import { SxProps as MuiSxProps } from '@mui/system';
import { FormFieldProps } from './utils';
import FormFieldContainer from './FormFieldContainer';
import { TitleDto } from 'types';

const sxTitles: MuiSxProps[] = [
  {
    fontWeight: 'bold',
    fontSize: 25,
    marginTop: '3em'
  },
  {
    fontWeight: 'bold',
    fontSize: 20,
    marginTop: '1em'
  }
];

export default function FormFieldTitle<FormValues>({ formField }: FormFieldProps<FormValues>) {
  const title = formField.data as TitleDto;
  return (
    <FormFieldContainer
      formField={title}
      formFieldId={formField.id}
      skeletonProps={{ variant: 'circular', height: 40, width: 40 }}
      render={field => {
        return (
          <MuiTypography sx={sxTitles[field.level - 1] ?? {}}>
            {field.content}
          </MuiTypography>
        );
      }}
    />
  );
}
