import React from 'react';
import { Box, IconButton as MuiIconButton, Typography } from '@mui/material';
import { useTaxReturnRequestContext } from 'hooks/TaxReturn/TaxReturnContext';
import { useUploadFileTaxReturnRequest } from 'hooks/TaxReturnRequest/useUploadFileTaxReturnRequest';
import BaseInputFile from 'components/Form/BaseForm/Fields/BaseInputFIle';
import Downloader from 'components/Downloader/Downloader';

export default function UploadRequestFile() {

  const { request } = useTaxReturnRequestContext();
  const { upload, progress } = useUploadFileTaxReturnRequest();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      {
        request.file && <Downloader
          url={`${process.env.REACT_APP_BASE_API_URI}/api/storage/${request.file.id}`}
          fileName={request.file.fileName}
        >
          {download => {
            return request.file ? <MuiIconButton
              color="primary"
              sx={{ ml: 1 }}
              onClick={download}
            >
              <Typography>{request.file.fileName}</Typography>
            </MuiIconButton> : <></>;
          }}
        </Downloader>
      }

      <BaseInputFile
        name={`${request.id}-input-file`}
        label={'Upload result file (2Mo max)'}
        isValidating={false}
        violations={[]}
        percentage={progress}
        displayFileName={false}
        onChange={event => {
          const files = event.target.files;
          if (files === null) {
            return;
          }

          const file: File | null = files[0] ?? null;
          if (file !== null) {
            upload(file);
          }
        }}
      />
    </Box>
  );
}
