export interface TypeOption {
  value: string;
  label: string;
}

export const inputTypes: TypeOption[] = [
  { value: 'email', label: 'email' },
  { value: 'text', label: 'text' },
  { value: 'number', label: 'number' },
  { value: 'date', label: 'date' },
  { value: 'phone', label: 'phone' }
];

export interface LevelOption {
  level: string;
  label: string;
}

export const titleLevels: LevelOption[] = [
  { level: '1', label: 'H1' },
  { level: '2', label: 'H2' },
  { level: '3', label: 'H3' },
  { level: '4', label: 'H4' },
  { level: '5', label: 'H5' },
  { level: '6', label: 'H6' }
];
