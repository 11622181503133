import { useBoolean } from 'hooks/useBoolean';
import { useContext } from 'react';
import axios from 'axios';
import FileSaver from 'file-saver';
import { AuthenticationContext } from '../../context/AuthenticationContext';

export function useDownloadFile(url: string, fileName: string): [
  () => void,
  boolean
] {

  const {
    value: downloading,
    setTrue: startDownload,
    setFalse: stopDownload,
  } = useBoolean();
  const context = useContext(AuthenticationContext);

  const downloadFile = () => {
    startDownload();
    axios({
      url: url,
      method: 'GET',
      responseType: 'blob',
      headers: context?.accessToken ? { Authorization: `Bearer ${context?.accessToken}` } : {}
    }).then(response => {
      FileSaver.saveAs(response.data, fileName);
    }).finally(stopDownload);
  };

  return [
    downloadFile,
    downloading
  ];
}