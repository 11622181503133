import React from 'react';
import {
  StandardTextFieldProps as MuiStandardTextFieldProps,
  TextField as MuiTextField
} from '@mui/material';
import {
  Control,
  Controller,
  Path,
  PathValue
} from 'react-hook-form';
import { ViolationDto } from '../BaseForm/types';

interface ControlledInputTextProps<FormValues extends object> extends MuiStandardTextFieldProps {
  label?: string
  isValidating: boolean
  violations: ViolationDto[]
  control: Control<FormValues, object>
  name: string & Path<FormValues>
}

export default function ControlledInputText<FormValues extends object> ({
  control,
  name,
  label,
  isValidating,
  violations,
  defaultValue,
  ...props
}: ControlledInputTextProps<FormValues>) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={
        defaultValue as
          | PathValue<FormValues, string & Path<FormValues>>
          | undefined
      }
      render={({ field }) => {
        const finalProps = { ...props, ...field };
        return (
          <MuiTextField
            {...finalProps}
            size='small'
            variant={props?.variant ?? 'outlined'}
            margin={props?.margin ?? 'normal'}
            label={props?.placeholder ? undefined : label}
            placeholder={props?.placeholder}
            fullWidth={true}
            sx={{ my: 1 }}
            helperText={
              violations.length > 0 && !isValidating && violations[0].message
            }
            error={violations.length > 0 && !isValidating}
          />
        );
      }}
    />
  );
}
