import { TaxReturnRequestDto } from 'types';
import { useTaxReturnRequestContext } from 'hooks/TaxReturn/TaxReturnContext';
import useApi from '../api';


export default function useSubmitTaxReturnRequest(requestId: string, onSuccess: (request: TaxReturnRequestDto) => void): [
  boolean,
  boolean,
  () => void,
] {
  const { setRequest } = useTaxReturnRequestContext();
  const { post, postLoading,postError } = useApi<void, TaxReturnRequestDto>();
  const submitFunction = async () => {
    return post(`api/requests/${requestId}/submit`)
      .then(response => {
        setRequest(response.data);
        onSuccess(response.data);
        return Promise.resolve(response.data);
      });
  };

  return [
    postLoading,
    postError,
    submitFunction,
  ];
}