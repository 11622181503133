import React from 'react';

import BasicDialog from 'components/Dialog/BasicDialog';
import { UserDto } from 'types';
import BaseForm from '../../Form/BaseForm/BaseForm';
import { UpdateEmployeeEmailFormValues, useUpdateEmployeeEmail } from 'hooks/Employee/useUpdateEmployee';

interface UpdateEmployeeEmailDialogProps {
  visible: boolean;
  close: () => void;
  refreshCompany: () => void;
  employee: UserDto;
}

export default function UpdateEmployeeEmailDialog({
  visible,
  close,
  refreshCompany,
  employee,
}: UpdateEmployeeEmailDialogProps) {

  const [submit] = useUpdateEmployeeEmail(employee.id, {
    onSuccess: () => {
      refreshCompany();
      close();
    }
  });
  const initials: UpdateEmployeeEmailFormValues = {
    email: employee.email
  };

  return (
    <BasicDialog
      open={visible}
      onClose={close}
      title={`Update ${employee.firstName} ${employee.lastName} email`}
    >
      <BaseForm
        urlValidation={`api/admin/employees/${employee.id}/update-email/validate`}
        initialValues={initials}
        inputs={[{ name: 'email', label: 'Email' }]}
        onSubmit={submit}
      />
    </BasicDialog>
  );
}