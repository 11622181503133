import React from 'react';
import { Box as MuiBox, Paper as MuiPaper, Typography as MuiTypography } from '@mui/material';
import { useParams } from 'react-router-dom';
import ActivateAccountForm from 'components/ActivateAccountForm/ActivateAccountForm';

export default function ActivateAccountPage(): React.ReactElement {

  const { token } = useParams<'token'>();
  return <MuiBox
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
    }}
  >
    <MuiPaper
      sx={{
        py: 3,
        px: 2,
        pt: 2,
        width: '30em',
        maxWidth: '100%'
      }}
    >
      <MuiTypography
        sx={{
          pb: 3,
          textTransform: 'uppercase',
          fontSize: '2em',
          textAlign: 'center'
        }}
      >
        ACTIVATE YOUR ACCOUNT
      </MuiTypography>
      {
        token && <ActivateAccountForm token={token}/>
      }
    </MuiPaper>
  </MuiBox>;
}
