import { useSubmitForm, UseSubmitFormOptions } from 'hooks/useSubmitForm';

export function useDeleteTaxReturn(taxReturnId: string, props: UseSubmitFormOptions<void>): [
  submit: () => Promise<void>,
  loading: boolean,
  errorOccured: boolean,
] {
  return useSubmitForm<void, void>({
    ...props,
    urlPath: `api/admin/tax-returns/${taxReturnId}/delete`,
  });
}
