import { AdminFormDto } from 'types';
import useApi from './api';

interface UpdateFormItemProps {
  onSuccess?: (response: AdminFormDto) => void;
}

interface UpdateFormItemResult<FormValues> {
  submit: (values: FormValues) => Promise<AdminFormDto>;
  loading: boolean;
  errorOccured: boolean;
}

export function useUpdateFormItem<FormValues>(updateUrl: string, {
  onSuccess,
}: UpdateFormItemProps): UpdateFormItemResult<FormValues> {

  const { post, postLoading,postError } = useApi<FormValues, AdminFormDto>();
  const submitFunction = async (
    values: FormValues
  ) => {
    return post(updateUrl, values)
      .then(response => {
        onSuccess && onSuccess(response.data);
        return Promise.resolve(response.data);
      });
  };

  return {
    loading: postLoading,
    errorOccured: postError,
    submit: submitFunction
  };
}