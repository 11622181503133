import { BaseValidator, ViolationDto } from 'components/Form/BaseForm/types';
import useApi from '../api';

interface UseValidateFormResult<FormValues> {
  validate?: BaseValidator<FormValues>;
}

export function useValidateForm<FormValues>(urlPath?: string): UseValidateFormResult<FormValues> {
  if (!urlPath) {
    return {
      validate: undefined
    };
  }
  const { post } = useApi<FormValues, ViolationDto[]>();

  const validateFunction = async (values?: FormValues) => {
    const response = await post(urlPath, values);
    return Promise.resolve(response.data);
  };

  return {
    validate: validateFunction
  };
}
