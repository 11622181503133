import React from 'react';

import { TaxReturnSummaryDto } from 'types';
import { ListItem as MuiListItem, ListItemButton, ListItemText as MuiListItemText } from '@mui/material';
import { ListItemLink } from 'components/ListItemLink/ListItemLink';
import EditTaxReturnDialog from 'components/Dialog/EditTaxReturnDialog/EditTaxReturnDialog';
import { useBoolean } from 'hooks/useBoolean';
import CloneTaxReturnDialog from 'components/Dialog/CloneTaxReturnDialog/CloneTaxReturnDialog';
import { useDeleteTaxReturn } from 'hooks/TaxReturn/useDeleteTaxReturn';
import DialogConfirmation from 'components/Dialog/DialogConfirmation';
import { useExportTaxReturn } from 'hooks/Downloader/useExportTaxReturn';

interface ListItemTaxReturnProps {
  taxReturn: TaxReturnSummaryDto;
  refreshDashboard: () => void;
}

export function ListItemTaxReturn({
  taxReturn, refreshDashboard
}: ListItemTaxReturnProps): React.ReactElement {
  const {
    value: visibleEdit,
    setTrue: openEdit,
    setFalse: closeEdit,
  } = useBoolean();
  const {
    value: visibleClone,
    setTrue: openClone,
    setFalse: closeClone,
  } = useBoolean();
  const {
    value: visibleConfirmDelete,
    setTrue: openConfirmDelete,
    setFalse: closeConfirmDelete,
  } = useBoolean();
  const [submitDelete, loadingDelete] = useDeleteTaxReturn(taxReturn.id, {
    onSuccess: () => {
      closeConfirmDelete();
      refreshDashboard();
    }
  });
  const [exportFile, exporting] = useExportTaxReturn(taxReturn.id, taxReturn.name);

  return (
    <MuiListItem sx={{ p: 0 }}>
      <ListItemLink to={`/admin/tax-returns/${taxReturn.id}`}>
        <>
          <MuiListItemText primary={<strong>{taxReturn.name}</strong>}/>
          <MuiListItemText primary={`${taxReturn.nbRequests} request(s)`}/>
        </>
      </ListItemLink>
      <ListItemButton onClick={openEdit}>
        <MuiListItemText primary="Edit"/>
      </ListItemButton>
      <ListItemButton onClick={openClone}>
        <MuiListItemText primary="Clone"/>
      </ListItemButton>
      <ListItemButton onClick={openConfirmDelete} disabled={!taxReturn.deletable}>
        <MuiListItemText primary="Delete"/>
      </ListItemButton>
      <ListItemButton onClick={exportFile} disabled={exporting}>
        <MuiListItemText primary="Export"/>
      </ListItemButton>
      <EditTaxReturnDialog
        visible={visibleEdit}
        close={() => {
          closeEdit();
          refreshDashboard();
        }}
        taxReturn={taxReturn}
      />
      <CloneTaxReturnDialog
        visible={visibleClone}
        close={closeClone}
        taxReturn={taxReturn}
        fetchTaxReturns={refreshDashboard}
      />
      <DialogConfirmation
        onCancel={closeConfirmDelete}
        onConfirm={() => {
          submitDelete();
        }}
        loading={loadingDelete}
        open={visibleConfirmDelete}
      />
    </MuiListItem>
  );
}
