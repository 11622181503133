import React from 'react';
import Page from 'components/Page/Page';
import { Grid, List, ListItem, ListItemText, Paper, Typography } from '@mui/material';
import { useFetchTaxReturnRequestWaitingForAdmin, useFetchTaxReturnRequestWaitingForEmployee } from 'hooks/useFetchDto';
import AsyncComponent from 'components/AsyncComponent/AsyncComponent';
import { ListItemLink } from 'components/ListItemLink/ListItemLink';
import moment from 'moment';
import ChipTaxReturnRequest from 'components/Chip/ChipTaxReturnRequest';

export default function AdminHomePage(): React.ReactElement {
  const [resultsWaitAdmin, loadingWaitAdmin, errorWaitAdmin, reloadWaitAdmin] = useFetchTaxReturnRequestWaitingForAdmin();
  const [resultsWaitEmployee, loadingWaitEmployee, errorWaitEmployee, reloadWaitEmployee] = useFetchTaxReturnRequestWaitingForEmployee();
  return <Page title="Dashboard">
    <Grid spacing={2} justifyContent="center" container>
      <Grid xs={3} item>
        <Paper>
          <Typography variant="h5" sx={{ p: 2 }}>Waiting for you</Typography>
          <AsyncComponent
            loading={loadingWaitAdmin}
            errorOccured={errorWaitAdmin}
            reload={reloadWaitAdmin}
            result={resultsWaitAdmin}>
            {
              loadedResults => <>{
                loadedResults.total > 0 ? <List>
                  {
                    loadedResults.items.map(request => <ListItem key={request.id}>
                      <ListItemLink to={`/admin/requests/${request.id}`}>
                        <>
                          <ListItemText
                            primary={`${request.user.firstName} ${request.user.lastName} - ${request.taxReturnName}`}
                            secondary={`Expiration: ${moment.utc(request.expirationDate).local().format('DD/MM/yyyy')}`}
                          />
                          <ChipTaxReturnRequest status={request.status}/>
                        </>
                      </ListItemLink>
                    </ListItem>)
                  }
                </List> : <Typography
                  sx={{
                    p: 2,
                    pt: 0,
                    color: '#999',
                    textAlign: 'center'
                  }}>
                  No request waiting for you
                </Typography>
              }</>
            }
          </AsyncComponent>
        </Paper>
      </Grid>
      <Grid xs={3} item>
        <Paper>
          <Typography variant="h5" sx={{ p: 2 }}>Waiting for employee</Typography>
          <AsyncComponent
            loading={loadingWaitEmployee}
            errorOccured={errorWaitEmployee}
            reload={reloadWaitEmployee}
            result={resultsWaitEmployee}>
            {
              loadedResults => <>{
                loadedResults.total > 0 ? <List>
                  {
                    loadedResults.items.map(request => <ListItem key={request.id}>
                      <ListItemLink to={`/admin/requests/${request.id}`}>
                        <>
                          <ListItemText
                            primary={`${request.user.firstName} ${request.user.lastName} - ${request.taxReturnName}`}
                            secondary={`Expiration: ${moment.utc(request.expirationDate).local().format('DD/MM/yyyy')}`}
                          />
                          <ChipTaxReturnRequest status={request.status}/>
                        </>
                      </ListItemLink>
                    </ListItem>)
                  }
                </List> : <Typography
                  sx={{
                    p: 2,
                    pt: 0,
                    color: '#999',
                    textAlign: 'center'
                  }}>
                  No request waiting for you
                </Typography>
              }</>
            }
          </AsyncComponent>
        </Paper>
      </Grid>
    </Grid>
  </Page>;
}
